import "./courses.css";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SelectButton } from "primereact/selectbutton";
import AuthContext from "../../../auth/auth-context";
import CourseService from "../../../services/course.service";
import AlertService from "../../../services/alert.service";

const CourseUsers = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [csearchText, setCSearchText] = useState("");
    const [users, setUsers] = useState([]);
    const [courseUsers, setCourseUsers] = useState([]);
    const [srchCourseUsers, setSrchCourseUsers] = useState([]);
    const [srchUsers, setSrchUsers] = useState([]);
    const authContext = useContext(AuthContext);

    const difficulties = [
        { name: "E", value: 1 },
        { name: "M", value: 2 },
        { name: "H", value: 3 },
    ];

    const courseAccess = authContext.getPermissions("courses");
    if (courseAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }
    const showAdd = courseAccess.filter((f) => f.access.includes(2)).length > 0;
    const showEdit = courseAccess.filter((f) => f.access.includes(3)).length > 0;

    const loadCourseUsers = useCallback(async () => {
        setLoading(true);
        const data = await CourseService.GetUsers(params.id, "", authContext);
        if (data.isSuccess) {
            data.data &&
                data.data.mappedUsers &&
                data.data.mappedUsers.forEach((element) => {
                    element.selected = false;
                });
            data.data &&
                data.data.allUsers &&
                data.data.allUsers.forEach((element) => {
                    element.selected = false;
                });
            setCourseUsers(data.data.mappedUsers);
            if (data.data && data.data.mappedUsers && data.data.mappedUsers.length > 0) {
                setUsers([
                    ...data.data.allUsers.filter(
                        (f) => data.data.mappedUsers.filter((d) => d.userId === f.userId).length === 0
                    ),
                ]);
            } else {
                setUsers([...data.data.allUsers]);
            }
            setLoading(false);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [params, authContext]);

    useEffect(() => {
        if (params.id) {
            loadCourseUsers();
        }
    }, [params, loadCourseUsers, authContext]);

    useEffect(() => {
        setSrchUsers(users.filter((f) => f.userName.toLowerCase().indexOf(searchText) !== -1));
    }, [searchText, users]);

    useEffect(() => {
        setSrchCourseUsers(courseUsers.filter((f) => f.userName.toLowerCase().indexOf(csearchText) !== -1));
    }, [csearchText, courseUsers]);

    const Userselected = (m) => {
        setUsers((prevUsers) => {
            if (prevUsers && prevUsers.length > 0) {
                let incident = prevUsers.filter((f) => f.userId === m.userId);
                if (incident && incident.length > 0) {
                    incident[0].selected = !m.selected;
                }
                return [...prevUsers];
            } else {
                return [];
            }
        });
    };

    const courseUserselected = (m) => {
        setCourseUsers((prevUsers) => {
            if (prevUsers && prevUsers.length > 0) {
                let incident = prevUsers.filter((f) => f.userId === m.userId);
                if (incident && incident.length > 0) {
                    incident[0].selected = !m.selected;
                }
                return [...prevUsers];
            } else {
                return [];
            }
        });
    };

    const moveAllRight = (e) => {
        e.preventDefault();
        if (users && users.length > 0) {
            setCourseUsers((prevUsers) => {
                users.forEach((f) => (f.selected = false));
                if (prevUsers && prevUsers.length > 0) {
                    return [...prevUsers, ...users];
                } else {
                    return [...users];
                }
            });
            setUsers([]);
        }
    };

    const moveRight = (e) => {
        e.preventDefault();
        if (users && users.length > 0) {
            let selectedUsers = users.filter((f) => f.selected === true);
            if (selectedUsers.length === 0) {
                AlertService.error("Please select incident to move.");
            } else {
                selectedUsers.forEach((f) => (f.selected = false));
                setCourseUsers((prevUsers) => {
                    if (prevUsers && prevUsers.length > 0) {
                        return [...prevUsers, ...selectedUsers];
                    } else {
                        return [...selectedUsers];
                    }
                });
                setUsers((prevUsers) => {
                    if (prevUsers && prevUsers.length > 0) {
                        prevUsers = prevUsers.filter(
                            (f) => selectedUsers.filter((s) => s.userId === f.userId).length === 0
                        );
                        return [...prevUsers];
                    } else {
                        return [];
                    }
                });
            }
        }
    };

    const moveAllLeft = (e) => {
        e.preventDefault();
        if (courseUsers && courseUsers.length > 0) {
            courseUsers.forEach((f) => (f.selected = false));
            setUsers((prevUsers) => {
                if (prevUsers && prevUsers.length > 0) {
                    return [...prevUsers, ...courseUsers];
                } else {
                    return [...courseUsers];
                }
            });
            setCourseUsers([]);
        }
    };

    const moveLeft = (e) => {
        e.preventDefault();
        if (courseUsers && courseUsers.length > 0) {
            let selectedUsers = courseUsers.filter((f) => f.selected === true);
            if (selectedUsers.length === 0) {
                AlertService.error("Please select incident to move.");
            } else {
                selectedUsers.forEach((f) => (f.selected = false));
                setUsers((prevUsers) => {
                    if (prevUsers && prevUsers.length > 0) {
                        return [...prevUsers, ...selectedUsers];
                    } else {
                        return [...selectedUsers];
                    }
                });
                setCourseUsers((prevUsers) => {
                    if (prevUsers && prevUsers.length > 0) {
                        prevUsers = prevUsers.filter(
                            (f) => selectedUsers.filter((s) => s.userId === f.userId).length === 0
                        );
                        return [...prevUsers];
                    } else {
                        return [];
                    }
                });
            }
        }
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        if (!courseUsers || courseUsers.length === 0) {
            AlertService.error("Please select atleast an incident.");
        } else {
            setLoading(true);
            const payload = {
                courseId: params.id,
                users: courseUsers,
                mappedUsers: [],
            };
            let data = await CourseService.SaveUsers(payload, authContext);
            if (data.isSuccess) {
                AlertService.success("Selected users are added to the course.");
            } else {
                AlertService.error(data.errorMessage);
            }
            setLoading(false);
        }
    };

    const updateDifficulty = (input, difficulty) => {
        setCourseUsers((prevUsers) => {
            if (prevUsers && prevUsers.length > 0) {
                let user = prevUsers.filter((f) => f.userId === input.userId);
                if (user && user.length > 0) {
                    user[0].difficulty = difficulty;
                }
                return [...prevUsers];
            } else {
                return [];
            }
        });
        setSrchCourseUsers((prevUsers) => {
            if (prevUsers && prevUsers.length > 0) {
                let user = prevUsers.filter((f) => f.userId === input.userId);
                if (user && user.length > 0) {
                    user[0].difficulty = difficulty;
                }
                return [...prevUsers];
            } else {
                return [];
            }
        });
    };

    return (
        <Fragment>
            <div className="row m-0">
                <div className="col-12 course-title">
                    <button type="button" className="btn-incident-back" onClick={() => navigate("/courses")}>
                        <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                    </button>
                </div>
            </div>
            <div className="row m-0 course-incidents-container">
                <div className="col-5">
                    <div className="repo-incidents">
                        <div className="title">User Master Repository</div>
                        <div className="form-group has-search search-group mb-0">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input
                                type="text"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                className="form-control"
                                placeholder="Search"
                            />
                            <span className="input-group-append">
                                <button className="btn border-0" type="button" onClick={() => setSearchText("")}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </span>
                        </div>
                        <div className="repo-incidents-container">
                            {srchUsers &&
                                srchUsers.length > 0 &&
                                srchUsers.map((m, i) => (
                                    <div
                                        key={i}
                                        className={m.selected ? "incident-item selected" : "incident-item"}
                                        onClick={() => Userselected(m)}
                                    >
                                        {m.userName}
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <div className="col-2">
                    <div className="action-container">
                        <button disabled={!showAdd && !showEdit} onClick={moveAllLeft}>
                            &lt;&lt;
                        </button>
                        <button disabled={!showAdd && !showEdit} onClick={moveLeft}>
                            &lt;
                        </button>
                        <button disabled={!showAdd && !showEdit} onClick={moveRight}>
                            &gt;
                        </button>
                        <button disabled={!showAdd && !showEdit} onClick={moveAllRight}>
                            &gt;&gt;
                        </button>
                    </div>
                </div>
                <div className="col-5">
                    <div className="course-incidents">
                        <div className="title">Selected users</div>
                        <div className="form-group has-search search-group">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input
                                type="text"
                                value={csearchText}
                                onChange={(e) => setCSearchText(e.target.value)}
                                className="form-control"
                                placeholder="Search"
                            />
                            <span className="input-group-append">
                                <button className="btn border-0" type="button" onClick={() => setCSearchText("")}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </span>
                        </div>
                        <div className="course-incidents-container">
                            {srchCourseUsers &&
                                srchCourseUsers.length > 0 &&
                                srchCourseUsers.map((m, i) => (
                                    <div
                                        key={i}
                                        className={`incident-item ${m.selected ? "selected" : ""} ${
                                            m.difficulty === 2
                                                ? "text-primary"
                                                : m.difficulty === 3
                                                ? "text-danger"
                                                : ""
                                        }`}
                                    >
                                        <div className="row m-0 p-0">
                                            <div className="col-9 m-0 p-0" onClick={() => courseUserselected(m)}>
                                                {m.userName}
                                            </div>
                                            <div className="col-3 m-0 p-0">
                                                <SelectButton
                                                    className="float-right"
                                                    value={m.difficulty}
                                                    onChange={(e) => updateDifficulty(m, e.value)}
                                                    optionLabel="name"
                                                    options={difficulties}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <button
                    type="button"
                    className="submit-button float-right"
                    onClick={onSubmitHandler}
                    disabled={loading}
                >
                    Submit
                </button>
            </div>
        </Fragment>
    );
};

export default CourseUsers;
