import { React, useEffect, useState } from "react";
import { Viewer } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";

const DocumentViewer = ({ src, contentType, clearSelection, title }) => {
    const [url, setUrl] = useState();
    const [docTitle, setDocTitle] = useState(title);
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;

    useEffect(() => {
        if (src && src !== null) {
            const base64WithoutPrefix = src;

            const bytes = atob(base64WithoutPrefix);
            let length = bytes.length;
            let out = new Uint8Array(length);

            while (length--) {
                out[length] = bytes.charCodeAt(length);
            }

            const blob = new Blob([out], { type: contentType });
            setUrl(URL.createObjectURL(blob));
        }
    }, [src, contentType]);

    useEffect(() => {
        if (title) {
            setDocTitle(title);
            console.log(title);
        }
    }, [title]);

    return (
        <div
            className="rpv-core__viewer"
            style={{
                border: "1px solid #ddd",
                display: "flex",
                flexDirection: "column",
                height: "calc(100vh - 120px)",
            }}
        >
            <div
                style={{
                    alignItems: "center",
                    backgroundColor: "#f9f9f9",
                    borderBottom: "1px solid #ddd",
                    display: "flex",
                    padding: "4px",
                }}
            >
                <Toolbar>
                    {(props) => {
                        const {
                            CurrentPageInput,
                            // Download,
                            EnterFullScreen,
                            GoToFirstPage,
                            GoToNextPage,
                            GoToPreviousPage,
                            GoToLastPage,
                            NumberOfPages,
                            // Print,
                            ShowSearchPopover,
                            Zoom,
                            ZoomIn,
                            ZoomOut,
                        } = props;
                        return (
                            <>
                                <div style={{ padding: "0px 2px" }}>
                                    <ShowSearchPopover />
                                </div>
                                <div style={{ padding: "0px 2px" }}>
                                    <GoToFirstPage />
                                </div>
                                <div style={{ padding: "0px 2px" }}>
                                    <GoToPreviousPage />
                                </div>
                                <div style={{ padding: "0px 2px", width: "3rem" }}>
                                    <CurrentPageInput />
                                </div>
                                <div style={{ padding: "0px 2px" }}>
                                    / <NumberOfPages />
                                </div>
                                <div style={{ padding: "0px 2px" }}>
                                    <GoToNextPage />
                                </div>
                                <div style={{ padding: "0px 2px" }}>
                                    <GoToLastPage />
                                </div>
                                <div style={{ padding: "0px 2px" }}>
                                    <span>{docTitle}</span>
                                </div>
                                <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                                    <ZoomOut />
                                </div>
                                <div style={{ padding: "0px 2px" }}>
                                    <Zoom />
                                </div>
                                <div style={{ padding: "0px 2px" }}>
                                    <ZoomIn />
                                </div>
                                <div style={{ padding: "0px 2px" }}>
                                    <EnterFullScreen />
                                </div>
                                <div style={{ padding: "0px 2px" }}>
                                    <button type="button" className="rpv-core__minimal-button" onClick={clearSelection}>
                                        <i className="fa fa-chevron-left"></i> <span>Back</span>
                                    </button>
                                </div>
                                {/* <div style={{ padding: '0px 2px' }}>
                  <Download />
                </div>
                <div style={{ padding: '0px 2px' }}>
                  <Print />
                </div> */}
                            </>
                        );
                    }}
                </Toolbar>
            </div>
            <div
                style={{
                    flex: 1,
                    overflow: "hidden",
                }}
            >
                {url && <Viewer fileUrl={url} plugins={[toolbarPluginInstance]} />}
            </div>
        </div>
    );
};

export default DocumentViewer;
