import { BehaviorSubject } from "rxjs";

const alertSubject = new BehaviorSubject(null);

const AlertService = {
    alert: alertSubject.asObservable(),
    success: (message) => {
        alertSubject.next({
            severity: "success",
            summary: "Success",
            detail: message,
            life: 3000,
        });
    },
    info: (message) => {
        alertSubject.next({
            severity: "info",
            summary: "Info",
            detail: message,
            life: 3000,
        });
    },
    sticky: (message) => {
        alertSubject.next({
            severity: "info",
            summary: "Info",
            sticky: true,
            detail: message,
            life: 3000,
        });
    },
    error: (message, showAfterRedirect = false) => {
        alertSubject.next({
            severity: "error",
            summary: "Error",
            detail: message,
            life: 3000,
            showAfterRedirect: showAfterRedirect,
        });
    },
    clear: () => {
        // if showAfterRedirect flag is true the alert is not cleared
        // for one route change (e.g. after successful registration)
        let alert = alertSubject.value;
        if (alert?.showAfterRedirect) {
            alert.showAfterRedirect = false;
        } else {
            alert = null;
        }
        alertSubject.next(alert);
    },
};

export default AlertService;
