import AuthContext from "../../../../auth/auth-context";
import AssessmentMasterService from "../../../../services/assessmentmaster.service";
import AlertService from "../../../../services/alert.service";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MasterAssessments = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [assessments, setAssessments] = useState([]);
    const authContext = useContext(AuthContext);

    const assessmentAccess = authContext.getPermissions("assessmentsmaster");
    if (assessmentAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }
    const showAdd = assessmentAccess.filter((f) => f.access.includes(2)).length > 0;

    const loadAssessments = useCallback(async () => {
        setLoading(true);
        const data = await AssessmentMasterService.GetAll(authContext);
        if (data.isSuccess) {
            setLoading(false);
            setAssessments(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    useEffect(() => {
        loadAssessments();
    }, [loadAssessments, authContext]);

    const onAddHandler = async () => {
        navigate("/master/assessments/add");
    };

    const onViewHandler = async (id) => {
        navigate(`/master/assessments/view/${id}`);
    };

    return (
        <Fragment>
            <div className="row m-0 pl-3 pr-3">
                {!loading &&
                    assessments.length > 0 &&
                    assessments.map((m, i) => (
                        <div
                            key={m.id}
                            className="col-3 text-center cursor-pointer"
                            onClick={() => onViewHandler(m.id)}
                        >
                            <div className="commando-container">
                                <div className="title">Assessment {i + 1}</div>
                                <div className="body">
                                    <span>{m.assessmentName}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                {!loading && assessments.length === 0 && (
                    <div className="col-12 text-center">No Assessments Available!!</div>
                )}
                {loading && <div className="col-12">Loading...</div>}
            </div>
            {showAdd && (
                <button type="button" onClick={onAddHandler} className="floating-button">
                    <i className="fa fa-plus"></i>
                </button>
            )}
        </Fragment>
    );
};

export default MasterAssessments;
