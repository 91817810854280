import "../../app.css";
import AlertService from "../../../../services/alert.service";
import QuizService from "../../../../services/quiz.service";
import CourseService from "../../../../services/course.service";
import AuthContext from "../../../../auth/auth-context";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";

const ViewQuiz = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState([]);
    const times = [
        { value: 5, label: "5 mins" },
        { value: 10, label: "10 mins" },
        { value: 15, label: "15 mins" },
        { value: 20, label: "20 mins" },
        { value: 25, label: "25 mins" },
        { value: 30, label: "30 mins" },
        { value: 35, label: "35 mins" },
        { value: 40, label: "40 mins" },
        { value: 45, label: "45 mins" },
        { value: 50, label: "50 mins" },
        { value: 55, label: "55 mins" },
        { value: 60, label: "60 mins" },
    ];

    const [quizId, setQuizId] = useState(0);
    const [quizName, setQuizName] = useState();
    const [courseId, setCourseId] = useState();
    const [mcq, setMCQ] = useState(false);
    const [quizTimeInMinutes, setQuizTimeInMinutes] = useState();

    const quizNameRef = useRef();

    const authContext = useContext(AuthContext);

    const quizAccess = authContext.getPermissions("quizmaster");
    if (quizAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }
    const showAdd = quizAccess.filter((f) => f.access.includes(2)).length > 0;
    const showEdit = quizAccess.filter((f) => f.access.includes(3)).length > 0;
    const showDelete = quizAccess.filter((f) => f.access.includes(4)).length > 0;

    const loadCourses = useCallback(async () => {
        setLoading(true);
        const data = await CourseService.GetCourses(authContext);
        if (data.isSuccess) {
            setLoading(false);
            setCourses(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    useEffect(() => {
        loadCourses();
    }, [loadCourses, authContext]);

    const loadQuiz = useCallback(async () => {
        setLoading(true);
        const data = await QuizService.Get(params.id, authContext);
        if (data.isSuccess) {
            setLoading(false);
            setQuizName(data.data.quizName);
            setCourseId(data.data.courseId);
            setMCQ(data.data.mcq);
            setQuizTimeInMinutes(data.data.quizTimeInMinutes);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext, params.id]);

    useEffect(() => {
        if (params.id) {
            setQuizId(params.id);
            loadQuiz();
        }
    }, [loadQuiz, authContext, params.id]);

    const onCancelHandler = async (event) => {
        navigate("/master/quizzes");
    };

    const onEditHandler = async (event) => {
        navigate(`/master/quizzes/edit/${quizId}`);
    };

    const onConfirmDeleteHandler = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Are you sure you want to delete this quiz?",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept: onDeleteHandler,
        });
    };

    const onDeleteHandler = async () => {
        let data = await QuizService.Remove(quizId, authContext);
        if (data.isSuccess) {
            AlertService.success("Quiz details are deleted successfully!");
            navigate("/master/quizzes");
        } else {
            AlertService.error(data.errorMessage);
        }
    };

    return (
        <Fragment>
            {!loading && (
                <form>
                    <div className="row pt-4 catalogue">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Quiz Name</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Quiz Name"
                                maxLength="150"
                                disabled
                                defaultValue={quizName}
                                ref={quizNameRef}
                            />
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Course</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <Dropdown
                                value={courseId}
                                onChange={(e) => setCourseId(e.value)}
                                disabled
                                options={courses}
                                optionLabel="label"
                                placeholder="Select a course"
                                className="w-full"
                            />
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Quiz Type</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <div className="flex align-items-center">
                                <Checkbox
                                    inputId="mcqId"
                                    onChange={(e) => setMCQ(e.checked)}
                                    checked={mcq}
                                    disabled
                                ></Checkbox>
                                <label htmlFor="mcqId" className="ml-2 mt-1 mr-2">
                                    {" "}
                                    MCQ
                                </label>
                            </div>
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Duration </div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <Dropdown
                                value={quizTimeInMinutes}
                                disabled
                                options={times}
                                optionLabel="label"
                                placeholder="Select a duration"
                                className="w-full"
                            />
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-2 mb-3">
                        <div className="col-md-3 col-sm-5 d-xs-none"></div>
                        <div className="col-2">
                            <div className="text-center">
                                <div className="course-card">
                                    <span>MCQ</span>
                                    {(showAdd || showEdit) && (
                                        <div
                                            onClick={() => navigate(`/master/quizzes/mcq/${quizId}`)}
                                            title={"Click here to create/edit MCQ."}
                                        >
                                            <i className="fa fa-cog"></i> Create / Edit
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="text-center">
                                <div className="course-card">
                                    <span>Attendees</span>
                                    {(showAdd || showEdit) && (
                                        <div
                                            onClick={() => navigate(`/master/quizzes/attendees/${quizId}`)}
                                            title={"Click here to view attendees."}
                                        >
                                            <i className="fa fa-cog"></i> View
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-2 mb-3">
                        <div className="col-md-12 col-sm-12">
                            <div className="text-center pt-4">
                                {showEdit && quizId > 0 && (
                                    <button
                                        type="button"
                                        className="submit-button"
                                        onClick={onEditHandler}
                                        disabled={loading}
                                    >
                                        Edit
                                    </button>
                                )}
                                {showDelete && quizId > 0 && (
                                    <>
                                        <ConfirmPopup />
                                        <button
                                            type="button"
                                            className="remove-button ml-3"
                                            onClick={onConfirmDeleteHandler}
                                            disabled={loading}
                                        >
                                            Delete
                                        </button>
                                    </>
                                )}
                                <button
                                    type="button"
                                    className="cancel-button ml-3"
                                    onClick={onCancelHandler}
                                    disabled={loading}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Fragment>
    );
};

export default ViewQuiz;
