import { Fragment, useContext, useEffect, useRef } from "react";
import ViewObjective from "./viewObjective";
import ViewStep from "./viewStep";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import CatalogueService from "../../../services/catalogue.service";
import AuthContext from "../../../auth/auth-context";

const View = ({ incident, clearSelection, editable, courseId, courseName, statusHandler }) => {
    const hasObjectives = incident && incident.objectives && incident.objectives.length > 0;
    const authContext = useContext(AuthContext);

    const timeSpentRef = useRef({});

    useEffect(() => {
        if (incident) {
            if (!timeSpentRef.current[incident.id]) {
                timeSpentRef.current[incident.id] = Date.now(); // Store only if not exists
            }

            const startTime = timeSpentRef.current[incident.id]; // Copy ref value to variable

            return () => {
                const timeSpent = Math.floor((Date.now() - startTime) / 1000);
                console.log(`Time spent on incident ${incident.id}: ${timeSpent} seconds`);
            };
        }
    }, [incident]);

    const handleStatusChange = async (status) => {
        try {
            if (status === 2) {
                await CatalogueService.MarkAsCompleted(incident.id, authContext);
            } else {
                await CatalogueService.MarkAsIncomplete(incident.id, authContext);
            }
            incident.status = status;
            statusHandler(status);
        } catch (error) {
            console.error("Failed to update status:", error);
        }
    };

    const onConfirmHandler = (event, status) => {
        confirmPopup({
            target: event.currentTarget,
            message: `Are you sure you want to mark this as ${status === 2 ? "completed" : "incomplete"}?`,
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept: () => handleStatusChange(status),
        });
    };

    return (
        <Fragment>
            {incident && (
                <div className="row m-0">
                    <ConfirmPopup />
                    <div className="col-9 course-title">
                        {courseName}
                        <button
                            type="button"
                            className={`btn-incident-back ${incident.status === 2 ? "completed" : "incomplete"}`}
                            onClick={(e) => onConfirmHandler(e, incident.status === 2 ? 0 : 2)}
                        >
                            <i className="fa fa-check-circle"></i>
                            <span>{incident.status === 2 ? "Completed" : "Incomplete"}</span>
                        </button>
                    </div>
                    <div className="col-3 course-title">
                        <button type="button" className="btn-incident-back" onClick={clearSelection}>
                            <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                        </button>
                    </div>

                    <div className={hasObjectives ? "col-md-9 col-sm-12" : "col-md-12 col-sm-12"}>
                        <div className="incident-view-container">
                            <div className="row m-0">
                                <div className="col-12">
                                    <span className="label">Incident Title: </span> {incident.incidentTitle}
                                </div>
                                {editable && (
                                    <>
                                        <div className="col-12">
                                            <span className="label">Tags: </span> {incident.tags}
                                        </div>
                                        <div className="col-12">
                                            <span className="label">Category: </span> {incident.category}
                                        </div>
                                    </>
                                )}
                                <div className="col-12">
                                    <span className="label">Story Board</span> <br />
                                    {incident.storyBoard}
                                </div>
                                <div className="col-12">
                                    <span className="label">Task</span> <br />
                                    {incident.task}
                                </div>
                                <div className="col-12">
                                    <span className="label">Solution</span> <br />
                                    {incident.steps?.map((step, i) => (
                                        <ViewStep key={i} step={step.description}></ViewStep>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    {hasObjectives && (
                        <div className="col-3 objective-view-container">
                            {incident.objectives.map((objective, i) => (
                                <ViewObjective key={i} objective={objective}></ViewObjective>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    );
};

export default View;
