import { Fragment, useContext, useEffect, useState } from "react";
import AuthContext from "../../../auth/auth-context";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardCard from "./dashboard-card";
import CatalogueService from "../../../services/catalogue.service";

const Dashboard = () => {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [hasIncidents, setHasIncidents] = useState(false);

    const catelogueAccess = authContext.getPermissions("catalogues");
    const courseAccess = authContext.getPermissions("courses");
    const bayAccess = authContext.getPermissions("bays");
    const assessmentAccess = authContext.getPermissions("assessments");
    const reportAccess = authContext.getPermissions("report");
    const supportAccess = authContext.getPermissions("support");
    const documentAccess = authContext.getPermissions("documents");
    const agentAccess = authContext.getPermissions("agents");

    const showCatalogue = catelogueAccess.filter((f) => f.access.includes(1)).length > 0;
    const showCourse = courseAccess.filter((f) => f.access.includes(1)).length > 0;
    const showBay = bayAccess.filter((f) => f.access.includes(1)).length > 0;
    const showAssessment = assessmentAccess.filter((f) => f.access.includes(1)).length > 0;
    const showReport = reportAccess.filter((f) => f.access.includes(1)).length > 0;
    const showSupport = supportAccess.filter((f) => f.access.includes(1)).length > 0;
    const showDocument = documentAccess.filter((f) => f.access.includes(1)).length > 0;
    const showAgents = agentAccess.filter((f) => f.access.includes(1)).length > 0;

    useEffect(() => {
        if (authContext.isLoggedIn === false) {
            navigate("login");
        }
        if (
            authContext.mfaSetupEnabled === true &&
            (authContext.mfaSetupCompleted === undefined || authContext.mfaSetupCompleted === false)
        ) {
            navigate("qrcode");
        }
        if (
            authContext.mfaSetupEnabled === true &&
            (authContext.mfaVerified === undefined || authContext.mfaVerified === false)
        ) {
            navigate("mfa");
        }
        async function fetchData() {
            let result = await CatalogueService.HasIncidents(authContext);
            if (result !== undefined && result !== null && result.isSuccess) {
                setHasIncidents(result.data);
            } else {
                setHasIncidents(false);
            }
        }
        fetchData();
    }, [location, authContext, navigate]);

    return (
        <Fragment>
            <div className="dashboard">
                <div className="row m-0">
                    {showCourse && (
                        <DashboardCard
                            navLink="/courses"
                            imageLink="assets/img/courses.svg"
                            displayName="Courses"
                            helpText="Click on the card to open courses page."
                        ></DashboardCard>
                    )}
                    {showDocument && (
                        <DashboardCard
                            navLink="/doctypes"
                            imageLink="assets/img/documents.png"
                            displayName="Documents"
                            helpText="Click on the card to open Document page."
                        ></DashboardCard>
                    )}
                    {showCatalogue && hasIncidents && (
                        <DashboardCard
                            navLink="/types"
                            imageLink="assets/img/catalogue.svg"
                            displayName="Catalogue"
                            helpText="Click on the card to open Catalogue page."
                        ></DashboardCard>
                    )}
                    {showBay && (
                        <DashboardCard
                            navLink="/bays"
                            imageLink="assets/img/bays.svg"
                            displayName="Cyber Range"
                            helpText="Click on the card to open Bays page."
                        ></DashboardCard>
                    )}
                    {showSupport && (
                        <DashboardCard
                            navLink="/support"
                            imageLink="assets/img/support.svg"
                            displayName="Support"
                            helpText="Click on the card to open support page."
                        ></DashboardCard>
                    )}
                    {showAssessment && (
                        <DashboardCard
                            navLink="/assessments"
                            imageLink="assets/img/assessment.svg"
                            displayName="Assessment"
                            helpText="Click on the card to open Assessment page."
                        ></DashboardCard>
                    )}
                    {showReport && (
                        <DashboardCard
                            navLink="/reports"
                            imageLink="assets/img/report.svg"
                            displayName="Report"
                            helpText="Click on the card to open Report page."
                        ></DashboardCard>
                    )}
                    {/* {showDocument && (
            <DashboardCard
              navLink="/trainers"
              imageLink="assets/img/schedule.svg"
              displayName="Trainer Data"
              helpText="Click on the card to open trainers page."
            ></DashboardCard>
          )} */}
                    {showDocument && (
                        <DashboardCard
                            navLink="/quizzes"
                            imageLink="assets/img/quiz.png"
                            displayName="Quiz"
                            helpText="Click on the card to open quiz page."
                        ></DashboardCard>
                    )}
                    {showAgents && (
                        <DashboardCard
                            navLink="/support/agent"
                            imageLink="assets/img/support.svg"
                            displayName="My Tickets"
                            helpText="Click on the card to open agent support page."
                        ></DashboardCard>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default Dashboard;
