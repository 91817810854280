import { Fragment, useEffect, useState } from "react";

const PracticalQuestion = ({ index, input, changeHandler, removeHandler }) => {
    const assessmentId = (input && input.assessmentId) ?? 0;
    const questionId = (input && input.id) ?? 0;
    const [storyBoardTitle, setStoryBoardTitle] = useState(input && input.storyBoardTitle);
    const [storyBoardInfo, setStoryBoardInfo] = useState(input && input.storyBoardInfo);
    const [taskTitle, setTaskTitle] = useState(input && input.taskTitle);
    const [taskInfo, setTaskInfo] = useState(input && input.taskInfo);

    useEffect(() => {
        let payload = {
            id: questionId,
            assessmentId: assessmentId,
            storyBoardTitle: storyBoardTitle,
            storyBoardInfo: storyBoardInfo,
            taskTitle: taskTitle,
            taskInfo: taskInfo,
        };
        changeHandler(index, payload);
    }, [index, assessmentId, storyBoardTitle, storyBoardInfo, taskTitle, taskInfo]);

    return (
        <Fragment>
            <div className="row m-0 pl-3 pr-3">
                <div className="col-12">
                    <label>Question No: {index + 1}</label>
                </div>
            </div>
            <div className="row m-0 pl-3 pr-3">
                <div className="col-10">
                    <input
                        className="form-control question"
                        placeholder="Story Board Title"
                        defaultValue={storyBoardTitle}
                        onChange={(e) => {
                            setStoryBoardTitle(e.target.value);
                        }}
                    ></input>
                </div>
                <div className="col-2">
                    {index > 0 && (
                        <button
                            type="button"
                            title="Remove Question"
                            className="text-danger btn-icon"
                            onClick={() => removeHandler(index)}
                        >
                            <i className="pi pi-trash"></i>
                        </button>
                    )}
                </div>
            </div>
            <div className="row m-0 pl-3 pr-3">
                <div className="col-10">
                    <textarea
                        className="form-control"
                        placeholder="Story Board Info"
                        defaultValue={storyBoardInfo}
                        onChange={(e) => {
                            setStoryBoardInfo(e.target.value);
                        }}
                    ></textarea>
                </div>
                <div className="col-2"></div>
            </div>
            <div className="row m-0 pl-3 pr-3">
                <div className="col-10">
                    <input
                        className="form-control question"
                        placeholder="Task Title"
                        defaultValue={taskTitle}
                        onChange={(e) => {
                            setTaskTitle(e.target.value);
                        }}
                    ></input>
                </div>
                <div className="col-2"></div>
            </div>
            <div className="row m-0 pl-3 pr-3">
                <div className="col-10">
                    <input
                        className="form-control"
                        placeholder="Task Info"
                        defaultValue={taskInfo}
                        onChange={(e) => {
                            setTaskInfo(e.target.value);
                        }}
                    ></input>
                </div>
                <div className="col-2"></div>
            </div>
        </Fragment>
    );
};

export default PracticalQuestion;
