import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import AuthContext from "../../../auth/auth-context";
import UserService from "../../../services/user.service";
import AlertService from "../../../services/alert.service";
import "../identity.css";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

const RemoveUser = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tag, setTag] = useState();
    const [tags, setTags] = useState();
    const [buttonText, setButtonText] = useState("Remove");

    const authContext = useContext(AuthContext);

    const assessmentAccess = authContext.getPermissions("learners");

    if (assessmentAccess.filter((f) => f.access.includes(1) && f.access.includes(4)).length === 0) {
        navigate("/");
    }

    const loadTags = useCallback(async () => {
        setLoading(true);
        const data = await UserService.GetAllTags(authContext);
        if (data.isSuccess) {
            setLoading(false);
            setTags(
                data.data.map((m) => ({
                    id: m,
                    key: m,
                    label: m,
                    data: m,
                }))
            );
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    useEffect(() => {
        loadTags();
    }, [loadTags, authContext]);

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        if (tag && tag.key) {
            setLoading(true);
            setButtonText("Processing");
            try {
                let data = await UserService.RemoveByTag(tag.key, authContext);
                setLoading(false);
                if (data.isSuccess) {
                    AlertService.success(`Learners, associated to '${tag.key}' are removed successfully!`);
                } else {
                    AlertService.error(data.errorMessage);
                }
            } catch {}
            setButtonText("Remove");
            setLoading(false);
        } else {
            AlertService.error("Please select tag");
        }
    };

    return (
        <Fragment>
            <div className="container-fluid">
                <h2>Remove User/Learners</h2>
                <div className="row m-0 pt-3">
                    <div className="col-3 text-right m-auto">Select Tag</div>
                    <div className="col-9">
                        <Dropdown
                            value={tag}
                            onChange={(e) => setTag(e.value)}
                            options={tags}
                            optionLabel="label"
                            placeholder="Select a tag"
                            className="w-full"
                        />
                    </div>
                </div>
                <div className="row m-0 pt-3">
                    <div className="col-3 text-right m-auto"></div>
                    <div className="col-9">
                        <small className="text-info">
                            <b>Note : </b> Users, Whoever is associated with the selected tag, under learner role will
                            be removed. Other users who has more roles will not be removed.
                        </small>
                    </div>
                </div>
                <div className="row pt-2 mb-3">
                    <div className="col-md-12 col-sm-12">
                        <div className="text-center pt-4">
                            <Button label={buttonText} loading={loading} onClick={onSubmitHandler} />
                            <button
                                type="button"
                                className="cancel-button ml-3"
                                onClick={() => navigate("/learners")}
                                disabled={loading}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default RemoveUser;
