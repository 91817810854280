import { useNavigate } from "react-router-dom";
import { useCallback, useContext, Fragment, useRef, useState, useEffect } from "react";
import AuthContext from "../../../auth/auth-context";
import QuestionsService from "../../../services/questions.service";
import AlertService from "../../../services/alert.service";
import Header from "../header";
import "../identity.css";

const Questions = () => {
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);

    const [q1IsValid, setQ1IsValid] = useState(true);
    const [q2IsValid, setQ2IsValid] = useState(true);
    const [q3IsValid, setQ3IsValid] = useState(true);
    // const [q4IsValid, setQ4IsValid] = useState(true);
    // const [q5IsValid, setQ5IsValid] = useState(true);
    const [a1IsValid, setA1IsValid] = useState(true);
    const [a2IsValid, setA2IsValid] = useState(true);
    const [a3IsValid, setA3IsValid] = useState(true);
    // const [a4IsValid, setA4IsValid] = useState(true);
    // const [a5IsValid, setA5IsValid] = useState(true);

    const [loading, setLoading] = useState(false);

    const q1Ref = useRef();
    const a1Ref = useRef();
    const q2Ref = useRef();
    const a2Ref = useRef();
    const q3Ref = useRef();
    const a3Ref = useRef();
    // const q4Ref = useRef();
    // const a4Ref = useRef();
    // const q5Ref = useRef();
    // const a5Ref = useRef();

    const authContext = useContext(AuthContext);

    const validateAnswered = useCallback(() => {
        if (authContext.isAnswered) {
            navigate("/");
        }
    }, [authContext, navigate]);

    const loadQuestions = useCallback(async () => {
        setLoading(true);
        const data = await QuestionsService.GetAllQuestions(authContext);
        if (data.isSuccess) {
            setLoading(false);
            setQuestions(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    useEffect(() => {
        validateAnswered();
        loadQuestions();
    }, [validateAnswered, loadQuestions, authContext]);

    const onSubmitHandler = async (event) => {
        setQ1IsValid(true);
        setA1IsValid(true);
        setQ2IsValid(true);
        setA2IsValid(true);
        setQ3IsValid(true);
        setA3IsValid(true);
        // setQ4IsValid(true);
        // setA4IsValid(true);
        // setQ5IsValid(true);
        // setA5IsValid(true);
        event.preventDefault();
        const q1Value = q1Ref.current.value;
        const a1Value = a1Ref.current.value;
        const q2Value = q2Ref.current.value;
        const a2Value = a2Ref.current.value;
        const q3Value = q3Ref.current.value;
        const a3Value = a3Ref.current.value;
        // const q4Value = q4Ref.current.value;
        // const a4Value = a4Ref.current.value;
        // const q5Value = q5Ref.current.value;
        // const a5Value = a5Ref.current.value;

        if (!q1Value) {
            setQ1IsValid(false);
            return false;
        }
        if (!a1Value) {
            setA1IsValid(false);
            return false;
        }
        if (!q2Value) {
            setQ2IsValid(false);
            return false;
        }
        if (!a2Value) {
            setA2IsValid(false);
            return false;
        }
        if (!q3Value) {
            setQ3IsValid(false);
            return false;
        }
        if (!a3Value) {
            setA3IsValid(false);
            return false;
        }
        // if (!q4Value) {
        //   setQ4IsValid(false);
        //   return false;
        // }
        // if (!a4Value) {
        //   setA4IsValid(false);
        //   return false;
        // }
        // if (!q5Value) {
        //   setQ5IsValid(false);
        //   return false;
        // }
        // if (!a5Value) {
        //   setA5IsValid(false);
        //   return false;
        // }

        if (
            q1IsValid &&
            a1IsValid &&
            q2IsValid &&
            a2IsValid &&
            q3IsValid &&
            a3IsValid /*&&
      q4IsValid &&
      a4IsValid &&
      q5IsValid &&
      a5IsValid*/
        ) {
            try {
                setLoading(true);
                let payload = [
                    { questionId: q1Value, answer: a1Value },
                    { questionId: q2Value, answer: a2Value },
                    { questionId: q3Value, answer: a3Value },
                    // { questionId: q4Value, answer: a4Value },
                    // { questionId: q5Value, answer: a5Value },
                ];
                let data = await QuestionsService.Save(payload, authContext);
                if (data.isSuccess) {
                    authContext.setAnswered(true);
                    if (authContext.resetPassword) {
                        navigate("/reset");
                    } else if (authContext.mfaSetupEnabled === true && authContext.mfaSetupCompleted === false) {
                        navigate("/qrcode");
                    } else {
                        navigate("/logout");
                    }
                } else {
                    AlertService.error(data.errorMessage);
                }
                setLoading(false);
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    };

    return (
        <Fragment>
            <div className="identity-container">
                <Header />
                <div className="row identity-wrapper">
                    <div className="identity-form questions-form">
                        <h1>Security Questions</h1>
                        <form onSubmit={onSubmitHandler}>
                            <center>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sl. No</th>
                                            <th>Question</th>
                                            <th>Answer</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1.</td>
                                            <td>
                                                <select required className="form-control" ref={q1Ref}>
                                                    {questions &&
                                                        questions.map((question) => (
                                                            <option key={question.id} value={question.id}>
                                                                {question.question}
                                                            </option>
                                                        ))}
                                                </select>
                                                {!q1IsValid && <p className="error">Please select question</p>}
                                            </td>
                                            <td>
                                                <input
                                                    autoFocus
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Answer"
                                                    required
                                                    maxLength="30"
                                                    ref={a1Ref}
                                                />
                                                {!a1IsValid && <p className="error">Please provide answer</p>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2.</td>
                                            <td>
                                                <select required className="form-control" ref={q2Ref}>
                                                    {questions &&
                                                        questions.map((question) => (
                                                            <option key={question.id} value={question.id}>
                                                                {question.question}
                                                            </option>
                                                        ))}
                                                </select>
                                                {!q2IsValid && <p className="error">Please select question</p>}
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Answer"
                                                    required
                                                    maxLength="30"
                                                    ref={a2Ref}
                                                />
                                                {!a2IsValid && <p className="error">Please provide answer</p>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3.</td>
                                            <td>
                                                <select required className="form-control" ref={q3Ref}>
                                                    {questions &&
                                                        questions.map((question) => (
                                                            <option key={question.id} value={question.id}>
                                                                {question.question}
                                                            </option>
                                                        ))}
                                                </select>
                                                {!q3IsValid && <p className="error">Please select question</p>}
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Answer"
                                                    required
                                                    maxLength="30"
                                                    ref={a3Ref}
                                                />
                                                {!a3IsValid && <p className="error">Please provide answer</p>}
                                            </td>
                                        </tr>
                                        {/* <tr>
                      <td>4.</td>
                      <td>
                        <select required className="form-control" ref={q4Ref}>
                          {questions &&
                            questions.map((question) => (
                              <option key={question.id} value={question.id}>
                                {question.question}
                              </option>
                            ))}
                        </select>
                        {!q4IsValid && (
                          <p className="error">Please select question</p>
                        )}
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Answer"
                          required
                          maxLength="30"
                          ref={a4Ref}
                        />
                        {!a4IsValid && (
                          <p className="error">Please provide answer</p>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>5.</td>
                      <td>
                        <select required className="form-control" ref={q5Ref}>
                          {questions &&
                            questions.map((question) => (
                              <option key={question.id} value={question.id}>
                                {question.question}
                              </option>
                            ))}
                        </select>
                        {!q5IsValid && (
                          <p className="error">Please select question</p>
                        )}
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Answer"
                          required
                          maxLength="30"
                          ref={a5Ref}
                        />
                        {!a5IsValid && (
                          <p className="error">Please provide answer</p>
                        )}
                      </td>
                    </tr> */}
                                    </tbody>
                                </table>
                            </center>
                            <div className="mb-3 mt-2 input-icons"></div>
                            <div className="text-center pt-4">
                                <button type="submit" className="identity-button" disabled={loading}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Questions;
