import { Fragment } from "react";

const ReviewPracticalExamQuestion = ({ input, index, selectedIndex }) => {
    const storyBoardTitle = input && input.storyBoardTitle;
    const storyBoardInfo = input && input.storyBoardInfo;
    const taskTitle = input && input.taskTitle;
    const taskInfo = input && input.taskInfo;
    const answer = input && input.answer;

    return (
        <Fragment>
            <div className={selectedIndex === index ? "d-block" : "d-none"}>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">{storyBoardTitle}</span>
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="story-info">{storyBoardInfo}</div>
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">{taskTitle}</span>
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="story-info">{taskInfo}</div>
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">Answer</span>
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="border" dangerouslySetInnerHTML={{ __html: answer }}></div>
                    </div>
                    <div className="col-2"></div>
                </div>
            </div>
        </Fragment>
    );
};

export default ReviewPracticalExamQuestion;
