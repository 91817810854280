import { InputNumber } from "primereact/inputnumber";
import AuthContext from "../../../../auth/auth-context";
import PracticalExamService from "../../../../services/practicalexam.service";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";

const PracticalExamQuestionView = ({ index, selectedIndex, input, changeHandler }) => {
    const storyBoardTitle = input && input.storyBoardTitle;
    const storyBoardInfo = input && input.storyBoardInfo;
    const taskTitle = input && input.taskTitle;
    const taskInfo = input && input.taskInfo;
    const fileName = input && input.fileName;
    const answer = input && input.answer;
    const [href, setHref] = useState();
    const [score, setScore] = useState(input && input.score);
    const [comment, setComment] = useState(input && input.comments);
    const [fileNotFound, setFileNotFound] = useState(false);

    const authContext = useContext(AuthContext);

    const loadDocument = useCallback(async () => {
        setFileNotFound(false);
        if (fileName && fileName !== "") {
            let data = await PracticalExamService.GetFile(input.answerId, authContext);
            if (data.isSuccess && data.data && data.data != null) {
                setHref(`data:${data.data.contentType};base64,${data.data.filePath}`);
            } else {
                setFileNotFound(true);
            }
        }
    }, [authContext, input.answerId, fileName]);

    useEffect(() => {
        loadDocument();
    }, [authContext, loadDocument, input.answerId]);

    useEffect(() => {
        changeHandler(index, score, comment);
    }, [score, comment]);

    return (
        <Fragment>
            <div className={selectedIndex === index ? "d-block" : "d-none"}>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">{storyBoardTitle}</span>
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="story-info">{storyBoardInfo}</div>
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">{taskTitle}</span>
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="story-info">{taskInfo}</div>
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">Answer</span>
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <div className="border" dangerouslySetInnerHTML={{ __html: answer }}></div>
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">Score</span>
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <InputNumber
                            inputId="integeronly"
                            maxFractionDigits={2}
                            minFractionDigits={2}
                            useGrouping={false}
                            min={0}
                            placeholder="Score"
                            max={100.0}
                            maxLength="3"
                            format={false}
                            value={score}
                            onValueChange={(e) => {
                                setScore(e.value);
                            }}
                            className="w-full"
                        />
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <span className="form-control question">Comment</span>
                    </div>
                    <div className="col-2"></div>
                </div>
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <textarea
                            placeholder="Comments"
                            className="form-control no-resize cancel-reason"
                            maxLength={500}
                            defaultValue={comment}
                            onChange={(e) => {
                                setComment(e.target.value);
                            }}
                        ></textarea>
                    </div>
                    <div className="col-2"></div>
                </div>
                {fileName && fileName !== "" && (
                    <>
                        <div className="row m-0 pl-3 pr-3">
                            <div className="col-10">
                                <span className="form-control question">Attachments</span>
                            </div>
                            <div className="col-2"></div>
                        </div>
                        <div className="row m-0 pl-3 pr-3">
                            <div className="col-10 value document-files">
                                <div className="attachment-container">
                                    <div className="file">
                                        <a href={href} download={fileName}>
                                            {fileName}
                                        </a>{" "}
                                        {fileNotFound && <span className="text-danger">( File not found )</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Fragment>
    );
};

export default PracticalExamQuestionView;
