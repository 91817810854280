import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../auth/auth-context";
import Card from "./card";

const Reports = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [hasQuizReportAccess, setHasQuizReportAccess] = useState(false);
    const [hasAssessmentReportAccess, setHasAssessmentReportAccess] = useState(false);
    const [hasIncidentUserReportAccess, setHasIncidentUserReportAccess] = useState(false);
    const [hasIncidentMISReportAccess, setHasIncidentMISReportAccess] = useState(false);
    const authContext = useContext(AuthContext);

    useEffect(() => {
        const courseAccess = authContext.getPermissions("quizreport");
        if (courseAccess.filter((f) => f.access.includes(1)).length > 0 || authContext.onGoingValidations) {
            setHasQuizReportAccess(true);
            setLoading(false);
        }
        const assReportAccess = authContext.getPermissions("assessmentreport");
        if (assReportAccess.filter((f) => f.access.includes(1)).length > 0 || authContext.onGoingValidations) {
            setHasAssessmentReportAccess(true);
            setLoading(false);
        }
        const incidentUserReportAccess = authContext.getPermissions("incidentuserreport");
        if (incidentUserReportAccess.filter((f) => f.access.includes(1)).length > 0 || authContext.onGoingValidations) {
            setHasIncidentUserReportAccess(true);
            setLoading(false);
        }
        const incidentMISReportAccess = authContext.getPermissions("incidentmisreport");
        if (incidentMISReportAccess.filter((f) => f.access.includes(1)).length > 0 || authContext.onGoingValidations) {
            setHasIncidentMISReportAccess(true);
            setLoading(false);
        }
    }, [authContext]);

    const onCardClick = async (url) => {
        navigate(url);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                {hasQuizReportAccess && (
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                        <Card title={"Quiz Report"} clickHandler={() => onCardClick("/reports/quizreport")}></Card>
                    </div>
                )}
                {hasAssessmentReportAccess && (
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                        <Card
                            title={"Assessment Report"}
                            clickHandler={() => onCardClick("/reports/assessmentreport")}
                        ></Card>
                    </div>
                )}
                {hasAssessmentReportAccess && (
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                        <Card
                            title={"Assessment Practical Report"}
                            clickHandler={() => onCardClick("/reports/assessmentreport/practical")}
                        ></Card>
                    </div>
                )}
                {hasIncidentUserReportAccess && (
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                        <Card
                            title={"Incident Report"}
                            clickHandler={() => onCardClick("/reports/incidentuserreport")}
                        ></Card>
                    </div>
                )}
                {hasIncidentMISReportAccess && (
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                        <Card
                            title={"Incident MIS Report"}
                            clickHandler={() => onCardClick("/reports/incidentreport")}
                        ></Card>
                    </div>
                )}
                {!loading && !hasQuizReportAccess && !hasAssessmentReportAccess && (
                    <div className="col-12">No reports available...</div>
                )}
                {loading && <div className="col-12">Loading...</div>}
            </div>
        </div>
    );
};

export default Reports;
