import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";

import UserService from "../../../services/user.service";
import AlertService from "../../../services/alert.service";
import Header from "../header";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../auth/auth-context";
import "../identity.css";

const MFAQ = () => {
    const navigate = useNavigate();
    const [ansIsValid, setAnsIsValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [questions, setQuestions] = useState([]);

    const ans1Ref = useRef();
    const ans2Ref = useRef();

    const authContext = useContext(AuthContext);

    const loadQuestions = useCallback(async () => {
        let result = await UserService.GetMFAQ(authContext);
        setQuestions(result.data);
    }, [authContext]);

    const validateVerified = useCallback(() => {
        if (authContext.mfaVerified) {
            navigate("/");
        }
    }, [authContext, navigate]);

    useEffect(() => {
        validateVerified();
        loadQuestions();
    }, [validateVerified, loadQuestions, authContext]);

    const onSubmitHandler = async (event) => {
        setAnsIsValid(true);
        event.preventDefault();
        const ans1Value = ans1Ref.current.value;
        const ans2Value = ans2Ref.current.value;

        if (!ans1Value) {
            setAnsIsValid(false);
            return false;
        }

        if (!ans2Value) {
            setAnsIsValid(false);
            return false;
        }

        if (ansIsValid) {
            try {
                setLoading(true);
                let payload = [
                    { questionId: questions[0].id, answer: ans1Value },
                    { questionId: questions[1].id, answer: ans2Value },
                ];
                const result = await UserService.ValidateSQ(payload, authContext);
                setLoading(false);
                if (result.isSuccess) {
                    authContext.setMFAVerfied(true);
                    setLoading(false);
                    navigate("/");
                } else {
                    setLoading(false);
                    AlertService.error(result.errorMessage);
                }
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    };

    return (
        <Fragment>
            <div className="identity-container">
                <Header />
                <div className="row identity-wrapper">
                    <div className="col-xl-6 col-lg-12">
                        {!loading && (
                            <div className="identity-form">
                                <h1>Security Questions</h1>
                                <form onSubmit={onSubmitHandler}>
                                    <div className="row m-0">
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                        <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                            <small>Security Question 1</small>
                                            <br></br>
                                            {questions && questions.length && (
                                                <span>
                                                    <b>{questions[0].question}</b>
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                        <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                            <div className="input-container-password">
                                                <input
                                                    autoFocus
                                                    type="password"
                                                    id="pwd1"
                                                    className="mfaq-textbox"
                                                    placeholder="Answer here"
                                                    name="pswd1"
                                                    maxLength="30"
                                                    required
                                                    ref={ans1Ref}
                                                    onChange={() => setLoading(false)}
                                                    onKeyUp={() => setLoading(false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                        <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                            <small>Security Question 2</small>
                                            <br></br>
                                            {questions && questions.length && (
                                                <span>
                                                    <b>{questions[1].question}</b>
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                        <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                            <div className="input-container-password">
                                                <input
                                                    type="password"
                                                    id="pwd2"
                                                    className="mfaq-textbox"
                                                    placeholder="Answer here"
                                                    name="pswd2"
                                                    required
                                                    maxLength="30"
                                                    ref={ans2Ref}
                                                    onChange={() => setLoading(false)}
                                                    onKeyUp={() => setLoading(false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    </div>
                                    <div className="text-center pt-4">
                                        <button type="submit" className="identity-button" disabled={loading}>
                                            Verify
                                        </button>
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                    <div className="col-lg-6 d-none d-xl-block identity-image">
                        <img alt="QrCode" src="assets/img/login.png"></img>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default MFAQ;
