import "../app.css";
import AlertService from "../../../services/alert.service";
import DocumentService from "../../../services/document.service";
import AuthContext from "../../../auth/auth-context";
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";

const AddDocument = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);

    const [documentId, setDocumentId] = useState(0);
    const [documentName, setDocumentName] = useState();
    // const [description, setDescription] = useState();
    const [files, setFiles] = useState([]);

    const [documentNameIsValid, setDocumentNameIsValid] = useState(true);
    // const [descriptionIsValid, setDescriptionIsValid] = useState(true);

    const documentNameRef = useRef();
    // const descriptionRef = useRef();
    const fileUploadRef = useRef();

    const authContext = useContext(AuthContext);

    const loadDocument = useCallback(async () => {
        setLoading(true);
        const data = await DocumentService.Get(params.id, authContext);
        if (data.isSuccess) {
            setLoading(false);
            setDocumentName(data.data.documentName);
            // setDescription(data.data.description);
            setFiles(data.data.attachments);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext, params.id]);

    useEffect(() => {
        if (params.id) {
            setDocumentId(params.id);
            loadDocument();
        }
    }, [loadDocument, authContext, params.id]);

    const onCancelHandler = async (event) => {
        navigate(`/documents/${params.typeid}`);
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        let isValid = true;
        let documentNameValue = documentNameRef.current.value;
        if (!documentNameValue) {
            setDocumentNameIsValid(false);
            isValid = false;
        }
        // let descriptionValue = descriptionRef.current.value;
        // if (!descriptionValue) {
        //   setDescriptionIsValid(false);
        //   isValid = false;
        // }
        if (isValid) {
            setLoading(true);
            try {
                const formData = new FormData();
                files
                    .filter((f) => f.data)
                    .forEach((file) => {
                        formData.append("files", file.data);
                    });
                formData.append(`documentName`, documentNameValue);
                formData.append(`documentTypeId`, params.typeid);
                // formData.append(`description`, descriptionValue);
                formData.append(`attachments`, []);
                if (documentId > 0) {
                    formData.append(`id`, documentId);
                }
                let data = await DocumentService.Save(formData, authContext);
                if (data.isSuccess) {
                    navigate(`/documents/${params.typeid}`);
                } else {
                    AlertService.error(data.errorMessage);
                }
                setLoading(false);
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    };

    const handleChange = (event) => {
        setFiles((prevFiles) => {
            for (let file of event.target.files) {
                prevFiles.push({
                    fileName: file.name,
                    filePath: file.name,
                    data: file,
                });
            }
            return [...prevFiles];
        });
        setTimeout(() => {
            fileUploadRef.current.value = "";
        }, 100);
    };

    const removeFileHandler = async (file) => {
        if (file.id) {
            let data = await DocumentService.RemoveDocument(file.id, authContext);
            if (data.isSuccess) {
                await removeFile(file);
            } else {
                AlertService.error(data.errorMessage);
            }
        } else {
            await removeFile(file);
        }
    };

    const removeFile = async (file) => {
        setFiles((prevFiles) => {
            prevFiles = prevFiles.filter((f) => f.fileName !== file.fileName);
            return [...prevFiles];
        });
    };

    const onConfirmDeleteHandler = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Are you sure you want to delete this document?",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept: onDeleteHandler,
        });
    };

    const onDeleteHandler = async () => {
        let data = await DocumentService.Remove(documentId, authContext);
        if (data.isSuccess) {
            AlertService.success("Document details deleted successfully!");
            navigate(`/documents/${params.typeid}`);
        } else {
            AlertService.error(data.errorMessage);
        }
    };

    return (
        <Fragment>
            <form onSubmit={onSubmitHandler}>
                <div className="row pt-4 catalogue">
                    <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    <div className="col-md-2 col-sm-3 col-xs-12 label">Document Name</div>
                    <div className="col-md-8 col-sm-5 col-xs-12 value">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Document Name"
                            maxLength="150"
                            defaultValue={documentName}
                            ref={documentNameRef}
                            disabled={loading}
                            onChange={(e) => {
                                setDocumentName(e.value);
                                setDocumentNameIsValid(true);
                            }}
                        />
                        {!documentNameIsValid && <p className="error">Please provide document name</p>}
                    </div>
                    <div className="col-md-1 col-sm-2 d-xs-none"></div>
                </div>
                {/* <div className="row pt-4">
          <div className="col-md-1 col-sm-2 d-xs-none"></div>
          <div className="col-md-2 col-sm-3 col-xs-12 label">Description</div>
          <div className="col-md-8 col-sm-5 col-xs-12 value">
            <textarea
              type="text"
              className="form-control"
              placeholder="Description"
              maxLength="800"
              defaultValue={description}
              ref={descriptionRef}
              disabled={loading}
              onChange={(e) => {
                setDescription(e.value);
                setDescriptionIsValid(true);
              }}
            />
            {!descriptionIsValid && (
              <p className="error">Please provide description</p>
            )}
          </div>
          <div className="col-md-1 col-sm-2 d-xs-none"></div>
        </div> */}
                <div className="row pt-4">
                    <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    <div className="col-md-2 col-sm-3 col-xs-12 label">Files</div>
                    <div className="col-md-8 col-sm-5 col-xs-12 value">
                        <div className="input-group">
                            <div className="custom-file">
                                <input
                                    ref={fileUploadRef}
                                    type="file"
                                    className="custom-file-input"
                                    onChange={handleChange}
                                    multiple
                                    disabled={loading}
                                    accept="application/pdf"
                                    id="fileuploaddocument"
                                ></input>
                                <label className="custom-file-label" htmlFor="fileuploaddocument">
                                    Choose file
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1 col-sm-2 d-xs-none"></div>
                </div>
                <div className="row pt-4">
                    <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    <div className="col-md-2 col-sm-3 col-xs-12 label"></div>
                    <div className="col-md-8 col-sm-5 col-xs-12 value document-files">
                        <div className="attachment-container">
                            {files &&
                                files.length > 0 &&
                                files.map((m, i) => (
                                    <div key={i} className="file">
                                        {!loading && (
                                            <i
                                                className="fa fa-trash mr-2 text-danger cursor-pointer"
                                                title={"Remove file"}
                                                onClick={() => removeFileHandler(m)}
                                            ></i>
                                        )}{" "}
                                        {m.fileName}
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="col-md-1 col-sm-2 d-xs-none"></div>
                </div>
                <div className="row pt-2 mb-3">
                    <div className="col-md-12 col-sm-12">
                        <div className="text-center pt-4">
                            <button type="submit" className="submit-button" disabled={loading}>
                                Submit
                            </button>
                            {documentId > 0 && (
                                <>
                                    <ConfirmPopup />
                                    <button
                                        type="button"
                                        className="remove-button ml-3"
                                        onClick={onConfirmDeleteHandler}
                                        disabled={loading}
                                    >
                                        Delete
                                    </button>
                                </>
                            )}
                            <button
                                type="button"
                                className="cancel-button ml-3"
                                onClick={onCancelHandler}
                                disabled={loading}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default AddDocument;
