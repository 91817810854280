import handler from "../middleware/httpHandler";

const sendAuthRequest = async (path, body) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
        },
    }).catch((err) => {
        return handler(err);
    });

    if (response.ok) {
        const data = await response.json();
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
    } else {
        handler(response);
    }
};

const sendPostRequest = async (path, body, context) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.token}`,
        },
    }).catch((err) => {
        return handler(err);
    });

    if (response.ok) {
        const data = await response.json();
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
    } else {
        handler(response);
    }
};

const sendPostFormDataRequest = async (path, formData, context) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: "POST",
        body: formData,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.token}`,
        },
    }).catch((err) => {
        return handler(err);
    });

    if (response.ok) {
        const data = await response.json();
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
    } else {
        handler(response);
    }
};

const sendFormDataRequest = async (path, formData, context) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: "POST",
        body: formData,
        headers: {
            Authorization: `Bearer ${context.token}`,
        },
    }).catch((err) => {
        return handler(err);
    });

    if (response.ok) {
        const data = await response.json();
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
    } else {
        handler(response);
    }
};

const sendPutRequest = async (path, context) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.token}`,
        },
    }).catch((err) => {
        return handler(err);
    });

    if (response.ok) {
        const data = await response.json();
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
    } else {
        handler(response);
    }
};

const sendPutRequestWithBody = async (path, body, context) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.token}`,
        },
    }).catch((err) => {
        return handler(err);
    });

    if (response.ok) {
        const data = await response.json();
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
    } else {
        handler(response);
    }
};

const sendUnAuthRequest = async (path, token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: "GET",
    }).catch((err) => {
        return handler(err);
    });
    if (response.ok) {
        const data = await response.json();
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
    } else {
        handler(response);
    }
};

const sendRequest = async (path, token) => {
    let headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: "GET",
        headers,
    }).catch((err) => {
        return handler(err);
    });
    if (response.ok) {
        const data = await response.json();
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
    } else {
        handler(response);
    }
};

const sendDeleteRequest = async (path, token) => {
    let headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: "DELETE",
        headers,
    }).catch((err) => {
        return handler(err);
    });
    if (response.ok) {
        const data = await response.json();
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
    } else {
        handler(response);
    }
};

const sendUnAuthPostRequest = async (path, body) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
        },
    }).catch((err) => {
        return handler(err);
    });

    if (response.ok) {
        const data = await response.json();
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
    } else {
        handler(response);
    }
};

const sendUnAuthPostFormDataRequest = async (path, formData) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: "POST",
        body: formData,
    }).catch((err) => {
        return handler(err);
    });

    if (response.ok) {
        const data = await response.json();
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
    } else {
        handler(response);
    }
};

const request = {
    sendPostRequest,
    sendAuthRequest,
    sendRequest,
    sendUnAuthRequest,
    sendDeleteRequest,
    sendPutRequest,
    sendPutRequestWithBody,
    sendPostFormDataRequest,
    sendUnAuthPostRequest,
    sendUnAuthPostFormDataRequest,
    sendFormDataRequest,
};

export default request;
