import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import AuthContext from "../../../auth/auth-context";
import ReportService from "../../../services/report.service";
import QuizService from "../../../services/quiz.service";
import AlertService from "../../../services/alert.service";
import { CSVLink } from "react-csv";
import "../support/support.css";

const QuizReport = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [quizzes, setQuizzes] = useState([]);
    const [quizId, setQuizId] = useState(0);

    const headers = [
        { label: "Display Name", key: "userDisplayName" },
        { label: "Email", key: "userEmail" },
        { label: "Quiz Name", key: "quizName" },
        { label: "Date", key: "attendedDate" },
        { label: "Correct Answers", key: "correctMCQAnswers" },
        { label: "Total Questions", key: "totalMCQQuestions" },
        { label: "Score", key: "mcqScore" },
    ];

    const authContext = useContext(AuthContext);
    const supportAccess = authContext.getPermissions("quizreport");
    if (supportAccess.filter((f) => f.access.includes(1)).length === 0 || authContext.onGoingValidations) {
        navigate("/");
    }

    const loadQuizzes = useCallback(async () => {
        setLoading(true);
        const data = await QuizService.GetList(authContext);
        if (data.isSuccess) {
            setLoading(false);
            if (data.data.length > 1) {
                data.data.splice(0, 0, { value: 0, label: "All" });
            }
            setQuizzes(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    const loadResults = useCallback(async () => {
        setLoading(true);
        const data = await ReportService.getQuizReport(quizId, authContext);
        if (data.isSuccess) {
            setLoading(false);
            setResults(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [quizId, authContext]);

    useEffect(() => {
        loadQuizzes();
    }, [loadQuizzes, authContext]);

    useEffect(() => {
        loadResults();
    }, [loadResults, quizId, authContext]);

    return (
        <Fragment>
            <div className="row m-0">
                <div className="col-6">
                    <h1 className="title">Quiz Report</h1>
                </div>
                <div className="col-6">
                    <button
                        type="button"
                        className="btn-incident-back float-right"
                        onClick={() => navigate("/reports")}
                    >
                        <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                    </button>
                    <CSVLink
                        data={results}
                        filename={"Quiz-Results.csv"}
                        className="btn btn-success float-right mr-2 ml-2 mt-1"
                        headers={headers}
                    >
                        <i className="fa fa-file-excel-o"></i>
                    </CSVLink>
                    <div className="btn-group float-right" role="group">
                        <Dropdown
                            value={quizId}
                            onChange={(e) => setQuizId(e.value)}
                            options={quizzes}
                            optionLabel="label"
                            placeholder="Select a quiz"
                            className="w-full"
                        />
                    </div>
                </div>
            </div>
            <div className="row m-0">
                <div className="col-12">
                    <div className="tickets">
                        <div className="row m-0">
                            <div className="col-3 text-center header-cell">Name</div>
                            <div className="col-2 text-center header-cell">Email</div>
                            <div className="col-2 text-center header-cell">Quiz Name</div>
                            <div className="col-2 text-center header-cell">Date</div>
                            <div className="col-1 text-center header-cell">Correct Answers</div>
                            <div className="col-1 text-center header-cell">Total Questions</div>
                            <div className="col-1 text-center header-cell">Score</div>
                        </div>
                        <div className="scroller">
                            {results &&
                                results.length > 0 &&
                                results.map((result, i) => (
                                    <div key={i} className="row m-0 records">
                                        <div className="col-3 value-cell">{result.userDisplayName}</div>
                                        <div className="col-2 value-cell">{result.userEmail}</div>
                                        <div className="col-2 value-cell">{result.quizName}</div>
                                        <div className="col-2 value-cell">
                                            {result.attendedDate == null
                                                ? null
                                                : Moment(new Date(result.attendedDate + "Z").toLocaleString()).format(
                                                      "DD/MM/YYYY hh:mm A"
                                                  )}
                                        </div>
                                        <div className="col-1 value-cell text-right">{result.correctMCQAnswers}</div>
                                        <div className="col-1 value-cell text-right">{result.totalMCQQuestions}</div>
                                        <div className="col-1 value-cell text-right">{result.mcqScore}</div>
                                    </div>
                                ))}
                            {results && results.length === 0 && (
                                <div className="row m-0 no-records">
                                    <div className="col-12 value-cell text-center">
                                        {loading ? "Loading..." : "No data found"}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default QuizReport;
