import request from "./request";

const CategoryService = {
    GetAll: async (context) => {
        return await request.sendRequest(`/category/all/Category`, context.token);
    },
    Save: async (payload, context) => {
        return await request.sendPostRequest(`/category`, payload, context);
    },
    Remove: async (id, context) => {
        return await request.sendDeleteRequest(`/category/${id}`, context.token);
    },
};

export default CategoryService;
