import { useNavigate } from "react-router-dom";
import { Fragment, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Header from "../header";
import UserService from "../../../services/user.service";
import AlertService from "../../../services/alert.service";
import "../identity.css";

const Forgot = () => {
    const navigate = useNavigate();
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const emailRef = useRef();

    const onCaptchaChange = async (response) => {
        setLoading(true);
        const data = await UserService.ValidateReCaptcha(response);
        setLoading(false);
        setIsValid(data);
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        const emailValue = emailRef.current.value;

        if (!emailValue) {
            setEmailIsValid(false);
            return false;
        }

        if (emailIsValid) {
            try {
                setLoading(true);
                const result = await UserService.ForgotPassword(emailValue);
                setLoading(false);
                if (result.isSuccess) {
                    AlertService.success("A link to reset the password is sent to your email successfully!");
                    setLoading(false);
                    navigate("login");
                } else {
                    setLoading(false);
                    AlertService.error(result.errorMessage);
                }
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    };

    return (
        <Fragment>
            <div className="identity-container">
                <Header />
                <div className="row identity-wrapper">
                    <div className="col-xl-6 col-lg-12">
                        <div className="identity-form">
                            <h1>FORGET PASSWORD 🔒</h1>
                            <form onSubmit={onSubmitHandler}>
                                <div className="row m-0">
                                    <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                        <div className="input-container-username">
                                            <input
                                                autoFocus
                                                type="email"
                                                id="email"
                                                placeholder="Enter your email"
                                                name="email"
                                                required
                                                maxLength="254"
                                                ref={emailRef}
                                                onChange={() => setLoading(false)}
                                                onKeyUp={() => setLoading(false)}
                                            />
                                        </div>
                                        {!emailIsValid && <p className="error">Please enter your email</p>}
                                    </div>
                                    <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                </div>
                                <div className="text-center captcha-holder">
                                    <ReCAPTCHA
                                        className="mb-3"
                                        sitekey={process.env.REACT_APP_SITEKEY}
                                        onChange={onCaptchaChange}
                                    />
                                </div>
                                <div className="text-center pt-4">
                                    <button type="submit" className="identity-button" disabled={loading || !isValid}>
                                        Send reset link
                                    </button>
                                </div>
                                <div className="text-center pt-4">
                                    <button
                                        type="button"
                                        className="signup-button"
                                        onClick={() => {
                                            navigate("login");
                                        }}
                                        disabled={loading}
                                    >
                                        Back to Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 d-none d-xl-block identity-image">
                        <img alt="Forgot Password" src="assets/img/login.png"></img>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Forgot;
