import request from "./request";

const PracticalExamService = {
    GetAll: async (id, context) => {
        return await request.sendRequest(`/practicalexam/all/${id}`, context.token);
    },
    Start: async (id, context) => {
        return await request.sendPutRequest(`/practicalexam/start/${id}`, context);
    },
    GetFile: async (id, context) => {
        return await request.sendRequest(`/practicalexam/file/${id}`, context.token);
    },
    Save: async (payload, context) => {
        return await request.sendPostRequest(`/practicalexam`, payload, context);
    },
    SaveDraft: async (payload, context) => {
        return await request.sendFormDataRequest(`/practicalexam/draft`, payload, context);
    },
    RemoveSession: async (assessmentId, userId, context) => {
        return await request.sendDeleteRequest(`/practicalexam/session/${assessmentId}/${userId}`, context.token);
    },
};

export default PracticalExamService;
