import { Fragment } from "react";
import { Checkbox } from "primereact/checkbox";

const MCQExamQuestionView = ({ input }) => {
    const question = input && input.question;
    const optionId1 = input && input.options[0] && input.options[0].id;
    const optionId2 = input && input.options[1] && input.options[1].id;
    const optionId3 = input && input.options[2] && input.options[2].id;
    const optionId4 = input && input.options[3] && input.options[3].id;
    const optionId5 = input && input.options[4] && input.options[4].id;
    const option1 = input && input.options[0] && input.options[0].option;
    const option2 = input && input.options[1] && input.options[1].option;
    const option3 = input && input.options[2] && input.options[2].option;
    const option4 = input && input.options[3] && input.options[3].option;
    const option5 = input && input.options[4] && input.options[4].option;
    const isAnswer1 = input && input.options[0] && input.options[0].isAnswer;
    const isAnswer2 = input && input.options[1] && input.options[1].isAnswer;
    const isAnswer3 = input && input.options[2] && input.options[2].isAnswer;
    const isAnswer4 = input && input.options[3] && input.options[3].isAnswer;
    const isAnswer5 = input && input.options[4] && input.options[4].isAnswer;
    const hasGraceMark = input && input.hasGraceMark ? true : false;
    const isConfident = input && input.isConfident;

    return (
        <Fragment>
            <div className="d-block">
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-10">
                        <label className={`form-control question ${hasGraceMark ? "has-grace-mark" : ""}`}>
                            {question}
                        </label>
                    </div>
                </div>
                {optionId1 && optionId1 != null && (
                    <div className="row m-0 pl-3 pr-3">
                        <div className="col-10">
                            <div
                                className={`flex align-items-center pl-3 ${
                                    input.answer === input.options[0].id
                                        ? hasGraceMark === false && isAnswer1 && input.answer === input.options[0].id
                                            ? "correct-answer"
                                            : "wrong-answer"
                                        : ""
                                } ${isAnswer1 && input.answer !== input.options[0].id ? "right-answer" : ""}`}
                            >
                                <Checkbox
                                    checked={hasGraceMark === false && input.answer === input.options[0].id}
                                    disabled
                                />
                                <label className="m-auto ml-2 p-2">{option1}</label>
                            </div>
                        </div>
                    </div>
                )}
                {optionId2 && optionId2 != null && (
                    <div className="row m-0 pl-3 pr-3">
                        <div className="col-10">
                            <div
                                className={`flex align-items-center pl-3 ${
                                    input.answer === input.options[1].id
                                        ? hasGraceMark === false && isAnswer2 && input.answer === input.options[1].id
                                            ? "correct-answer"
                                            : "wrong-answer"
                                        : ""
                                } ${isAnswer2 && input.answer !== input.options[1].id ? "right-answer" : ""}`}
                            >
                                <Checkbox
                                    checked={hasGraceMark === false && input.answer === input.options[1].id}
                                    disabled
                                />
                                <label className="m-auto ml-2 p-2">{option2}</label>
                            </div>
                        </div>
                    </div>
                )}
                {optionId3 && optionId3 != null && (
                    <div className="row m-0 pl-3 pr-3">
                        <div className="col-10">
                            <div
                                className={`flex align-items-center pl-3 ${
                                    input.answer === input.options[2].id
                                        ? hasGraceMark === false && isAnswer3 && input.answer === input.options[2].id
                                            ? "correct-answer"
                                            : "wrong-answer"
                                        : ""
                                } ${isAnswer3 && input.answer !== input.options[2].id ? "right-answer" : ""}`}
                            >
                                <Checkbox
                                    checked={hasGraceMark === false && input.answer === input.options[2].id}
                                    disabled
                                />
                                <label className="m-auto ml-2 p-2">{option3}</label>
                            </div>
                        </div>
                    </div>
                )}
                {optionId4 && optionId4 != null && (
                    <div className="row m-0 pl-3 pr-3">
                        <div className="col-10">
                            <div
                                className={`flex align-items-center pl-3 ${
                                    input.answer === input.options[3].id
                                        ? hasGraceMark === false && isAnswer4 && input.answer === input.options[3].id
                                            ? "correct-answer"
                                            : "wrong-answer"
                                        : ""
                                } ${isAnswer4 && input.answer !== input.options[3].id ? "right-answer" : ""}`}
                            >
                                <Checkbox
                                    checked={hasGraceMark === false && input.answer === input.options[3].id}
                                    disabled
                                />
                                <label className="m-auto ml-2 p-2">{option4}</label>
                            </div>
                        </div>
                    </div>
                )}
                {optionId5 && optionId5 != null && (
                    <div className="row m-0 pl-3 pr-3">
                        <div className="col-10">
                            <div
                                className={`flex align-items-center pl-3 ${
                                    input.answer === input.options[4].id
                                        ? hasGraceMark === false && isAnswer5 && input.answer === input.options[4].id
                                            ? "correct-answer"
                                            : "wrong-answer"
                                        : ""
                                } ${isAnswer5 && input.answer !== input.options[4].id ? "right-answer" : ""}`}
                            >
                                <Checkbox
                                    checked={hasGraceMark === false && input.answer === input.options[4].id}
                                    disabled
                                />
                                <label className="m-auto ml-2 p-2">{option5}</label>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row m-0 pl-3 pr-3">
                    <div className="col-8"></div>
                    <div className="col-2">
                        <Checkbox inputId="confiCheck" checked={isConfident} disabled />
                        <span htmlFor="confiCheck" className="pl-2" id="label_number">
                            Are you Confident?
                        </span>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default MCQExamQuestionView;
