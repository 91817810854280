import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "font-awesome/css/font-awesome.min.css";
import "./index.css";
import App from "./App";
import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { HashRouter, Route, Routes } from "react-router-dom";
import { AuthContextProvider } from "./auth/auth-context";
import { Provider } from "react-redux";
import store from "./components/store/store";
import Login from "./components/identity/login/login";
import Forgot from "./components/identity/forgot/forgot";
import Alert from "./components/alert/alert";
import ResetPassword from "./components/identity/reset/resetpassword";
import ApproveUser from "./components/identity/users/approve";
import RejectUser from "./components/identity/users/reject";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <Fragment>
        <Alert />
        <AuthContextProvider>
            <Provider store={store}>
                <HashRouter>
                    <Routes>
                        <Route path="/*" exact element={<App />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/forgot" element={<Forgot />} />
                        <Route path="/resetpassword/:code" element={<ResetPassword />} />
                        <Route path="/approve/:code" exact element={<ApproveUser />} />
                        <Route path="/reject/:code" exact element={<RejectUser />} />
                    </Routes>
                </HashRouter>
            </Provider>
        </AuthContextProvider>
    </Fragment>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
