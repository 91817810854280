import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../auth/auth-context";
import CourseService from "../../../services/course.service";
import AlertService from "../../../services/alert.service";
import Card from "./card";
import DocViewer from "./docViewer";

const CourseDocs = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [selectedCardId, setSelectedCardId] = useState(0);
    const [selectedCardTitle, setSelectedCardTitle] = useState();
    const [documents, setDocuments] = useState([]);
    const [groupedDocuments, setGroupedDocuments] = useState([]);
    const [document, setDocument] = useState({});
    const [searchText, setSearchText] = useState("");
    const scrollRef = useRef();
    const authContext = useContext(AuthContext);

    const courseAccess = authContext.getPermissions("courses");
    if (courseAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }

    const groupIncidents = (documents) => {
        let preGroupedData = Object.groupBy(documents, ({ documentType }) => documentType);
        let groupedData = [];
        for (let key in preGroupedData) {
            groupedData.push({ key, items: preGroupedData[key] });
        }
        return groupedData;
    };

    const loadDocuments = useCallback(async () => {
        setLoading(true);
        const data = await CourseService.GetAttachments(params.id, searchText, authContext);
        if (data.isSuccess) {
            setLoading(false);
            let i = 0;
            data.data.forEach((element) => {
                i++;
                element.no = i;
            });
            setDocuments(data.data);
            setGroupedDocuments(groupIncidents(data.data));
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [params.id, authContext, searchText]);

    useEffect(() => {
        loadDocuments();
    }, [loadDocuments, authContext]);

    const onCardClick = async (doc) => {
        let preId = selectedCardId;
        setSelectedCardId(doc.documentId);
        setSelectedCardTitle(doc.documentName);
        setDocument(doc);
        if (preId === undefined || preId === null || preId === 0) {
            setTimeout(() => {
                if (scrollRef.current) {
                    scrollRef.current.scrollIntoView();
                }
            }, 10);
        }
    };

    const clearSelection = async () => {
        setSelectedCardId(0);
        setDocument(null);
    };

    return (
        <Fragment>
            {selectedCardId === 0 && (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group has-search search-group catalogue-search">
                                <span className="fa fa-search form-control-feedback"></span>
                                <input
                                    type="text"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    className="form-control"
                                    placeholder="Search"
                                />
                                {searchText && (
                                    <span className="input-group-append">
                                        <button
                                            className="btn border-0"
                                            type="button"
                                            onClick={() => setSearchText("")}
                                        >
                                            <i className="fa fa-times"></i>
                                        </button>
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="col-3"></div>
                        <div className="col-6 course-title">
                            <button type="button" className="btn-incident-back" onClick={() => navigate("/courses")}>
                                <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                            </button>
                        </div>
                    </div>
                    {groupedDocuments &&
                        groupedDocuments.map((g, i) => (
                            <div key={i}>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <span className="btn-incidents-all btn-incidents-width">{g.key}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    {g.items &&
                                        g.items.map((document, i) => (
                                            <div key={i} className="col-lg-2 col-md-4 col-sm-6 col-12">
                                                <Card
                                                    no={document.no}
                                                    id={document.documentId}
                                                    title={document.documentName}
                                                    active={document.documentId === selectedCardId}
                                                    clickHandler={() => onCardClick(document)}
                                                ></Card>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ))}
                    {documents && documents.length === 0 && <div className="col-12">No documents available...</div>}
                </div>
            )}
            {selectedCardId > 0 && !loading && (
                <>
                    <div className="row d-none d-md-flex">
                        <div className="col-md-2 col-sm-4">
                            <div className="form-group has-search search-group catalogue-search">
                                <span className="fa fa-search form-control-feedback"></span>
                                <input
                                    type="text"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    className="form-control"
                                    placeholder="Search"
                                />
                                {searchText && (
                                    <span className="input-group-append">
                                        <button
                                            className="btn border-0"
                                            type="button"
                                            onClick={() => setSearchText("")}
                                        >
                                            <i className="fa fa-times"></i>
                                        </button>
                                    </span>
                                )}
                            </div>
                            <div className="incident-list incident-v-scroll">
                                <div className="row m-0">
                                    {groupedDocuments &&
                                        groupedDocuments.map((g, i) => (
                                            <div key={i}>
                                                <div className="col-12 text-center">
                                                    <span className="btn-incidents-all">{g.key}</span>
                                                </div>
                                                {g.items &&
                                                    g.items.map((document, i) => (
                                                        <div
                                                            key={i}
                                                            className="col-12"
                                                            ref={
                                                                document.documentId === selectedCardId
                                                                    ? scrollRef
                                                                    : null
                                                            }
                                                        >
                                                            <Card
                                                                no={document.no}
                                                                id={document.documentId}
                                                                title={document.documentName}
                                                                active={document.documentId === selectedCardId}
                                                                clickHandler={() => onCardClick(document)}
                                                            ></Card>
                                                        </div>
                                                    ))}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10 col-sm-8 view course-doc-view">
                            <div className="doc-container p-0">
                                {document &&
                                    document.attachments.map((m) => (
                                        <DocViewer
                                            doc={m}
                                            clearSelection={clearSelection}
                                            title={selectedCardTitle}
                                        ></DocViewer>
                                    ))}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Fragment>
    );
};

export default CourseDocs;
