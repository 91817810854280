import { configureStore } from "@reduxjs/toolkit";
import apiSlice from "./apiSlice";

const saveToLocalStorageMiddleware = ({ getState }) => {
    return (next) => (action) => {
        const result = next(action);
        localStorage.setItem("appState", JSON.stringify(getState()));
        return result;
    };
};

const loadFromLocalStorage = () => {
    if (localStorage.getItem("appState") !== null) {
        return JSON.parse(localStorage.getItem("appState"));
    }
};

const store = configureStore({
    reducer: {
        api: apiSlice.reducer,
    },
    preloadedState: loadFromLocalStorage(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(saveToLocalStorageMiddleware),
});

export default store;
