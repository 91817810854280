import request from "./request";

const QuizService = {
    GetAll: async (context) => {
        return await request.sendRequest(`/quiz/all`, context.token);
    },
    GetList: async (context) => {
        return await request.sendRequest(`/quiz/list`, context.token);
    },
    Get: async (id, context) => {
        return await request.sendRequest(`/quiz/${id}`, context.token);
    },
    Save: async (payload, context) => {
        return await request.sendPostRequest(`/quiz`, payload, context);
    },
    Remove: async (id, context) => {
        return await request.sendDeleteRequest(`/quiz/${id}`, context.token);
    },
};

export default QuizService;
