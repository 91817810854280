import request from "./request";

const ReviewPracticalExamService = {
    Get: async (id, context) => {
        return await request.sendRequest(`/reviewpracticalexam/${id}`, context.token);
    },
    GetByExam: async (id, context) => {
        return await request.sendRequest(`/reviewpracticalexam/exam/${id}`, context.token);
    },
};

export default ReviewPracticalExamService;
