import LoginService from "./login.service";

const SharedService = {
    Check: async (context) => {
        let result = await LoginService.Check(context);
        context.setOnGoingValidations(result.data);
    },
};

export default SharedService;
