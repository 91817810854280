import "../../app.css";
import "../quiz/quiz.css";
import AlertService from "../../../../services/alert.service";
import PracticalService from "../../../../services/practical.service";
import AuthContext from "../../../../auth/auth-context";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PracticalQuestion from "./practicalquestion";

const AssessmentPractical = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [assessmentId, setAssessmentId] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [deletedIds, setDeletedIds] = useState([]);
    const authContext = useContext(AuthContext);

    const assessmentAccess = authContext.getPermissions("assessmentsmaster");
    if (assessmentAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }

    const addRow = () => {
        let maxId = questions.length + 1;
        let newQ = {
            assessmentId: params.id,
            question: "",
            orderBy: maxId,
            storyBoardTitle: "",
            storyBoardInfo: "",
            taskTitle: "",
            taskInfo: "",
        };
        setQuestions((prev) => {
            return [...prev, newQ];
        });
    };

    const loadQuestions = useCallback(async () => {
        setLoading(true);
        const data = await PracticalService.GetAll(params.id, authContext);
        if (data.isSuccess) {
            setLoading(false);
            setQuestions(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext, params.id]);

    useEffect(() => {
        if (questions.length === 0) {
            addRow();
        }
    }, [questions, addRow]);

    useEffect(() => {
        if (params.id) {
            setAssessmentId(params.id);
            loadQuestions();
        }
    }, [loadQuestions, authContext, params.id]);

    const onCancelHandler = async (event) => {
        navigate(`/master/assessments/view/${params.id}`);
    };

    const changeHandler = (idx, input) => {
        setQuestions((prev) => {
            if (prev && prev.length > 0) {
                prev[idx] = input;
            }
            return [...prev];
        });
    };

    const removeHandler = async (idx) => {
        let qId = questions[idx].id;
        setDeletedIds((prev) => {
            if (qId > 0) {
                return [...prev, qId];
            } else {
                return prev;
            }
        });
        setQuestions((prev) => {
            if (prev && prev.length > 0) {
                prev.splice(idx, 1);
            }
            return [...prev];
        });
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        if (questions.length > 0) {
            setLoading(true);
            try {
                let payload = {
                    ToBeDeleted: deletedIds,
                    models: questions,
                };
                let data = await PracticalService.BulkSave(payload, authContext);
                if (data.isSuccess) {
                    AlertService.success("Questions saved successfully!");
                    navigate(`/master/assessments/view/${params.id}`);
                } else {
                    AlertService.error(data.errorMessage);
                }
                setLoading(false);
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    };

    return (
        <Fragment>
            <div className="question-container">
                <div className="row m-0">
                    <div className="col-4 course-title">
                        <span>Assessment</span>
                    </div>
                    <div className="col-4 course-title">
                        <center>
                            <span>Practical</span>
                        </center>
                    </div>
                    <div className="col-4 course-title">
                        <button type="button" className="btn-incident-back" onClick={onCancelHandler}>
                            <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                        </button>
                    </div>
                </div>
                {!loading &&
                    questions &&
                    questions.length > 0 &&
                    questions.map((m, i) => (
                        <PracticalQuestion
                            key={i}
                            index={i}
                            input={m}
                            changeHandler={changeHandler}
                            removeHandler={removeHandler}
                        ></PracticalQuestion>
                    ))}
                <div className="row pt-1">
                    <div className="col-10">
                        <button className="float-right cursor-pointer link-button" onClick={addRow}>
                            <i className="pi pi-plus"></i> Add Question
                        </button>
                    </div>
                    <div className="col-2"></div>
                </div>
                {loading && (
                    <div className="row m-0 pl-3 pr-3">
                        <div className="col-12">Loading...</div>
                    </div>
                )}
                {!loading && questions && questions.length > 0 && (
                    <div className="row pt-1">
                        <div className="col-12">
                            <button className="float-right mr-3 submit-button" onClick={onSubmitHandler}>
                                Submit
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default AssessmentPractical;
