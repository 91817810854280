import AuthContext from "../../../auth/auth-context";
import AssessmentService from "../../../services/assessment.service";
import AlertService from "../../../services/alert.service";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";

const Assessments = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [assessments, setAssessments] = useState([]);
    const [assessment, setAssessment] = useState();
    const [assessmentType, setAssessmentType] = useState();
    const authContext = useContext(AuthContext);

    const assessmentAccess = authContext.getPermissions("assessments");
    if (assessmentAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }
    const loadAssessments = useCallback(async () => {
        setLoading(true);
        const data = await AssessmentService.GetAll(authContext);
        if (data.isSuccess) {
            setLoading(false);
            setAssessments(data.data);
            if (params.id) {
                let fData = data.data.filter((f) => f.id === Number(params.id));
                if (fData.length > 0) {
                    await onViewHandler(fData[0]);
                }
            }
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext, params.id]);

    useEffect(() => {
        loadAssessments();
    }, [loadAssessments, authContext]);

    const onViewHandler = async (data) => {
        setAssessment(data);
    };

    const onStartMCQExam = async () => {
        if (assessment.isMCQCompleted === false) {
            setAssessmentType("M");
        }
        if (assessment.isMCQCompleted === true && assessment.reviewAvailable === true) {
            navigate(`/assessments/review/M/${assessment.id}`);
        }
    };

    const onStartPracticalExam = async () => {
        if (assessment.isPracticalCompleted === false) {
            setAssessmentType("P");
            if (assessment.remarks !== undefined && assessment.remarks !== null && assessment.remarks !== "") {
                setVisible(true);
            }
        }
        if (assessment.isPracticalCompleted === true && assessment.reviewAvailable === true) {
            navigate(`/assessments/review/P/${assessment.id}`);
        }
    };

    const onStartExam = async () => {
        authContext.setOnGoingValidations(true);
        navigate(`/assessments/start/${assessmentType}/${assessment.id}`);
    };

    return (
        <Fragment>
            <div className="row m-0 pl-3 pr-3">
                {!loading &&
                    (assessment === undefined || assessment === null) &&
                    assessments.length > 0 &&
                    assessments.map((m, i) => (
                        <div key={m.id} className="col-3 text-center">
                            <div className="commando-container cursor-pointer" onClick={() => onViewHandler(m)}>
                                <div className="title">{m.assessmentName}</div>
                                <div className="body">
                                    <span>Please click here to take the assessment</span>
                                </div>
                            </div>
                        </div>
                    ))}
                {!loading && assessments.length === 0 && (
                    <div className="col-12 text-center">No Assessments Available!!</div>
                )}
                {loading && <div className="col-12">Loading...</div>}
                {!loading && assessment && (assessmentType === undefined || assessmentType === null) && (
                    <>
                        <div className="col-12">
                            <div className="row m-0">
                                <div className="col-4 course-title"></div>
                                <div className="col-8 course-title">
                                    <button
                                        type="button"
                                        className="btn-incident-back"
                                        onClick={() => {
                                            setAssessment(undefined);
                                            setAssessmentType(undefined);
                                        }}
                                    >
                                        <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                                    </button>
                                    <span className="quiz-title">{assessment && assessment.assessmentName}</span>
                                </div>
                            </div>
                        </div>
                        {assessment.mcq && (
                            <div className="col-6 text-center">
                                <div>
                                    <div
                                        className="quiz-container cursor-pointer m-auto"
                                        onClick={() => onStartMCQExam()}
                                    >
                                        <div className="title">MCQ</div>
                                        <div className="body">
                                            {assessment.isMCQCompleted === false && (
                                                <span>Please Click Here to Take Assessment</span>
                                            )}
                                            {assessment.isMCQCompleted && assessment.reviewAvailable && (
                                                <span>Please Click Here to Review Assessment</span>
                                            )}
                                            {assessment.isMCQCompleted && assessment.reviewAvailable === false && (
                                                <span>Please contact support for any assisstance</span>
                                            )}
                                        </div>
                                        {assessment.isMCQCompleted && (
                                            <>
                                                <div className="completed">
                                                    <center>
                                                        <span>Completed</span>
                                                    </center>
                                                </div>
                                                <div className="score-container pt-3">
                                                    <center>
                                                        <span>
                                                            Original Score :{" "}
                                                            <label className="score">
                                                                {assessment.correctMCQAnswers + assessment.graceMarks}/
                                                                {assessment.totalMCQQuestions}, {assessment.mcqScore}%
                                                            </label>
                                                        </span>
                                                    </center>
                                                </div>
                                                <div className="score-container pt-3">
                                                    <center>
                                                        <span>
                                                            Confident Score :{" "}
                                                            <label className="score">
                                                                {assessment.mcqConfidentScore}/
                                                                {assessment.totalMCQQuestions}
                                                            </label>
                                                        </span>
                                                    </center>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {assessment.practical && (
                            <div className="col-6 text-center">
                                <div>
                                    <div
                                        className="quiz-container cursor-pointer m-auto"
                                        onClick={() => onStartPracticalExam()}
                                        title={`${
                                            assessment && assessment != null && assessment.isPracticalCompleted
                                                ? assessment.remarks
                                                : ""
                                        }`}
                                    >
                                        <div className="title">Practical</div>
                                        <div className="body">
                                            <span>Please Click Here to Take Assessment</span>
                                        </div>
                                        {assessment.isPracticalCompleted && (
                                            <>
                                                <div className="completed">
                                                    <center>
                                                        <span>Completed</span>
                                                    </center>
                                                </div>
                                                {assessment.isPracticalMarksCalculated && (
                                                    <div className="score-container pt-3">
                                                        <center>
                                                            <span>
                                                                Score :{" "}
                                                                <label className="score">
                                                                    {assessment.practicalScore}/
                                                                    {assessment.totalPracticalScore}
                                                                </label>
                                                            </span>
                                                        </center>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
                {!loading && assessment && assessmentType && (
                    <>
                        <div className="col-12">
                            <div className="row m-0">
                                <div className="col-4 course-title">
                                    <span className="quiz-title float-left">Assessment Instructions</span>
                                </div>
                            </div>
                            <div className="instructions">
                                <span>Welcome to the assessment. Please read the following instructions carefully</span>
                                <ol type="1">
                                    <li>
                                        <b>Timing:</b>
                                        <ol type="I">
                                            <li>The timer will start as soon as you enter the assessment.</li>
                                            <li>
                                                If you leave the assessment, the timer will pause, and it will resume
                                                when you re-enter.
                                            </li>
                                            <li>
                                                Once the total time is used up, the assessment will end, and you will no
                                                longer be able to take it again.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <b>Answering Questions:</b>
                                        <ol type="I">
                                            <li>
                                                For each question, select the most appropriate answer from the
                                                multiple-choice options provided.
                                            </li>
                                            <li>
                                                You can indicate your confidence level by clicking the Confident Box for
                                                each question.
                                            </li>
                                            <li>
                                                If you skip or miss a question, it will count as 0 points for both your
                                                original and confidence scores.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <b>Auto-Submission:</b>
                                        <ol type="I">
                                            <li>
                                                When the time limit is reached, the assessment will automatically stop
                                                accepting inputs. Only the "Submit" button will remain enabled, allowing
                                                you to submit your answers.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <b>Scoring:</b>
                                        <ol type="I">
                                            <li>
                                                <b>Original Score:</b> Based on whether your answers are correct or
                                                incorrect.
                                            </li>
                                            <li>
                                                <b>Confident Score:</b> This will be calculated as follows:
                                                <ol type="a">
                                                    <li>
                                                        <b>Correct Answer + Confident:</b> +1 point
                                                    </li>
                                                    <li>
                                                        <b>Correct Answer + Not Confident:</b> +0.5 points
                                                    </li>
                                                    <li>
                                                        <b>Incorrect Answer + Confident:</b> -1 point
                                                    </li>
                                                    <li>
                                                        <b>Incorrect Answer + Not Confident:</b> -0.5 points
                                                    </li>
                                                    <li>
                                                        <b>Missed Question:</b> 0 points (for both the original and
                                                        confidence scores)
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <b>Do Not Leave the Page:</b>
                                        <ol type="I">
                                            <li>
                                                Once you begin the assessment, do not leave or refresh the page until
                                                you submit your answers. Leaving without submitting may result in the
                                                loss of your progress.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <b>Integrity:</b>
                                        <ol type="I">
                                            <li>
                                                Please ensure that all answers are your own. Any form of dishonesty will
                                                result in disqualification.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <b>Support:</b>
                                        <ol type="I">
                                            <li>
                                                If you experience any technical issues, you can raise a ticket through
                                                our <b>Support Handle Engine</b> or contact us via email at{" "}
                                                <a href="mailto:support@tevelcyber.com">support@tevelcyber.com</a>.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <div>
                                    <b>
                                        By starting this assessment, you agree to follow these instructions and complete
                                        the task honestly.
                                    </b>
                                </div>
                                <div>
                                    <b>Good luck!</b>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-9 course-title"></div>
                                <div className="col-3 course-title cursor-pointer" onClick={onStartExam}>
                                    <span className="quiz-title">
                                        Click here to take assessment : {assessment && assessment.assessmentName}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <Dialog
                header="Remarks"
                visible={visible}
                style={{ width: "50vw" }}
                onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                }}
            >
                <div className="row m-0 p-0">
                    <div className="col-12">
                        <span className="cancel-reason">{assessment && assessment != null && assessment.remarks}</span>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    );
};

export default Assessments;
