import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import Moment from "moment";
import Attachment from "./attachment";
import SupportService from "../../../services/support.service";
import AuthContext from "../../../auth/auth-context";
import Comment from "./comment";
import ViewComment from "./viewcomment";
import { Dialog } from "primereact/dialog";
import AgentService from "../../../services/agent.service";

const AgentView = ({ ticket, clearSelection, loadTickets, statusHandler }) => {
    const [comments, setComments] = useState();
    const [viewReassign, setViewReassign] = useState(false);
    const [agents, setAgents] = useState();
    const [assignedTo, setAssignedTo] = useState(ticket.assignedTo);

    const authContext = useContext(AuthContext);

    const loadComments = useCallback(async () => {
        if (ticket) {
            let data = await SupportService.GetComments(ticket.id, authContext);
            if (data.isSuccess) {
                setComments(data.data);
            }
        }
    }, [ticket, authContext]);

    const loadAgents = useCallback(async () => {
        let data = await AgentService.GetAgentList(1, authContext);
        if (data.isSuccess) {
            setAgents(data.data);
        }
    }, [authContext]);

    useEffect(() => {
        loadComments();
    }, [loadComments, ticket, authContext]);

    useEffect(() => {
        loadAgents();
    }, [loadAgents, authContext]);

    const onCommentSaveHandler = async () => {
        await loadComments();
    };

    const onCommentCancelHandler = async () => {};

    const markAsInProgress = async (e) => {
        e.preventDefault();
        await SupportService.SetStatus(ticket.id, 2, authContext);
        ticket.status = 2;
        ticket.statusDesc = "In-Progress";
        statusHandler(ticket.id, ticket.status, ticket.statusDesc);
    };

    const markAsClosed = async (e) => {
        e.preventDefault();
        await SupportService.SetStatus(ticket.id, 3, authContext);
        ticket.status = 3;
        ticket.statusDesc = "Closed";
        statusHandler(ticket.id, ticket.status, ticket.statusDesc);
    };

    const ReAssignTicket = async (e) => {
        e.preventDefault();
        await SupportService.Reassign(ticket.id, assignedTo, authContext);
        clearSelection();
        loadTickets();
    };

    return (
        <Fragment>
            {document && (
                <>
                    <div className="row m-0">
                        <div className="col-12 course-title">
                            <span>Ticket#: </span> <span className="course-title-value">{ticket.ticketId}</span>
                            <button type="button" className="btn-incident-back" onClick={clearSelection}>
                                <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                            </button>
                            <button type="button" className="submit-button ml-2" onClick={() => setViewReassign(true)}>
                                Reassign
                            </button>
                            {ticket.status === 1 && (
                                <button type="button" className="submit-button" onClick={markAsInProgress}>
                                    Mark as In-Progress
                                </button>
                            )}
                            {ticket.status === 2 && (
                                <button type="button" className="submit-button" onClick={markAsClosed}>
                                    Close Ticket
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="ticket-container">
                        <div className="row m-0">
                            <div className="col-12 border-bottom">
                                <span>Requested By: </span> {ticket.requester}
                            </div>
                            <div className="col-12 border-bottom">
                                <span>Status: </span> {ticket.statusDesc}
                                {ticket.status === 3 && ticket.closedDate !== null && (
                                    <>
                                        ,<span> Date: </span>
                                        {Moment(new Date(ticket.closedDate + "Z").toLocaleString()).format(
                                            "DD/MM/YYYY hh:mm A"
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="col-12 border-bottom">
                                <span>Category: </span> {ticket.categoryDesc}
                            </div>
                            <div className="col-12 border-bottom">
                                <span>Course Name: </span> {ticket.courseName}
                            </div>
                            <div className="col-12 border-bottom">
                                <span>Subject: </span> {ticket.subject}
                            </div>
                            <div className="col-12 border-bottom">
                                <span>Description</span> <br />
                                {ticket.description}
                            </div>
                            <div className="col-12 border-bottom">
                                <span>Attachments</span>
                                <br />
                                {ticket.attachments &&
                                    ticket.attachments.length > 0 &&
                                    ticket.attachments.map((m, i) => (
                                        <Attachment key={i} no={i} attachment={m}></Attachment>
                                    ))}
                            </div>
                            {comments && comments.length > 0 && (
                                <div className="col-12">
                                    <span>Comments</span>
                                    <br />
                                    {comments &&
                                        comments.length > 0 &&
                                        comments.map((m, i) => (
                                            <div key={i} className="p-1">
                                                <ViewComment comment={m}></ViewComment>
                                            </div>
                                        ))}
                                </div>
                            )}
                            {ticket.status !== 3 && (
                                <div className="col-12">
                                    <Comment
                                        ticketId={ticket.id}
                                        submitHandler={onCommentSaveHandler}
                                        cancelHandler={onCommentCancelHandler}
                                    ></Comment>
                                </div>
                            )}
                        </div>
                    </div>
                    <Dialog
                        header="Re-Assign Ticket"
                        visible={viewReassign}
                        style={{ width: "50vw" }}
                        onHide={() => {
                            if (!viewReassign) return;
                            setViewReassign(false);
                        }}
                    >
                        <p className="m-0">
                            <select
                                className="form-control"
                                value={assignedTo}
                                onChange={(e) => setAssignedTo(Number(e.target.value))}
                            >
                                {agents &&
                                    agents.map((agent) => (
                                        <option key={agent.value} value={agent.value}>
                                            {agent.label}
                                        </option>
                                    ))}
                            </select>
                            <div className="mt-3 text-right">
                                <button type="button" className="submit-button" onClick={ReAssignTicket}>
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    className="cancel-button ml-3"
                                    onClick={() => setViewReassign(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </p>
                    </Dialog>
                </>
            )}
        </Fragment>
    );
};

export default AgentView;
