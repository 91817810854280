import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import "../../app.css";
import "../quiz/quiz.css";
import AlertService from "../../../../services/alert.service";
import ReviewPracticalExamService from "../../../../services/reviewpracticalexam.service";
import AuthContext from "../../../../auth/auth-context";
import PracticalExamQuestionView from "./practicalexamquestionview";
import PracticalService from "../../../../services/practical.service";
import { Dialog } from "primereact/dialog";
import MCQExamService from "../../../../services/mcqexam.service";

const PracticalExamView = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [remarks, setRemarks] = useState();
    const [cancelVisible, setCancelVisible] = useState(false);
    const [reason, setReason] = useState();
    const [assessmentExam, setAssessmentExam] = useState();
    const [questions, setQuestions] = useState([]);
    const [isNavigated, setIsNavigated] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState();
    const authContext = useContext(AuthContext);
    const [selectedCardId, setSelectedCardId] = useState(0);
    const scrollRef = useRef();

    const assessmentAccess = authContext.getPermissions("assessments");
    if (assessmentAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }

    const loadQuestions = useCallback(async () => {
        setLoading(true);
        const data = await ReviewPracticalExamService.GetByExam(params.id, authContext);
        if (data.isSuccess) {
            setLoading(false);
            setAssessmentExam(data.data);
            setQuestions(data.data.questions);
            if (data.data.questions.length > 0) {
                setSelectedCardId(data.data.questions[0].id);
            }
            setSelectedIndex(0);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext, params.id]);

    useEffect(() => {
        if (params.id) {
            loadQuestions();
        }
    }, [loadQuestions, authContext, params.id]);

    const onCardClick = (data, i) => {
        let preId = selectedCardId;
        setSelectedCardId(data.id);
        setSelectedIndex(i);
        if (preId === undefined || preId === null || preId === 0) {
            setTimeout(() => {
                if (scrollRef.current) {
                    scrollRef.current.scrollIntoView();
                }
            }, 10);
        }
    };

    const moveToPrevious = (e) => {
        setIsNavigated(true);
        setSelectedIndex((prev) => {
            if (prev > 0) {
                return prev - 1;
            } else {
                return 0;
            }
        });
    };

    const moveToNext = (e) => {
        setIsNavigated(true);
        setSelectedIndex((prev) => {
            if (prev === questions.length - 1) {
                return prev;
            } else {
                return prev + 1;
            }
        });
    };

    const changeHandler = (idx, input, comment) => {
        setQuestions((prev) => {
            const updatedQuestions = prev.map((q, i) => (i === idx ? { ...q, score: input, comments: comment } : q));

            const newTotalScore = updatedQuestions.reduce((sum, q) => sum + (Number(q.score) || 0), 0);

            setAssessmentExam((prevExam) => ({
                ...prevExam,
                practicalScore: newTotalScore,
            }));

            return updatedQuestions;
        });
        assessmentExam.practicalScore = Number(assessmentExam.practicalScore) + Number(input);
    };

    useEffect(() => {
        if (questions && questions.length > 0 && isNavigated) {
            setSelectedCardId(questions[selectedIndex].id);
            setTimeout(() => {
                if (scrollRef.current) {
                    scrollRef.current.scrollIntoView();
                }
            }, 10);
            setIsNavigated(false);
        }
    }, [isNavigated, selectedIndex, questions]);

    const onSubmitHandler = async () => {
        setLoading(true);
        try {
            assessmentExam.practicalScore = questions.map((item) => item.score).reduce((prev, curr) => prev + curr, 0);
            let payload = {
                id: assessmentExam.id,
                userId: assessmentExam.userId,
                assessmentId: assessmentExam.assessmentId,
                practicalScore: assessmentExam.practicalScore,
                remarks: remarks,
                questions: questions.map((m) => ({ id: m.id, score: m.score, comments: m.comments })),
            };
            let data = await PracticalService.SaveScore(payload, authContext);
            if (data.isSuccess) {
                setVisible(false);
                AlertService.success("Assessment score details saved successfully!");
                navigate(`/master/assessments/attendees/practical/${assessmentExam.assessmentId}`);
            } else {
                AlertService.error(data.errorMessage);
            }
            setLoading(false);
        } catch (ex) {
            console.log(ex);
            setLoading(false);
        }
    };

    const onConfirmSubmitHandler = (event) => {
        setAssessmentExam((prev) => {
            prev.questions = questions;
            return prev;
        });
        confirmPopup({
            target: event.currentTarget,
            message: "Are your sure you willing to submit scores for the Assessment",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept: onSubmitHandler,
        });
    };

    const onCancelHandler = () => {
        navigate(`/master/assessments/attendees/practical/${assessmentExam.assessmentId}`);
    };

    const RecalculateScore = async () => {
        let result = await MCQExamService.RecalculatePracticalScore(params.id, authContext);
        if (result.isSuccess) {
            AlertService.success("Score has been recalculated successfully.");
            loadQuestions();
        } else {
            AlertService.error(result.errorMessage);
        }
    };

    const onCancelExam = async () => {
        if (!reason) {
            AlertService.error("Please provide reason for cancellation.");
        } else {
            let payload = {
                examId: assessmentExam.id,
                cancelReason: reason,
                cancelledBy: authContext.userId,
            };
            let result = await MCQExamService.CancelPractical(payload, authContext);
            if (result.isSuccess) {
                AlertService.success("Exam has been cancelled successfully.");
                onCancelHandler();
            } else {
                AlertService.error(result.errorMessage);
            }
        }
    };

    return (
        <Fragment>
            <div className="container-fluid question-container">
                <div className="row m-0">
                    <div className="col-9 course-title">
                        <span className="quiz-left-title">
                            {assessmentExam && assessmentExam.assessmentName}, Practical :{" "}
                            {assessmentExam && assessmentExam.attendeeName},{" "}
                            <small>Score : {assessmentExam && assessmentExam.practicalScore}</small>
                        </span>
                    </div>
                    <div className="col-3 course-title">
                        <button type="button" className="btn-incident-back" onClick={onCancelHandler}>
                            <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                        </button>
                        <button
                            type="button"
                            className="btn-incidents-all float-right"
                            onClick={() => setCancelVisible(true)}
                        >
                            <span>Cancel Exam</span>
                        </button>
                        <button type="button" className="btn-incidents-all float-right mr-2" onClick={RecalculateScore}>
                            <span>Re-Calculate Score</span>
                        </button>
                    </div>
                </div>
                <div className="row d-none d-md-flex">
                    <div className="col-md-2 col-sm-4">
                        <div className="incident-list incident-v-scroll border">
                            <div className="row m-0">
                                {questions &&
                                    questions.length > 0 &&
                                    questions.map((q, i) => (
                                        <div
                                            key={i}
                                            className="col-12"
                                            ref={q.id === selectedCardId ? scrollRef : null}
                                        >
                                            <div
                                                className={
                                                    q.id === selectedCardId
                                                        ? "quiz-card p-1 text-center active"
                                                        : "quiz-card p-1 text-center"
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    onCardClick(q, i);
                                                }}
                                            >
                                                <div className="name" title={q.question}>
                                                    Question {i + 1}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 col-sm-8 view">
                        {!loading &&
                            questions &&
                            questions.length > 0 &&
                            questions.map((m, i) => (
                                <PracticalExamQuestionView
                                    key={i}
                                    index={i}
                                    input={m}
                                    selectedIndex={selectedIndex}
                                    changeHandler={changeHandler}
                                ></PracticalExamQuestionView>
                            ))}
                        {loading && (
                            <div className="row m-0 pl-3 pr-3">
                                <div className="col-12">Loading...</div>
                            </div>
                        )}
                        {!loading && questions && questions.length > 0 && (
                            <div className="row pt-1">
                                <div className="col-6">
                                    {selectedIndex > 0 && (
                                        <button type="button" className="submit-button" onClick={moveToPrevious}>
                                            Move to Previous Question
                                        </button>
                                    )}
                                </div>
                                <div className="col-6">
                                    {selectedIndex < questions.length - 1 && (
                                        <button
                                            type="button"
                                            className="float-right mr-3 submit-button"
                                            onClick={moveToNext}
                                        >
                                            Move to Next Question
                                        </button>
                                    )}
                                    <ConfirmPopup />
                                    {selectedIndex === questions.length - 1 && (
                                        <button
                                            type="button"
                                            className="float-right mr-3 submit-button"
                                            onClick={() => setVisible(true)}
                                        >
                                            Submit
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Dialog
                header="Remarks"
                visible={visible}
                style={{ width: "50vw" }}
                onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                }}
            >
                <div className="row m-0 p-0">
                    <div className="col-12">
                        <textarea
                            className="form-control no-resize cancel-reason"
                            maxLength={250}
                            defaultValue={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                        ></textarea>
                    </div>
                    <div className="col-12">
                        <button
                            className="cancel-button float-right ml-3"
                            onClick={() => {
                                setRemarks("");
                                setVisible(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button className="submit-button float-right ml-3" onClick={onConfirmSubmitHandler}>
                            Submit
                        </button>
                    </div>
                </div>
            </Dialog>
            <Dialog
                header="Reason to cancel this Exam?"
                visible={cancelVisible}
                style={{ width: "50vw" }}
                onHide={() => {
                    if (!cancelVisible) return;
                    setCancelVisible(false);
                }}
            >
                <div className="row m-0 p-0">
                    <div className="col-12">
                        <textarea
                            className="form-control no-resize cancel-reason"
                            maxLength={250}
                            defaultValue={reason}
                            onChange={(e) => setReason(e.target.value)}
                        ></textarea>
                    </div>
                    <div className="col-12">
                        <button
                            className="cancel-button float-right ml-3"
                            onClick={() => {
                                setReason("");
                                setCancelVisible(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button className="submit-button float-right ml-3" onClick={onCancelExam}>
                            Submit
                        </button>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    );
};

export default PracticalExamView;
