import "../app.css";
import AlertService from "../../../services/alert.service";
import CatalogueService from "../../../services/catalogue.service";
import AuthContext from "../../../auth/auth-context";
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Chips } from "primereact/chips";
import { MultiSelect } from "primereact/multiselect";
import SolutionStep from "./solutionStep";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";

const AddCatalogue = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);

    const [incidentId, setIncidentId] = useState(0);
    const [incidentTitle, setIncidentTitle] = useState();
    const [incidentNo, setIncidentNo] = useState();
    const [storyBoard, setStoryBoard] = useState();
    const [task, setTask] = useState();
    const [tags, setTags] = useState();
    const [categoryId, setCategoryId] = useState([]);
    const [vulnerabilityObjective, setVulnerabilityObjective] = useState();
    const [learningObjective, setLearningObjective] = useState();
    const [technicalObjective, setTechnicalObjective] = useState();
    const [caseStudyObjective, setCaseStudyObjective] = useState();
    const [futureObjective, setFutureObjective] = useState();

    const [vulnerabilityObjectiveRemoved, setVulnerabilityObjectiveRemoved] = useState(false);
    const [learningObjectiveRemoved, setLearningObjectiveRemoved] = useState(false);
    const [technicalObjectiveRemoved, setTechnicalObjectiveRemoved] = useState(false);
    const [caseStudyObjectiveRemoved, setCaseStudyObjectiveRemoved] = useState(false);
    const [futureObjectiveRemoved, setFutureObjectiveRemoved] = useState(false);
    const [solutions, setSolutions] = useState([{ id: 1, stepName: "Step 1", stepText: "", isValid: true }]);

    const [incidentTitleIsValid, setIncidentTitleIsValid] = useState(true);
    const [incidentNoIsValid, setIncidentNoIsValid] = useState(true);
    const [storyBoardIsValid, setStoryBoardIsValid] = useState(true);
    const [taskIsValid, setTaskIsValid] = useState(true);
    const [categoryIsValid, setCategoryIsValid] = useState(true);
    const [vulnerabilityObjectiveIsValid, setVulnerabilityObjectiveIsValid] = useState(true);
    const [learningObjectiveIsValid, setLearningObjectiveIsValid] = useState(true);
    const [technicalObjectiveIsValid, setTechnicalObjectiveIsValid] = useState(true);
    const [caseStudyObjectiveIsValid, setCaseStudyObjectiveIsValid] = useState(true);
    const [futureObjectiveIsValid, setFutureObjectiveIsValid] = useState(true);

    const incidentTitleRef = useRef();
    const incidentNoRef = useRef();
    const storyBoardRef = useRef();
    const taskRef = useRef();
    const vulnerabilityObjectiveRef = useRef();
    const learningObjectiveRef = useRef();
    const technicalObjectiveRef = useRef();
    const caseStudyObjectiveRef = useRef();
    const futureObjectiveRef = useRef();

    const authContext = useContext(AuthContext);

    const loadIncident = useCallback(async () => {
        setLoading(true);
        const data = await CatalogueService.Get(params.id, authContext);
        if (data.isSuccess) {
            setLoading(false);
            setIncidentTitle(data.data.incidentTitle);
            setIncidentNo(data.data.incidentNo);
            setStoryBoard(data.data.storyBoard);
            setTask(data.data.task);
            setTags(data.data.tags && data.data.tags != null ? data.data.tags.split(",") : []);
            setCategoryId(data.data.categories);
            setVulnerabilityObjectiveRemoved(true);
            setLearningObjectiveRemoved(true);
            setTechnicalObjectiveRemoved(true);
            setCaseStudyObjectiveRemoved(true);
            setFutureObjectiveRemoved(true);
            if (data.data.objectives.length > 0) {
                if (data.data.objectives.filter((f) => f.objectiveType === 1).length > 0) {
                    setVulnerabilityObjectiveRemoved(false);
                    setVulnerabilityObjective(data.data.objectives.filter((f) => f.objectiveType === 1)[0].description);
                }
                if (data.data.objectives.filter((f) => f.objectiveType === 2).length > 0) {
                    setLearningObjectiveRemoved(false);
                    setLearningObjective(data.data.objectives.filter((f) => f.objectiveType === 2)[0].description);
                }
                if (data.data.objectives.filter((f) => f.objectiveType === 3).length > 0) {
                    setTechnicalObjectiveRemoved(false);
                    setTechnicalObjective(data.data.objectives.filter((f) => f.objectiveType === 3)[0].description);
                }
                if (data.data.objectives.filter((f) => f.objectiveType === 4).length > 0) {
                    setCaseStudyObjectiveRemoved(false);
                    setCaseStudyObjective(data.data.objectives.filter((f) => f.objectiveType === 4)[0].description);
                }
                if (data.data.objectives.filter((f) => f.objectiveType === 5).length > 0) {
                    setFutureObjectiveRemoved(false);
                    setFutureObjective(data.data.objectives.filter((f) => f.objectiveType === 5)[0].description);
                }
            }
            if (data.data.steps && data.data.steps.length > 0) {
                setSolutions(() => {
                    return [
                        ...data.data.steps.map((m) => ({
                            id: m.orderBy,
                            stepName: m.step,
                            stepText: m.description,
                            isValid: true,
                        })),
                    ];
                });
            }
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext, params.id]);

    const loadCategories = useCallback(async () => {
        const data = await CatalogueService.GetCategories(authContext);
        if (data.isSuccess) {
            setLoading(false);
            setCategories(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    useEffect(() => {
        loadCategories();
    }, [loadCategories, authContext]);

    useEffect(() => {
        if (params.id) {
            setIncidentId(params.id);
            loadIncident();
        } else {
            setLoading(false);
        }
    }, [loadIncident, authContext, params.id]);

    const addStepHandler = async (event) => {
        let maxId = solutions.length;
        let newStep = {
            id: maxId + 1,
            stepName: "Step " + (maxId + 1),
            stepText: "",
            isValid: true,
        };
        setSolutions((prevSteps) => {
            return [...prevSteps, newStep];
        });
    };

    const onCancelHandler = async (event) => {
        navigate("/catalogues");
    };

    const removeStepHandler = async (event) => {
        setSolutions((prevSteps) => {
            prevSteps = prevSteps.filter((f) => f.id !== event);
            if (prevSteps.length === 0) {
                let newStep = {
                    id: 1,
                    stepName: "Step 1",
                    stepText: "",
                };
                return [...prevSteps, newStep];
            } else {
                prevSteps = prevSteps.sort((a, b) => a.id - b.id);
                let i = 0;
                prevSteps.forEach((e) => {
                    i++;
                    e.id = i;
                    e.stepName = "Step " + i;
                });
                return [...prevSteps];
            }
        });
    };

    const stepValueHandler = async (id, value) => {
        setSolutions((prevSteps) => {
            prevSteps
                .filter((f) => f.id === id)
                .forEach((step) => {
                    step.stepText = value;
                    step.isValid = true;
                    if (!value) {
                        step.isValid = false;
                    }
                });
            return [...prevSteps];
        });
    };

    const onSubmitHandler = async (event) => {
        let isValid = true;
        event.preventDefault();
        let incidentTitleValue = incidentTitleRef.current.value;
        if (!incidentTitleValue) {
            setIncidentTitleIsValid(false);
            isValid = false;
        }
        let incidentNoValue = incidentNoRef.current.value;
        if (!incidentNoValue) {
            setIncidentNoIsValid(false);
            isValid = false;
        }
        let storyBoardValue = storyBoardRef.current.value;
        if (!storyBoardValue) {
            setStoryBoardIsValid(false);
            isValid = false;
        }
        let taskValue = taskRef.current.value;
        if (!taskValue) {
            setTaskIsValid(false);
            isValid = false;
        }
        let tagsValue = tags && tags.length > 0 && tags.join(", ");
        let categoryValue = categoryId;
        if (!categoryValue) {
            setCategoryIsValid(false);
            isValid = false;
        }
        setVulnerabilityObjectiveIsValid(true);
        let vulnerabilityObjectiveValue = vulnerabilityObjectiveRef.current.value;
        if (!vulnerabilityObjectiveRemoved && !vulnerabilityObjectiveValue) {
            setVulnerabilityObjectiveIsValid(false);
            isValid = false;
        }
        setLearningObjectiveIsValid(true);
        let learningObjectiveValue = learningObjectiveRef.current.value;
        if (!learningObjectiveRemoved && !learningObjectiveValue) {
            setLearningObjectiveIsValid(false);
            isValid = false;
        }
        setTechnicalObjectiveIsValid(true);
        let technicalObjectiveValue = technicalObjectiveRef.current.value;
        if (!technicalObjectiveRemoved && !technicalObjectiveValue) {
            setTechnicalObjectiveIsValid(false);
            isValid = false;
        }
        setCaseStudyObjectiveIsValid(true);
        let caseStudyObjectiveValue = caseStudyObjectiveRef.current.value;
        if (!caseStudyObjectiveRemoved && !caseStudyObjectiveValue) {
            setCaseStudyObjectiveIsValid(false);
            isValid = false;
        }
        setFutureObjectiveIsValid(true);
        let futureObjectiveValue = futureObjectiveRef.current.value;
        if (!futureObjectiveRemoved && !futureObjectiveValue) {
            setFutureObjectiveIsValid(false);
            isValid = false;
        }

        let newSolutions = solutions.map((step) => {
            step.isValid = true;
            if (!step.stepText) {
                step.isValid = false;
            }
            return step;
        });
        setSolutions(newSolutions);
        if (isValid) {
            isValid = newSolutions.filter((f) => f.isValid === false).length === 0;
        }
        if (
            isValid &&
            incidentTitleIsValid &&
            incidentNoIsValid &&
            storyBoardIsValid &&
            taskIsValid &&
            categoryIsValid &&
            (vulnerabilityObjectiveRemoved || vulnerabilityObjectiveIsValid) &&
            (learningObjectiveRemoved || learningObjectiveIsValid) &&
            (vulnerabilityObjectiveRemoved || vulnerabilityObjectiveIsValid) &&
            (vulnerabilityObjectiveRemoved || vulnerabilityObjectiveIsValid) &&
            (vulnerabilityObjectiveRemoved || vulnerabilityObjectiveIsValid)
        ) {
            setLoading(true);
            try {
                let objectives = [];

                if (!vulnerabilityObjectiveRemoved) {
                    objectives.push({
                        objectiveType: 1,
                        orderBy: 1,
                        description: vulnerabilityObjectiveValue,
                    });
                }
                if (!learningObjectiveRemoved) {
                    objectives.push({
                        objectiveType: 2,
                        orderBy: 2,
                        description: learningObjectiveValue,
                    });
                }
                if (!technicalObjectiveRemoved) {
                    objectives.push({
                        objectiveType: 3,
                        orderBy: 3,
                        description: technicalObjectiveValue,
                    });
                }
                if (!caseStudyObjectiveRemoved) {
                    objectives.push({
                        objectiveType: 4,
                        orderBy: 4,
                        description: caseStudyObjectiveValue,
                    });
                }
                if (!futureObjectiveRemoved) {
                    objectives.push({
                        objectiveType: 5,
                        orderBy: 5,
                        description: futureObjectiveValue,
                    });
                }

                let payload = {
                    incidentTitle: incidentTitleValue,
                    incidentNo: incidentNoValue,
                    storyBoard: storyBoardValue,
                    task: taskValue,
                    tags: tagsValue,
                    categories: categoryValue,
                    objectives: objectives,
                    steps: solutions.map((m) => ({
                        step: m.stepName,
                        orderBy: m.id,
                        description: m.stepText,
                    })),
                };
                if (incidentId > 0) {
                    payload.id = incidentId;
                }
                let data = await CatalogueService.Save(payload, authContext);
                if (data.isSuccess) {
                    navigate("/catalogues");
                } else {
                    AlertService.error(data.errorMessage);
                }
                setLoading(false);
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    };

    const onConfirmDeleteHandler = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Are you sure you want to delete this incident?",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept: onDeleteHandler,
        });
    };

    const onDeleteHandler = async () => {
        let data = await CatalogueService.Remove(incidentId, authContext);
        if (data.isSuccess) {
            AlertService.success("Incident details deleted successfully!");
            navigate("/catalogues");
        } else {
            AlertService.error(data.errorMessage);
        }
    };

    return (
        <Fragment>
            {!loading && (
                <form onSubmit={onSubmitHandler}>
                    <div className="row pt-4 catalogue">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Incident Title</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Incident Title"
                                maxLength="150"
                                defaultValue={incidentTitle}
                                ref={incidentTitleRef}
                                onChange={(e) => {
                                    setIncidentTitle(e.value);
                                    setIncidentTitleIsValid(true);
                                }}
                            />
                            {!incidentTitleIsValid && <p className="error">Please provide incident title</p>}
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Incident No</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Incident No"
                                maxLength="50"
                                defaultValue={incidentNo}
                                ref={incidentNoRef}
                                onChange={(e) => {
                                    setIncidentNo(e.value);
                                    setIncidentNoIsValid(true);
                                }}
                            />
                            {!incidentNoIsValid && <p className="error">Please provide incident no.</p>}
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Story Board</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <textarea
                                type="text"
                                className="form-control"
                                placeholder="Story Board"
                                maxLength="1000"
                                defaultValue={storyBoard}
                                ref={storyBoardRef}
                                onChange={(e) => {
                                    setStoryBoard(e.value);
                                    setStoryBoardIsValid(true);
                                }}
                            />
                            {!storyBoardIsValid && <p className="error">Please provide story board</p>}
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Task</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Task"
                                maxLength="300"
                                defaultValue={task}
                                ref={taskRef}
                                onChange={(e) => {
                                    setTask(e.value);
                                    setTaskIsValid(true);
                                }}
                            />
                            {!taskIsValid && <p className="error">Please provide task</p>}
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Tags</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <Chips
                                value={tags}
                                allowDuplicate={false}
                                addOnBlur={true}
                                onChange={(e) => {
                                    setTags(e.value);
                                }}
                                separator=","
                            />
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Category</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <MultiSelect
                                filter
                                value={categoryId}
                                onChange={(e) => {
                                    setCategoryId(e.value);
                                    setCategoryIsValid(true);
                                }}
                                options={categories}
                                maxSelectedLabels={5}
                            />
                            {!categoryIsValid && <p className="error">Please select category</p>}
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-1 col-sm-1 d-xs-none"></div>
                        <div className="col-md-2 col-sm-4 col-xs-12 label">5 O's</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <div className={vulnerabilityObjectiveRemoved ? "row text-decoration-line-through" : "row"}>
                                <div className="col-md-5 col-sm-5 col-xs-12 value">
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled
                                        value="Vulnerability Objective"
                                    />
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-10 value p-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Type here"
                                        disabled={vulnerabilityObjectiveRemoved}
                                        maxLength="300"
                                        defaultValue={vulnerabilityObjective}
                                        ref={vulnerabilityObjectiveRef}
                                        onChange={(e) => {
                                            setVulnerabilityObjective(e.value);
                                            setVulnerabilityObjectiveIsValid(true);
                                        }}
                                    />
                                    {!vulnerabilityObjectiveIsValid && !vulnerabilityObjectiveRemoved && (
                                        <p className="error">Please provide vulnerability objective</p>
                                    )}
                                </div>
                                <div className="col-md-1 col-sm-1 col-xs-2 action">
                                    <button
                                        type="button"
                                        className="form-control"
                                        onClick={() => setVulnerabilityObjectiveRemoved(!vulnerabilityObjectiveRemoved)}
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                            <div
                                className={
                                    learningObjectiveRemoved ? "row pt-1 text-decoration-line-through" : "row pt-1"
                                }
                            >
                                <div className="col-md-5 col-sm-5 col-xs-12 value">
                                    <input type="text" className="form-control" disabled value="Learning Objective" />
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-8 value p-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Type here"
                                        disabled={learningObjectiveRemoved}
                                        maxLength="300"
                                        defaultValue={learningObjective}
                                        ref={learningObjectiveRef}
                                        onChange={(e) => {
                                            setLearningObjective(e.value);
                                            setLearningObjectiveIsValid(true);
                                        }}
                                    />
                                    {!learningObjectiveIsValid && !learningObjectiveRemoved && (
                                        <p className="error">Please provide learning objective</p>
                                    )}
                                </div>
                                <div className="col-md-1 col-sm-1 col-xs-2 action">
                                    <button
                                        type="button"
                                        className="form-control"
                                        onClick={() => setLearningObjectiveRemoved(!learningObjectiveRemoved)}
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                            <div
                                className={
                                    technicalObjectiveRemoved ? "row pt-1 text-decoration-line-through" : "row pt-1"
                                }
                            >
                                <div className="col-md-5 col-sm-5 col-xs-12 value">
                                    <input type="text" className="form-control" disabled value="Technical Objective" />
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-8 value p-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Type here"
                                        disabled={technicalObjectiveRemoved}
                                        maxLength="300"
                                        defaultValue={technicalObjective}
                                        ref={technicalObjectiveRef}
                                        onChange={(e) => {
                                            setTechnicalObjective(e.value);
                                            setTechnicalObjectiveIsValid(true);
                                        }}
                                    />
                                    {!technicalObjectiveIsValid && !technicalObjectiveRemoved && (
                                        <p className="error">Please provide technical objective</p>
                                    )}
                                </div>
                                <div className="col-md-1 col-sm-1 col-xs-2 action">
                                    <button
                                        type="button"
                                        className="form-control"
                                        onClick={() => setTechnicalObjectiveRemoved(!technicalObjectiveRemoved)}
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                            <div
                                className={
                                    caseStudyObjectiveRemoved ? "row pt-1 text-decoration-line-through" : "row pt-1"
                                }
                            >
                                <div className="col-md-5 col-sm-5 col-xs-12 value">
                                    <input type="text" className="form-control" disabled value="Case Study Objective" />
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-8 value p-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Type here"
                                        disabled={caseStudyObjectiveRemoved}
                                        maxLength="300"
                                        defaultValue={caseStudyObjective}
                                        ref={caseStudyObjectiveRef}
                                        onChange={(e) => {
                                            setCaseStudyObjective(e.value);
                                            setCaseStudyObjectiveIsValid(true);
                                        }}
                                    />
                                    {!caseStudyObjectiveIsValid && !caseStudyObjectiveRemoved && (
                                        <p className="error">Please provide case study objective</p>
                                    )}
                                </div>
                                <div className="col-md-1 col-sm-1 col-xs-2 action">
                                    <button
                                        type="button"
                                        className="form-control"
                                        onClick={() => setCaseStudyObjectiveRemoved(!caseStudyObjectiveRemoved)}
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                            <div
                                className={
                                    futureObjectiveRemoved ? "row pt-1 text-decoration-line-through" : "row pt-1"
                                }
                            >
                                <div className="col-md-5 col-sm-5 col-xs-12 value">
                                    <input type="text" className="form-control" disabled value="Future Objective" />
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-8 value p-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Type here"
                                        disabled={futureObjectiveRemoved}
                                        maxLength="300"
                                        defaultValue={futureObjective}
                                        ref={futureObjectiveRef}
                                        onChange={(e) => {
                                            setFutureObjective(e.value);
                                            setFutureObjectiveIsValid(true);
                                        }}
                                    />
                                    {!futureObjectiveIsValid && !futureObjectiveRemoved && (
                                        <p className="error">Please provide future objective</p>
                                    )}
                                </div>
                                <div className="col-md-1 col-sm-1 col-xs-2 action">
                                    <button
                                        type="button"
                                        className="form-control"
                                        onClick={() => setFutureObjectiveRemoved(!futureObjectiveRemoved)}
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Solution</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            {solutions &&
                                solutions.map((step) => (
                                    <SolutionStep
                                        key={step.id}
                                        id={step.id}
                                        stepName={step.stepName}
                                        stepText={step.stepText}
                                        removeHandler={removeStepHandler}
                                        valueHandler={stepValueHandler}
                                        isValid={step.isValid}
                                    ></SolutionStep>
                                ))}
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-1">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label"></div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <button className="float-right cursor-pointer link-button" onClick={addStepHandler}>
                                <i className="pi pi-plus"></i> Add Step
                            </button>
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-2 mb-3">
                        <div className="col-md-12 col-sm-12">
                            <div className="text-center pt-4 pb-3">
                                <button type="submit" className="submit-button" disabled={loading}>
                                    Submit
                                </button>
                                {incidentId > 0 && (
                                    <>
                                        <ConfirmPopup />
                                        <button
                                            type="button"
                                            className="remove-button ml-3"
                                            onClick={onConfirmDeleteHandler}
                                            disabled={loading}
                                        >
                                            Delete
                                        </button>
                                    </>
                                )}
                                <button
                                    type="button"
                                    className="cancel-button ml-3"
                                    onClick={onCancelHandler}
                                    disabled={loading}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Fragment>
    );
};

export default AddCatalogue;
