import AlertService from "../../../services/alert.service";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../../auth/auth-context";
import DocumentViewer from "../documents/viewer";
import CourseService from "../../../services/course.service";

const DocViewer = ({ doc, clearSelection, title }) => {
    const [loading, setLoading] = useState(false);
    const [document, setDocument] = useState();
    const [contentType, setContentType] = useState();
    const authContext = useContext(AuthContext);

    const downloadFile = useCallback(async () => {
        setDocument(null);
        setLoading(true);
        const data = await CourseService.GetFile(doc.documentId, authContext);
        if (data) {
            if (data.isSuccess) {
                // setDocument(`data:${data.data.contentType};base64,${data.data.filePath}`);
                setContentType(data.data.contentType);
                setDocument(data.data.filePath);
            } else {
                AlertService.error(data.errorMessage);
            }
        }
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, [doc.documentId, authContext]);

    useEffect(() => {
        downloadFile();
    }, [downloadFile, doc.documentId, authContext]);

    return (
        <Fragment>
            {!loading && document && (
                <DocumentViewer
                    src={document}
                    contentType={contentType}
                    title={title}
                    clearSelection={clearSelection}
                ></DocumentViewer>
            )}
            {loading &&
                <div className="row m-0">
                    <div className="col-12 h-view" style={{ height: 'calc(100vh - 120px)' }}>
                        <div className="loader"></div>
                    </div>
                </div>
            }
        </Fragment>
    );
};

export default DocViewer;
