import { Fragment, useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../auth/auth-context";

const Logout = () => {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const onLogoutHandler = useCallback(() => {
        authContext.logout();
        navigate("/login");
    }, [authContext, navigate]);

    useEffect(() => {
        onLogoutHandler();
    }, [onLogoutHandler]);

    return <Fragment>Processing...</Fragment>;
};

export default Logout;
