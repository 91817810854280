import { Fragment } from "react";
import Attachment from "./attachment";

const View = ({ document, clearSelection, title }) => {
    return (
        <Fragment>
            {document && (
                <div className="doc-container p-0">
                    {document.attachments &&
                        document.attachments.length > 0 &&
                        document.attachments.map((m, i) => (
                            <Attachment
                                key={i}
                                no={i}
                                attachment={m}
                                clearSelection={clearSelection}
                                title={title}
                            ></Attachment>
                        ))}
                </div>
            )}
        </Fragment>
    );
};

export default View;
