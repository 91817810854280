import { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../auth/auth-context";
import UserService from "../../../services/user.service";
import AlertService from "../../../services/alert.service";
import { InputOtp } from "primereact/inputotp";
import "../identity.css";

const OTPQRVerify = () => {
    const navigate = useNavigate();
    const [codeIsValid, setCodeIsValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [codeValue, setCodeValue] = useState();

    const authContext = useContext(AuthContext);

    const onSubmitHandler = async (event) => {
        event.preventDefault();

        if (!codeValue) {
            setCodeIsValid(false);
            return false;
        }

        if (codeIsValid) {
            try {
                setLoading(true);
                const result = await UserService.ValidateMFAOTP(codeValue, authContext);
                setLoading(false);
                if (result.isSuccess) {
                    setLoading(false);
                    navigate("/configureqr/" + result.data);
                } else {
                    setLoading(false);
                    AlertService.error(result.errorMessage);
                }
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    };

    return (
        <Fragment>
            <div className="identity-container no-background">
                <div className="row identity-wrapper">
                    <div className="col-lg-12">
                        {!loading && (
                            <div className="identity-form text-center">
                                <h1>OTP Verification</h1>
                                <small>
                                    Please provide the One-Time Password sent to your email in the textbox below.
                                </small>
                                <form onSubmit={onSubmitHandler}>
                                    <div className="row m-0">
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                        <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 m-auto otp-holder">
                                            <InputOtp
                                                onChange={(e) => {
                                                    setCodeValue(e.value);
                                                    setLoading(false);
                                                }}
                                                onKeyUp={() => setLoading(false)}
                                                length={8}
                                                disabled={loading}
                                            />
                                        </div>
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    </div>
                                    <div className="text-center pt-4">
                                        <button type="submit" className="identity-button" disabled={loading}>
                                            Verify OTP
                                        </button>
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default OTPQRVerify;
