import { Fragment } from "react";

const Card = ({ id, no, title, active, clickHandler }) => {
    return (
        <Fragment>
            <div className={active ? "card active" : "card"} onClick={() => clickHandler(id)}>
                <div>
                    {/* <div className="name">Document {no}</div> */}
                    <div className="name" title={title}>
                        {title}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Card;
