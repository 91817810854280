import { Fragment } from "react";

const Card = ({ title, clickHandler }) => {
    return (
        <Fragment>
            <div className="card" onClick={clickHandler}>
                <div>
                    <div className="name" title={title}>
                        {title}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Card;
