import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import AuthContext from "../../../../auth/auth-context";
import AgentService from "../../../../services/agent.service";
import UserService from "../../../../services/user.service";
import AlertService from "../../../../services/alert.service";
import "../../../identity/identity.css";

const Agent = () => {
    const [loading, setLoading] = useState(false);
    const [agents, setAgents] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState();
    const [id, setId] = useState(0);
    const [agentId, setAgentId] = useState();
    const [agentType, setAgentType] = useState();
    const [active, setActive] = useState(true);

    const agentTypes = [{ value: 1, label: "Support" }];

    const authContext = useContext(AuthContext);

    const [agentIdIsValid, setAgentIdIsValid] = useState(true);
    const [agentTypeIsValid, setAgentTypeIsValid] = useState(true);

    const assignAgent = async (e) => {
        if (agents.filter((f) => f.id === e).length > 0) {
            setAgentIdIsValid(true);
            let selectedAgentData = agents.filter((f) => f.id === e)[0];
            setId(selectedAgentData.data.id);
            setAgentId(selectedAgentData.data.agentId);
            setAgentType(selectedAgentData.data.agentType);
            setActive(selectedAgentData.data.isActive);
        }
    };

    const loadUsers = useCallback(async () => {
        setLoading(true);
        const data = await UserService.GetList(authContext);
        if (data.isSuccess) {
            setLoading(false);
            setUsers(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    const loadAgents = useCallback(async () => {
        setLoading(true);
        const data = await AgentService.GetAll(authContext);
        if (data.isSuccess) {
            setLoading(false);
            setAgents(
                data.data.map((m) => ({
                    id: m.id,
                    key: m.id,
                    label: m.agentName,
                    data: m,
                }))
            );
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    useEffect(() => {
        loadUsers();
        loadAgents();
    }, [loadAgents, loadUsers, authContext]);

    const clearData = () => {
        setSelectedAgent(0);
        setId(0);
        setAgentId(0);
        setAgentType(0);
        setActive(true);
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        let agentIdValue = agentId;
        if (!agentIdValue) {
            setAgentIdIsValid(false);
        }
        let agentTypeValue = agentType;
        if (!agentTypeValue) {
            setAgentTypeIsValid(false);
        }
        if (agentIdIsValid && agentTypeIsValid) {
            setLoading(true);
            try {
                let payload = {
                    instanceId: 1,
                    agentId: agentIdValue,
                    agentType: agentTypeValue,
                    isActive: active,
                };
                if (id > 0) {
                    payload.id = id;
                }
                let data = await AgentService.Save(payload, authContext);
                if (data.isSuccess) {
                    clearData();
                    await loadAgents();
                    AlertService.success("Agent details saved successfully!");
                } else {
                    AlertService.error(data.errorMessage);
                }
                setLoading(false);
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    };

    const onCancelHandler = async (e) => {
        e.preventDefault();
        clearData();
    };

    return (
        <Fragment>
            <div className="container-fluid">
                <h2>Agents</h2>
                <div className="row m-0">
                    <div className="col-lg-4 d-none d-lg-block">
                        <Tree
                            value={agents}
                            selectionMode="single"
                            selectionKeys={selectedAgent}
                            onSelectionChange={(e) => {
                                setSelectedAgent(e.value);
                                assignAgent(e.value);
                            }}
                            className="w-full"
                            loading={loading}
                        />
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <div className="row pt-4 d-block d-lg-none">
                            <div className="col-12">
                                <select
                                    className="form-control"
                                    value={selectedAgent}
                                    onChange={(e) => {
                                        setSelectedAgent(Number(e.target.value));
                                        assignAgent(Number(e.target.value));
                                    }}
                                >
                                    {agents &&
                                        agents.map((agent) => (
                                            <option key={agent.id} value={agent.id}>
                                                {agent.label}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        <form onSubmit={onSubmitHandler}>
                            <div className="row pt-4">
                                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                                <div className="col-md-2 col-sm-3 col-xs-12 label">Agent</div>
                                <div className="col-md-8 col-sm-5 col-xs-12 value">
                                    <Dropdown
                                        value={agentId}
                                        onChange={(e) => setAgentId(e.value)}
                                        options={users}
                                        optionLabel="label"
                                        placeholder="Select an agent"
                                        className="w-full"
                                    />
                                    {!agentIdIsValid && <p className="error">Please select an agent</p>}
                                </div>
                                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                                <div className="col-md-2 col-sm-3 col-xs-12 label">Agent Type</div>
                                <div className="col-md-8 col-sm-5 col-xs-12 value">
                                    <Dropdown
                                        value={agentType}
                                        onChange={(e) => setAgentType(e.value)}
                                        options={agentTypes}
                                        optionLabel="label"
                                        placeholder="Select type"
                                        className="w-full"
                                    />
                                    {!agentTypeIsValid && <p className="error">Please select type</p>}
                                </div>
                                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                                <div className="col-md-2 col-sm-3 col-xs-12 label">Active</div>
                                <div className="col-md-8 col-sm-5 col-xs-12 value">
                                    <Checkbox onChange={(e) => setActive(e.checked)} checked={active}></Checkbox>
                                </div>
                                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                            </div>
                            <div className="row pt-2 mb-3">
                                <div className="col-md-12 col-sm-12">
                                    <div className="text-center pt-4">
                                        <button type="submit" className="submit-button" disabled={loading}>
                                            Submit
                                        </button>
                                        <button
                                            type="button"
                                            className="cancel-button ml-3"
                                            onClick={onCancelHandler}
                                            disabled={loading}
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Agent;
