import { Fragment } from "react";

const Card = ({ id, data, clickHandler }) => {
    return (
        <Fragment>
            <div className="quiz-container cursor-pointer m-auto border" onClick={() => clickHandler(id)}>
                <div className="title">{data.attendeeName}</div>
                {data.isMCQExamCompleted && (
                    <>
                        <div className="score-container pt-3">
                            <center>
                                <span>
                                    Original Score :{" "}
                                    <label className="score">
                                        {data.correctMCQAnswers + data.graceMarks}/{data.totalMCQQuestions},{" "}
                                        {data.mcqScore}%
                                    </label>
                                </span>
                            </center>
                        </div>
                        <div className="score-container pt-3">
                            <center>
                                <span>
                                    Confident Score :{" "}
                                    <label className="score">
                                        {data.mcqConfidentScore}/{data.totalMCQQuestions}
                                    </label>
                                </span>
                            </center>
                        </div>
                    </>
                )}
            </div>
        </Fragment>
    );
};

export default Card;
