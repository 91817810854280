import request from "./request";

const RoleService = {
    GetAll: async (context) => {
        return await request.sendRequest(`/role/all`, context.token);
    },
    Get: async (id, context) => {
        return await request.sendRequest(`/role/${id}`, context.token);
    },
    GetPermissions: async (id, context) => {
        return await request.sendRequest(`/role/permissions/${id}`, context.token);
    },
    Save: async (payload, context) => {
        return await request.sendPostRequest(`/role`, payload, context);
    },
    SavePermission: async (payload, context) => {
        return await request.sendPostRequest(`/role/permission`, payload, context);
    },
    Remove: async (id, context) => {
        return await request.sendDeleteRequest(`/role/${id}`, context.token);
    },
};

export default RoleService;
