import request from "./request";

const AssessmentService = {
    GetAll: async (context) => {
        return await request.sendRequest(`/assessment/all`, context.token);
    },
    GetList: async (context) => {
        return await request.sendRequest(`/assessment/list`, context.token);
    },
    GetMCQList: async (context) => {
        return await request.sendRequest(`/assessment/mcq/list`, context.token);
    },
    GetPracticalList: async (context) => {
        return await request.sendRequest(`/assessment/practical/list`, context.token);
    },
    Get: async (id, context) => {
        return await request.sendRequest(`/assessment/${id}`, context.token);
    },
    Save: async (payload, context) => {
        return await request.sendPostRequest(`/assessment`, payload, context);
    },
    Remove: async (id, context) => {
        return await request.sendDeleteRequest(`/assessment/${id}`, context.token);
    },
};

export default AssessmentService;
