import { Fragment, useContext, useRef, useState } from "react";
import AuthContext from "../../../auth/auth-context";
import SupportService from "../../../services/support.service";
import AlertService from "../../../services/alert.service";

const Comment = ({ ticketId, submitHandler, cancelHandler }) => {
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState();
    const [descriptionIsValid, setDescriptionIsValid] = useState(true);
    const [files, setFiles] = useState([]);

    const descriptionRef = useRef();
    const fileUploadRef = useRef();

    const authContext = useContext(AuthContext);

    const handleChange = (event) => {
        setFiles((prevFiles) => {
            for (let file of event.target.files) {
                prevFiles.push({
                    fileName: file.name,
                    filePath: file.name,
                    data: file,
                });
            }
            return [...prevFiles];
        });
        setTimeout(() => {
            fileUploadRef.current.value = "";
        }, 100);
    };

    const removeFileHandler = (file) => {
        setFiles((prevFiles) => {
            prevFiles = prevFiles.filter((f) => f.fileName !== file.fileName);
            return [...prevFiles];
        });
    };

    const clearForm = async () => {
        if (descriptionRef && descriptionRef.current) descriptionRef.current.value = "";
        if (fileUploadRef && fileUploadRef.current) fileUploadRef.current.value = "";
        setDescription("");
        setFiles([]);
    };

    const onCancelHandler = async (event) => {
        event.preventDefault();
        await clearForm();
        cancelHandler();
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        let descriptionValue = descriptionRef.current.value;
        if (!descriptionValue) {
            setDescriptionIsValid(false);
        } else {
            setDescription(descriptionValue);
        }
        if (descriptionIsValid) {
            setLoading(true);
            try {
                const formData = new FormData();
                files.forEach((file) => {
                    formData.append("files", file.data);
                });
                formData.append(`ticketId`, ticketId);
                formData.append(`comment`, descriptionValue);
                let data = await SupportService.SaveComment(formData, authContext);
                if (data.isSuccess) {
                    await clearForm();
                    submitHandler();
                } else {
                    AlertService.error(data.errorMessage);
                }
                setLoading(false);
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    };

    return (
        <Fragment>
            {!loading && (
                <form onSubmit={onSubmitHandler} className="comment">
                    <div className="row m-0">
                        <div className="col-6">
                            <span>Add Comment</span>
                        </div>
                        <div className="col-6 text-right">
                            <span className="file-selector" onClick={() => fileUploadRef.current.click()}>
                                @ Attach file
                            </span>
                        </div>
                        <div className="col-12">
                            <textarea
                                type="text"
                                className="form-control"
                                placeholder="Description"
                                maxLength="800"
                                defaultValue={description}
                                ref={descriptionRef}
                                onChange={() => setDescriptionIsValid(true)}
                            />
                            {!descriptionIsValid && <p className="error">Please provide description</p>}
                        </div>
                    </div>
                    <div className="row pt-1 m-0 border-bottom d-none">
                        <div className="col-1">
                            <span>Files</span>
                        </div>
                        <div className="col-11">
                            <div className="input-group">
                                <div className="custom-file">
                                    <input
                                        ref={fileUploadRef}
                                        type="file"
                                        className="custom-file-input"
                                        onChange={handleChange}
                                        multiple
                                        accept="application/pdf"
                                        id="fileuploadcomment"
                                    ></input>
                                    <label className="custom-file-label" htmlFor="fileuploadcomment">
                                        Choose file
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {files && files.length > 0 && (
                        <div className="row pt-1 m-0">
                            <div className="col-12 label"></div>
                            <div className="col-12 value document-files">
                                {files &&
                                    files.length > 0 &&
                                    files.map((m, i) => (
                                        <div key={i} className="file">
                                            <i
                                                className="fa fa-trash mr-2 text-danger cursor-pointer"
                                                title={"Remove file"}
                                                onClick={() => removeFileHandler(m)}
                                            ></i>{" "}
                                            {m.fileName}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                    <div className="row m-0 mb-1">
                        <div className="col-md-12 col-sm-12">
                            <div className="text-right pt-1">
                                <button type="submit" className="submit-button" disabled={loading}>
                                    Add Comment
                                </button>
                                <button
                                    type="button"
                                    className="cancel-button ml-3"
                                    onClick={onCancelHandler}
                                    disabled={loading}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Fragment>
    );
};

export default Comment;
