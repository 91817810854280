import { Fragment } from "react";

const View = ({ document }) => {
    return (
        <Fragment>
            {document && (
                <div className="view-container">
                    <div className="row m-0">
                        <div className="col-12">
                            <span>Document Type: </span> {document.documentType}
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default View;
