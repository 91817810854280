import AuthContext from "../../../auth/auth-context";
import QuizService from "../../../services/quiz.service";
import AlertService from "../../../services/alert.service";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";

const Quizzes = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [quizzes, setQuizzes] = useState([]);
    const authContext = useContext(AuthContext);

    const assessmentAccess = authContext.getPermissions("quiz");
    if (assessmentAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }

    const loadAssessments = useCallback(async () => {
        setLoading(true);
        const data = await QuizService.GetAll(authContext);
        if (data.isSuccess) {
            setLoading(false);
            setQuizzes(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    useEffect(() => {
        loadAssessments();
    }, [loadAssessments, authContext]);

    const onViewHandler = async (e, data) => {
        if (data.isCompleted === false) {
            onConfirmStartHandler(e, data);
        }
        if (data.isCompleted) {
            navigate(`/quizzes/review/${data.id}`);
        }
    };

    const onConfirmStartHandler = (event, data) => {
        confirmPopup({
            target: event.currentTarget,
            message:
                "Are you sure you want to start this quiz? Once the quiz is started, You cannot stop/re-attend it.",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept: () => onStartHandler(data),
        });
    };

    const onStartHandler = async (data) => {
        authContext.setOnGoingValidations(true);
        navigate(`/quizzes/start/${data.id}`);
    };

    return (
        <Fragment>
            <div className="row m-0 pl-3 pr-3">
                <ConfirmPopup />
                {!loading &&
                    quizzes.length > 0 &&
                    quizzes.map((m, i) => (
                        <div key={m.id} className="col-4 text-center">
                            <div className="quiz-container cursor-pointer" onClick={(e) => onViewHandler(e, m)}>
                                <div className="title">{m.quizName}</div>
                                <div className="body">
                                    {m.isCompleted === false && <span>Please Click Here to enter the Quiz</span>}
                                    {m.isCompleted && <span>Please Click Here to Review the Quiz</span>}
                                </div>
                                {m.isCompleted && (
                                    <>
                                        <div className="completed">
                                            <center>
                                                <span>Completed</span>
                                            </center>
                                        </div>
                                        <div className="score-container pt-3">
                                            <center>
                                                <span>
                                                    Score :{" "}
                                                    <label className="score">
                                                        {m.correctMCQAnswers + m.graceMarks}/{m.totalMCQQuestions},{" "}
                                                        {m.mcqScore}%
                                                    </label>
                                                </span>
                                            </center>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                {!loading && quizzes.length === 0 && <div className="col-12 text-center">No Quizzes Available!!</div>}
                {loading && <div className="col-12">Loading...</div>}
            </div>
        </Fragment>
    );
};

export default Quizzes;
