import "../app.css";
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import AlertService from "../../../services/alert.service";
import SupportService from "../../../services/support.service";
import CourseService from "../../../services/course.service";
import AuthContext from "../../../auth/auth-context";

const AddSupport = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState([]);
    const categories = [
        { value: 1, label: "Login" },
        { value: 2, label: "Technical" },
        { value: 3, label: "Learning Material" },
        { value: 4, label: "Quiz/Assessment" },
    ];

    const [course, setCourse] = useState();
    const [subject, setSubject] = useState();
    const [description, setDescription] = useState();
    const [category, setCategory] = useState();
    const [files, setFiles] = useState([]);

    const [courseIsValid, setCourseIsValid] = useState(true);
    const [subjectIsValid, setSubjectIsValid] = useState(true);
    const [descriptionIsValid, setDescriptionIsValid] = useState(true);
    const [categoryIsValid, setCategoryIsValid] = useState(true);

    const subjectRef = useRef();
    const descriptionRef = useRef();
    const fileUploadRef = useRef();

    const authContext = useContext(AuthContext);

    const loadCourses = useCallback(async () => {
        const data = await CourseService.GetCourses(authContext);
        if (data.isSuccess) {
            setLoading(false);
            setCourses(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    useEffect(() => {
        loadCourses();
    }, [loadCourses, authContext]);

    const onCancelHandler = async (event) => {
        navigate("/support");
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        let courseValue = course;
        if (!courseValue) {
            setCourseIsValid(false);
        }
        let subjectValue = subjectRef.current.value;
        if (!subjectValue) {
            setSubjectIsValid(false);
        } else {
            setSubject(subjectValue);
        }
        let descriptionValue = descriptionRef.current.value;
        if (!descriptionValue) {
            setDescriptionIsValid(false);
        } else {
            setDescription(descriptionValue);
        }
        let categoryValue = category;
        if (!categoryValue) {
            setCategoryIsValid(false);
        }
        if (courseIsValid && subjectIsValid && descriptionIsValid && categoryIsValid) {
            setLoading(true);
            try {
                const formData = new FormData();
                files.forEach((file) => {
                    formData.append("files", file.data);
                });
                formData.append(`courseId`, courseValue);
                formData.append(`courseName`, courseValue);
                formData.append(`ticketNumber`, 0);
                formData.append(`subject`, subjectValue);
                formData.append(`description`, descriptionValue);
                formData.append(`categoryId`, categoryValue);
                formData.append(`requesterId`, authContext.userId);
                formData.append(`assignedTo`, 0);
                formData.append(`status`, 1);
                formData.append(`isReopened`, false);
                formData.append(`isActive`, true);
                formData.append(`isDeleted`, false);
                formData.append(`attachments`, []);
                let data = await SupportService.Save(formData, authContext);
                if (data.isSuccess) {
                    navigate("/support");
                } else {
                    AlertService.error(data.errorMessage);
                }
                setLoading(false);
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    };

    const handleChange = (event) => {
        for (let file of event.target.files) {
            if (file.type !== "application/pdf") {
                AlertService.error("Please select PDF files. Other file types are not supported!!");
                return false;
            }
        }
        setFiles((prevFiles) => {
            for (let file of event.target.files) {
                prevFiles.push({
                    fileName: file.name,
                    filePath: file.name,
                    data: file,
                });
            }
            return [...prevFiles];
        });
        setTimeout(() => {
            fileUploadRef.current.value = "";
        }, 100);
    };

    const removeFileHandler = (file) => {
        setFiles((prevFiles) => {
            prevFiles = prevFiles.filter((f) => f.fileName !== file.fileName);
            return [...prevFiles];
        });
    };

    return (
        <Fragment>
            {!loading && (
                <form onSubmit={onSubmitHandler}>
                    <div className="row pt-4 catalogue">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Category</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <Dropdown
                                value={category}
                                onChange={(e) => setCategory(e.value)}
                                options={categories}
                                optionLabel="label"
                                placeholder="Select a Category"
                                className="w-full"
                            />
                            {!categoryIsValid && <p className="error">Please select category</p>}
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4 catalogue">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Course</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <Dropdown
                                value={course}
                                filter
                                onChange={(e) => setCourse(e.value)}
                                options={courses}
                                optionLabel="label"
                                placeholder="Select a Course"
                                className="w-full"
                            />
                            {!courseIsValid && <p className="error">Please select course</p>}
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4 catalogue">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Subject</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Subject"
                                maxLength="150"
                                defaultValue={subject}
                                ref={subjectRef}
                                onChange={(e) => {
                                    setSubject(e.value);
                                    setSubjectIsValid(true);
                                }}
                            />
                            {!subjectIsValid && <p className="error">Please provide subject</p>}
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Description</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <textarea
                                type="text"
                                className="form-control"
                                placeholder="Description"
                                maxLength="800"
                                defaultValue={description}
                                ref={descriptionRef}
                                onChange={(e) => {
                                    setDescription(e.value);
                                    setDescriptionIsValid(true);
                                }}
                            />
                            {!descriptionIsValid && <p className="error">Please provide description</p>}
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Files</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <div className="input-group">
                                <div className="custom-file">
                                    <input
                                        ref={fileUploadRef}
                                        type="file"
                                        className="custom-file-input"
                                        onChange={handleChange}
                                        multiple
                                        accept="application/pdf"
                                        id="fileuploadticket"
                                    ></input>
                                    <label className="custom-file-label" htmlFor="fileuploadticket">
                                        Choose file
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label"></div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value document-files">
                            {files &&
                                files.length > 0 &&
                                files.map((m, i) => (
                                    <div key={i} className="file">
                                        <i
                                            className="fa fa-trash mr-2 text-danger cursor-pointer"
                                            title={"Remove file"}
                                            onClick={() => removeFileHandler(m)}
                                        ></i>{" "}
                                        {m.fileName}
                                    </div>
                                ))}
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-2 mb-3">
                        <div className="col-md-12 col-sm-12">
                            <div className="text-center pt-4">
                                <button type="submit" className="submit-button" disabled={loading}>
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    className="cancel-button ml-3"
                                    onClick={onCancelHandler}
                                    disabled={loading}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Fragment>
    );
};

export default AddSupport;
