import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import AuthContext from "../../../auth/auth-context";
import SupportService from "../../../services/support.service";
import AlertService from "../../../services/alert.service";
import "./support.css";
import View from "./view";

const Support = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [ticket, setTicket] = useState();
    const [status, setStatus] = useState(0);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(50);
    const [pageNo, setPageNo] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [maxPageNo, setMaxPageNo] = useState(1);
    const pageOptions = [25, 50, 75, 100, 150, 200, 250, 500];

    const authContext = useContext(AuthContext);
    const supportAccess = authContext.getPermissions("support");
    if (supportAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }
    const showAdd = supportAccess.filter((f) => f.access.includes(2)).length > 0;
    // const showEdit = supportAccess.filter((f) => f.access.includes(3).length > 0;

    const loadTickets = useCallback(async () => {
        setLoading(true);
        setTicket(null);
        const data = await SupportService.GetAll(status, skip, take, authContext);
        if (data.isSuccess) {
            setLoading(false);
            setTotalRecords(data.data.totalRecords);
            let mPageNo = Math.round(data.data.totalRecords / take, 0);
            setMaxPageNo(mPageNo === 0 ? 1 : mPageNo);
            setTickets(data.data.records);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [status, skip, take, authContext]);

    useEffect(() => {
        loadTickets();
    }, [loadTickets, status, skip, take, authContext]);

    const goToFirstPage = () => {
        setPageNo(1);
        setSkip(0);
    };

    const goToPreviousPage = () => {
        let cPageNo = pageNo - 1;
        cPageNo = cPageNo > 0 ? cPageNo : 1;
        setPageNo(cPageNo);
        setSkip(cPageNo * take - take);
    };

    const goToNextPage = () => {
        let cPageNo = pageNo + 1;
        cPageNo = cPageNo <= maxPageNo ? cPageNo : maxPageNo;
        setPageNo(cPageNo);
        setSkip(cPageNo * take - take);
    };

    const goToLastPage = () => {
        setPageNo(maxPageNo);
        setSkip(maxPageNo * take - take);
    };

    const clearSelection = () => {
        setTicket(null);
    };

    return (
        <Fragment>
            <div className="row m-0">
                <div className="col-6">
                    <h1 className="title">Support Tickets</h1>
                </div>
                <div className="col-6">
                    <div className="btn-group float-right" role="group" aria-label="Basic example">
                        <button
                            type="button"
                            onClick={() => setStatus(0)}
                            className={status === 0 ? "btn btn-info" : "btn btn-warning"}
                        >
                            All
                        </button>
                        <button
                            type="button"
                            onClick={() => setStatus(1)}
                            className={status === 1 ? "btn btn-info" : "btn btn-warning"}
                        >
                            Pending
                        </button>
                        <button
                            type="button"
                            onClick={() => setStatus(2)}
                            className={status === 2 ? "btn btn-info" : "btn btn-warning"}
                        >
                            In-Progress
                        </button>
                        <button
                            type="button"
                            onClick={() => setStatus(3)}
                            className={status === 3 ? "btn btn-info" : "btn btn-warning"}
                        >
                            Closed
                        </button>
                        <button
                            type="button"
                            onClick={() => setStatus(4)}
                            className={status === 4 ? "btn btn-info" : "btn btn-warning"}
                        >
                            Cancelled
                        </button>
                    </div>
                    {showAdd && (
                        <button className="btn btn-primary float-right mr-2" onClick={() => navigate("/support/add")}>
                            Add Ticket
                        </button>
                    )}
                </div>
            </div>
            <div className="row m-0">
                <div className={ticket != null ? "col-5" : "col-12"}>
                    <div className="tickets">
                        <div className="row m-0">
                            <div className="col-2 text-center header-cell">Ticket #</div>
                            <div className="col-3 text-center header-cell">Course</div>
                            <div className="col-3 text-center header-cell">Subject</div>
                            <div className="col-2 text-center header-cell">Status</div>
                            <div className="col-2 text-center header-cell">Request Date</div>
                        </div>
                        <div className="scroller">
                            {tickets &&
                                tickets.length > 0 &&
                                tickets.map((ticketObj, i) => (
                                    <div
                                        key={i}
                                        className={
                                            ticket !== null && ticketObj.id === ticket.id
                                                ? "row m-0 selected records"
                                                : "row m-0 records"
                                        }
                                        onClick={() =>
                                            setTicket((prevTicket) => (prevTicket === ticketObj ? null : ticketObj))
                                        }
                                    >
                                        <div className="col-2 value-cell">{ticketObj.ticketId}</div>
                                        <div className="col-3 value-cell">{ticketObj.courseName}</div>
                                        <div className="col-3 value-cell">{ticketObj.subject}</div>
                                        <div className="col-2 value-cell text-center">{ticketObj.statusDesc}</div>
                                        <div className="col-2 value-cell text-center">
                                            {Moment(new Date(ticketObj.ticketDate + "Z").toLocaleString()).format(
                                                "DD/MM/YYYY hh:mm A"
                                            )}
                                        </div>
                                    </div>
                                ))}
                            {tickets && tickets.length === 0 && (
                                <div className="row m-0 no-records">
                                    <div className="col-12 value-cell text-center">
                                        {loading ? "Loading..." : "No tickets found"}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="row m-0">
                            <div className="col-12 text-center header-cell p-0">
                                <div className="pager">
                                    Total Records : {totalRecords}
                                    <button className="btn btn-default" onClick={goToFirstPage}>
                                        &lt;&lt;
                                    </button>
                                    <button className="btn btn-default" onClick={goToPreviousPage}>
                                        &lt;
                                    </button>
                                    {pageNo}
                                    <button className="btn btn-default" onClick={goToNextPage}>
                                        &gt;
                                    </button>
                                    <button className="btn btn-default" onClick={goToLastPage}>
                                        &gt;&gt;
                                    </button>
                                    <select
                                        className="form-control select-pagesize"
                                        defaultValue={take}
                                        onChange={(e) => setTake(Number(e.target.value))}
                                    >
                                        {pageOptions.map((opt) => (
                                            <option key={opt} value={opt}>
                                                {opt}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {ticket != null && (
                    <div className="col-7 view">
                        <View clearSelection={clearSelection} ticket={ticket}></View>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default Support;
