import { useNavigate } from "react-router-dom";
import { useContext, Fragment, useRef, useState, useCallback, useEffect } from "react";
import AuthContext from "../../../auth/auth-context";
import LoginService from "../../../services/login.service";
import AlertService from "../../../services/alert.service";
import Header from "../header";
import "../identity.css";

const Reset = () => {
    const navigate = useNavigate();
    const [passwordIsValid, setPasswordIsValid] = useState(true);
    const [newPasswordIsValid, setNewPasswordIsValid] = useState(true);
    const [loading, setLoading] = useState(false);

    const newPasswordRef = useRef();
    const confirmPasswordRef = useRef();

    const authContext = useContext(AuthContext);

    const validateVerified = useCallback(() => {
        if (authContext.resetPassword === false) {
            navigate("/");
        }
    }, [authContext, navigate]);

    useEffect(() => {
        validateVerified();
    }, [validateVerified, authContext]);

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        const passwordValue = newPasswordRef.current.value;
        const newPasswordValue = confirmPasswordRef.current.value;

        if (!passwordValue) {
            setPasswordIsValid(false);
            return false;
        }
        if (!newPasswordValue) {
            setNewPasswordIsValid(false);
            return false;
        }
        if (passwordValue !== newPasswordValue) {
            setNewPasswordIsValid(false);
            return false;
        }
        const payload = {
            key: authContext.userId.toString(),
            value: passwordValue,
        };
        if (newPasswordIsValid) {
            try {
                setLoading(true);
                const result = await LoginService.ResetPassword(payload, authContext);
                setLoading(false);
                if (result.isSuccess) {
                    authContext.setResetPasswordCompleted(false);
                    if (authContext.mfaSetupEnabled === true && authContext.mfaSetupCompleted === false) {
                        navigate("/qrcode");
                    } else if (authContext.isAnswered === false) {
                        navigate("/questions");
                    } else {
                        navigate("/logout");
                    }
                } else {
                    setLoading(false);
                    AlertService.error(result.errorMessage);
                }
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    };

    return (
        <Fragment>
            <div className="identity-container">
                <Header />
                <div className="row identity-wrapper">
                    <div className="col-xl-6 col-lg-12">
                        <div className="identity-form">
                            <h1 className="mb-2">Reset Password 🔒</h1>
                            <form onSubmit={onSubmitHandler}>
                                <div className="row m-0">
                                    <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                        <input
                                            type="password"
                                            id="pwd"
                                            placeholder="New Password"
                                            name="pswd"
                                            required
                                            maxLength="25"
                                            ref={newPasswordRef}
                                            onChange={() => setLoading(false)}
                                            onKeyUp={() => setLoading(false)}
                                        />
                                        {!passwordIsValid && <p className="error">Please enter password</p>}
                                    </div>
                                    <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                </div>
                                <div className="row m-0">
                                    <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                        <input
                                            autoFocus
                                            type="password"
                                            id="npwd"
                                            placeholder="Re-enter New Password"
                                            name="npswd"
                                            required
                                            maxLength="25"
                                            ref={confirmPasswordRef}
                                            onChange={() => setLoading(false)}
                                            onKeyUp={() => setLoading(false)}
                                        />
                                        {!passwordIsValid && <p className="error">Please re-enter new password</p>}
                                    </div>
                                    <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                </div>
                                <div className="text-center pt-4">
                                    <button type="submit" className="identity-button" disabled={loading}>
                                        Reset
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 d-none d-xl-block identity-image">
                        <img alt="reset" src="assets/img/login.png"></img>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Reset;
