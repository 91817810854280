import request from "./request";

const LoginService = {
    Login: async (body) => {
        return await request.sendAuthRequest(`/Token`, body);
    },
    ResetPassword: async (model, context) => {
        return await request.sendPostRequest(`/user/resetpassword`, model, context);
    },
    ChangePassword: async (model, context) => {
        return await request.sendPostRequest(`/user/changepassword`, model, context);
    },
    SetPassword: async (model) => {
        return await request.sendUnAuthPostRequest(`/user/setpassword`, model);
    },
    Check: async (context) => {
        return await request.sendRequest(`/token/check`, context.token);
    },
    GetQuestions: async (code) => {
        return await request.sendUnAuthPostRequest(`/user/resetmfaq`, code);
    },
    ValidateSQ: async (code, model) => {
        return await request.sendUnAuthPostRequest(`/user/validateresetsq`, { code, Answers: model });
    },
};

export default LoginService;
