import UserService from "../../../services/user.service";
import AlertService from "../../../services/alert.service";
import AuthContext from "../../../auth/auth-context";
import { InputOtp } from "primereact/inputotp";
import { Fragment, useCallback, useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./profile.css";

const EditProfile = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [onLoadValidation, setOnLoadValidation] = useState(false);
    const [codeValue, setCodeValue] = useState();

    const [displayName, setDisplayName] = useState();
    const [email, setEmail] = useState();
    const [mobileNo, setMobileNo] = useState();
    const [showEditProfile, setShowEditProfile] = useState(false);

    const [codeIsValid, setCodeIsValid] = useState(true);
    const [displayNameIsValid, setDisplayNameIsValid] = useState(true);
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [mobileNoIsValid, setMobileNoIsValid] = useState(true);

    const displayNameRef = useRef();
    const emailRef = useRef();
    const mobileNoRef = useRef();

    const authContext = useContext(AuthContext);

    const loadUserInfo = useCallback(async () => {
        setLoading(true);
        const data = await UserService.GetProfile(authContext);
        if (data.isSuccess) {
            setLoading(false);
            setDisplayName(data.data.displayName);
            setEmail(data.data.email);
            setMobileNo(data.data.mobileNo);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    const onCancelHandler = async (event) => {
        navigate("/profile");
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        setDisplayNameIsValid(true);
        setEmailIsValid(true);
        setMobileNoIsValid(true);
        setDisplayName(displayNameRef.current.value);
        setEmail(emailRef.current.value);
        setMobileNo(mobileNoRef.current.value);

        if (!displayNameRef.current.value) {
            setDisplayNameIsValid(false);
            return false;
        }
        if (!emailRef.current.value) {
            setEmailIsValid(false);
            return false;
        }
        if (!mobileNoRef.current.value) {
            setMobileNoIsValid(false);
            return false;
        }
        if (displayNameIsValid && emailIsValid && mobileNoIsValid) {
            setShowEditProfile(false);
        }
    };

    const onOTPSubmitHandler = async (event) => {
        event.preventDefault();

        if (!codeValue) {
            setCodeIsValid(false);
            return false;
        }

        if (codeIsValid) {
            try {
                setLoading(true);
                const result = await UserService.VerifyQrCode(codeValue, authContext);
                setLoading(false);
                if (result.isSuccess) {
                    if (onLoadValidation === false) {
                        setOnLoadValidation(true);
                        setShowEditProfile(true);
                        setCodeValue(null);
                        loadUserInfo();
                    } else {
                        setLoading(true);
                        try {
                            let payload = [
                                { key: "DisplayName", Value: displayName },
                                { key: "Email", Value: email },
                                { key: "MobileNo", Value: mobileNo },
                            ];
                            let data = await UserService.UpdateProfile(payload, authContext);
                            if (data.isSuccess) {
                                navigate("/profile");
                            } else {
                                AlertService.error(data.errorMessage);
                            }
                            setLoading(false);
                        } catch (ex) {
                            console.log(ex);
                            setLoading(false);
                        }
                    }
                } else {
                    setLoading(false);
                    AlertService.error(result.errorMessage);
                }
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    };

    return (
        <Fragment>
            {!loading && (
                <div className="profile profile-container">
                    {showEditProfile && (
                        <div className="row m-0">
                            <div className="col-md-4 d-md-block d-none">
                                <h1>Edit Profile</h1>
                            </div>
                            <div className="col-md-8 col-sm-12 col-xs-12">
                                <form onSubmit={onSubmitHandler}>
                                    <h1 className="d-block d-md-none">Edit Profile</h1>
                                    <div className="row pt-5">
                                        <div className="col-md-2 col-sm-3 d-none d-sm-block profile-icon">
                                            <div>
                                                <img
                                                    className="display-name"
                                                    alt="Display Name"
                                                    src="assets/img/name.png"
                                                ></img>
                                            </div>
                                        </div>
                                        <div className="col-md-2 col-sm-3 col-xs-6 profile-name">Display</div>
                                        <div className="col-md-8 col-sm-6 profile-value">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Display Name"
                                                required
                                                maxLength="30"
                                                defaultValue={displayName}
                                                ref={displayNameRef}
                                                onChange={() => setDisplayNameIsValid(true)}
                                            />
                                            {!displayNameIsValid && (
                                                <p className="error">Please provide display name</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row pt-5">
                                        <div className="col-md-2 col-sm-3 d-none d-sm-block profile-icon">
                                            <div>
                                                <img className="email" alt="Email" src="assets/img/email.png"></img>
                                            </div>
                                        </div>
                                        <div className="col-md-2 col-sm-3 col-xs-6 profile-name">Email</div>
                                        <div className="col-md-8 col-sm-6 profile-value">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Email"
                                                required
                                                maxLength="30"
                                                defaultValue={email}
                                                ref={emailRef}
                                                onChange={() => setEmailIsValid(true)}
                                            />
                                            {!emailIsValid && <p className="error">Please provide email</p>}
                                        </div>
                                    </div>
                                    <div className="row pt-5">
                                        <div className="col-md-2 col-sm-3 d-none d-sm-block profile-icon">
                                            <div>
                                                <img
                                                    className="mobile"
                                                    alt="Mobile No"
                                                    src="assets/img/mobile.png"
                                                ></img>
                                            </div>
                                        </div>
                                        <div className="col-md-2 col-sm-3 col-xs-6 profile-name">Mobile No</div>
                                        <div className="col-md-8 col-sm-6 profile-value">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Mobile No"
                                                required
                                                maxLength="30"
                                                defaultValue={mobileNo}
                                                ref={mobileNoRef}
                                                onChange={() => setMobileNoIsValid(true)}
                                            />
                                            {!mobileNoIsValid && <p className="error">Please provide mobile no</p>}
                                        </div>
                                    </div>
                                    <div className="row pt-2">
                                        <div className="col-md-12 col-sm-12">
                                            <div className="text-center pt-4">
                                                <button type="submit" className="profile-button" disabled={loading}>
                                                    Submit
                                                </button>
                                                <button
                                                    type="button"
                                                    className="profile-cancel-button ml-3"
                                                    onClick={onCancelHandler}
                                                    disabled={loading}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                    {showEditProfile === false && (
                        <div className="profile-form text-center p-0">
                            <h1>Two Factor Verification</h1>
                            <form onSubmit={onOTPSubmitHandler}>
                                <div className="row m-0">
                                    <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                        <small>
                                            Please provide the One-Time Password code generated by <br />
                                            <b>Microsoft Authenticator</b> in the textbox below.
                                        </small>
                                    </div>
                                    <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                </div>
                                <div className="row m-0">
                                    <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 m-auto">
                                        <InputOtp
                                            onChange={(e) => {
                                                setCodeValue(e.value);
                                                setLoading(false);
                                            }}
                                            onKeyUp={() => setLoading(false)}
                                            length={6}
                                            disabled={loading}
                                            integerOnly
                                        />
                                    </div>
                                    <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                </div>
                                <div className="text-center pt-4">
                                    <button type="submit" className="profile-button" disabled={loading}>
                                        Verify
                                    </button>
                                </div>
                                {/* <div className="text-center pt-4">
                  <small>
                    I can't use my Microsoft Authenticator app right now
                  </small>
                </div>
                <div className="text-center pt-4">
                  <button
                    type="button"
                    className="profile-button"
                    disabled={loading}
                    onClick={onOTPHandler}
                  >
                    Send Code via Email
                  </button>
                </div> */}
                            </form>
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    );
};

export default EditProfile;
