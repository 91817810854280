import request from "./request";

const DocumentTypeService = {
    GetAll: async (context) => {
        return await request.sendRequest(`/documenttype/all`, context.token);
    },
    GetDocumentTypes: async (context) => {
        return await request.sendRequest(`/documenttype/all/types`, context.token);
    },
    Get: async (id, context) => {
        return await request.sendRequest(`/documenttype/${id}`, context.token);
    },
    Save: async (payload, context) => {
        return await request.sendFormDataRequest(`/documenttype`, payload, context);
    },
    Remove: async (id, context) => {
        return await request.sendDeleteRequest(`/documenttype/${id}`, context.token);
    },
};

export default DocumentTypeService;
