import "../app.css";
import "../masters/quiz/quiz.css";
import AlertService from "../../../services/alert.service";
import ReviewMCQExamService from "../../../services/reviewmcqexam.service";
import AuthContext from "../../../auth/auth-context";
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReviewMCQExamQuestion from "./reviewmcqquestion";

const ReviewMCQExam = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [assessmentExam, setAssessmentExam] = useState();
    const [questions, setQuestions] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState();
    const [selectedCardId, setSelectedCardId] = useState(0);
    const [isNavigated, setIsNavigated] = useState(false);
    const authContext = useContext(AuthContext);
    const scrollRef = useRef();

    const assessmentAccess = authContext.getPermissions("assessments");
    if (assessmentAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }

    const loadQuestions = useCallback(async () => {
        setLoading(true);
        const data = await ReviewMCQExamService.Get(params.id, authContext);
        if (data.isSuccess) {
            setLoading(false);
            setAssessmentExam(data.data);
            setQuestions(data.data.questions);
            if (data.data.questions.length > 0) {
                setSelectedCardId(data.data.questions[0].id);
            }
            setSelectedIndex(0);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext, params.id]);

    useEffect(() => {
        if (params.id) {
            loadQuestions();
        }
    }, [loadQuestions, authContext, params.id]);

    const onCardClick = (data, i) => {
        let preId = selectedCardId;
        setSelectedCardId(data.id);
        setSelectedIndex(i);
        if (preId === undefined || preId === null || preId === 0) {
            setTimeout(() => {
                if (scrollRef.current) {
                    scrollRef.current.scrollIntoView();
                }
            }, 10);
        }
    };

    const onCancelHandler = () => {
        navigate(`/assessments/${params.id}`);
    };

    const moveToPrevious = (e) => {
        setIsNavigated(true);
        setSelectedIndex((prev) => {
            if (prev > 0) {
                return prev - 1;
            } else {
                return 0;
            }
        });
    };

    const moveToNext = (e) => {
        setIsNavigated(true);
        setSelectedIndex((prev) => {
            if (prev === questions.length - 1) {
                return prev;
            } else {
                return prev + 1;
            }
        });
    };

    useEffect(() => {
        if (questions && questions.length > 0 && isNavigated) {
            setSelectedCardId(questions[selectedIndex].id);
            setTimeout(() => {
                if (scrollRef.current) {
                    scrollRef.current.scrollIntoView();
                }
            }, 10);
            setIsNavigated(false);
        }
    }, [isNavigated, selectedIndex, questions]);

    return (
        <Fragment>
            <div className="container-fluid question-container">
                <div className="row d-none d-md-flex">
                    <div className="col-md-2 col-sm-4">
                        <center className="quiz-left-title">MCQ</center>
                        <div className="incident-list incident-v-scroll border">
                            <div className="row m-0">
                                {questions &&
                                    questions.length > 0 &&
                                    questions.map((q, i) => (
                                        <div
                                            key={i}
                                            className="col-12"
                                            ref={q.id === selectedCardId ? scrollRef : null}
                                        >
                                            <div
                                                className={`quiz-card p-1 text-center cursor-pointer ${
                                                    q.id === selectedCardId ? "active" : ""
                                                } ${q.isCorrectAnswer ? "list-correct-answer" : "list-wrong-answer"}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    onCardClick(q, i);
                                                }}
                                            >
                                                <div
                                                    className={`name ${
                                                        q.isCorrectAnswer ? "list-correct-answer" : "list-wrong-answer"
                                                    }`}
                                                    title={q.question}
                                                >
                                                    {q.isCorrectAnswer && <i className="fa fa-check mr-3"></i>}
                                                    {q.isCorrectAnswer === false && (
                                                        <i className="fa fa-times mr-3"></i>
                                                    )}
                                                    Question {i + 1}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 col-sm-8 view">
                        <div className="row m-0">
                            <div className="col-4 course-title"></div>
                            <div className="col-8 course-title">
                                <button type="button" className="btn-incident-back" onClick={onCancelHandler}>
                                    <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                                </button>
                                <span className="quiz-title">{assessmentExam && assessmentExam.assessmentName}</span>
                            </div>
                        </div>
                        {!loading &&
                            questions &&
                            questions.length > 0 &&
                            questions.map((m, i) => (
                                <ReviewMCQExamQuestion
                                    key={i}
                                    index={i}
                                    input={m}
                                    selectedIndex={selectedIndex}
                                ></ReviewMCQExamQuestion>
                            ))}
                        {loading && (
                            <div className="row m-0 pl-3 pr-3">
                                <div className="col-12">Loading...</div>
                            </div>
                        )}
                        {!loading && questions && questions.length > 0 && (
                            <div className="row pt-1">
                                <div className="col-6">
                                    {selectedIndex > 0 && (
                                        <button type="button" className="submit-button" onClick={moveToPrevious}>
                                            Move to Previous Question
                                        </button>
                                    )}
                                </div>
                                <div className="col-6">
                                    {selectedIndex < questions.length - 1 && (
                                        <button
                                            type="button"
                                            className="float-right mr-3 submit-button"
                                            onClick={moveToNext}
                                        >
                                            Move to Next Question
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ReviewMCQExam;
