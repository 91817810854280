import { Fragment, useContext, useState } from "react";
import AuthContext from "../../../auth/auth-context";
import UserService from "../../../services/user.service";
import AlertService from "../../../services/alert.service";
import "../identity.css";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

const UploadLearners = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState();
    const [buttonText, setButtonText] = useState("Upload");

    const authContext = useContext(AuthContext);

    const assessmentAccess = authContext.getPermissions("learners");

    if (assessmentAccess.filter((f) => f.access.includes(1) && f.access.includes(2)).length === 0) {
        navigate("/");
    }

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        setLoading(true);
        setButtonText("Processing");
        const formData = new FormData();

        formData.append("file", file);
        formData.append("filename", file.name);
        try {
            let data = await UserService.Import(formData, authContext);
            if (data.isSuccess) {
                AlertService.success("Learners are imported successfully!");
            } else {
                AlertService.error(data.errorMessage);
            }
        } catch {}
        setButtonText("Upload");
        setLoading(false);
    };

    const handleChange = (event) => {
        setFile(event.target.files[0]);
    };

    return (
        <Fragment>
            <div className="container-fluid">
                <h2>Upload Users</h2>
                <div className="row m-0 pt-3">
                    <div className="col-3 text-right m-auto">Select Excel File</div>
                    <div className="col-9">
                        <input type="file" className="form-control" onChange={handleChange}></input>
                    </div>
                </div>
                <div className="row pt-2 mb-3">
                    <div className="col-md-12 col-sm-12">
                        <div className="text-center pt-4">
                            <Button label={buttonText} loading={loading} onClick={onSubmitHandler} />
                            {/* <button
                type="button"
                className="submit-button"
                onClick={onSubmitHandler}
                disabled={loading}
                loading={loading}
              >
                Upload
              </button> */}
                            <button
                                type="button"
                                className="cancel-button ml-3"
                                onClick={() => navigate("/learners")}
                                disabled={loading}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default UploadLearners;
