import AlertService from "../../../services/alert.service";
import AuthContext from "../../../auth/auth-context";
import { Fragment, useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginService from "../../../services/login.service";

const ChangePassword = () => {
    const navigate = useNavigate();
    const [curPasswordIsValid, setCurPasswordIsValid] = useState(true);
    const [passwordIsValid, setPasswordIsValid] = useState(true);
    const [newPasswordIsValid, setNewPasswordIsValid] = useState(true);
    const [loading, setLoading] = useState(false);

    const curPasswordRef = useRef();
    const newPasswordRef = useRef();
    const confirmPasswordRef = useRef();

    const authContext = useContext(AuthContext);

    const onCancelHandler = async (event) => {
        navigate("/profile");
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        const curPasswordValue = curPasswordRef.current.value;
        const passwordValue = newPasswordRef.current.value;
        const newPasswordValue = confirmPasswordRef.current.value;

        if (!curPasswordValue) {
            setCurPasswordIsValid(false);
            return false;
        }
        if (!passwordValue) {
            setPasswordIsValid(false);
            return false;
        }
        if (!newPasswordValue) {
            setNewPasswordIsValid(false);
            return false;
        }
        if (passwordValue !== newPasswordValue) {
            setNewPasswordIsValid(false);
            return false;
        }
        const payload = {
            password: curPasswordValue,
            newPassword: passwordValue,
        };
        if (newPasswordIsValid) {
            try {
                setLoading(true);
                const result = await LoginService.ChangePassword(payload, authContext);
                setLoading(false);
                if (result.isSuccess) {
                    navigate("/logout");
                } else {
                    setLoading(false);
                    AlertService.error(result.errorMessage);
                }
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    };

    return (
        <Fragment>
            {!loading && (
                <div className="identity-container no-background">
                    <div className="identity-wrapper">
                        <div className="identity-form">
                            <center>
                                <h1 className="mb-2">Change Password 🔒</h1>
                                <form className="change-password" onSubmit={onSubmitHandler}>
                                    <div className="row m-0">
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                        <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                            <div className="input-container-password">
                                                <input
                                                    type="password"
                                                    id="cpwd"
                                                    placeholder="Current Password"
                                                    name="cpswd"
                                                    required
                                                    maxLength="25"
                                                    ref={curPasswordRef}
                                                    onChange={() => setLoading(false)}
                                                    onKeyUp={() => setLoading(false)}
                                                />
                                            </div>
                                            {!curPasswordIsValid && (
                                                <p className="error">Please enter current password</p>
                                            )}
                                        </div>
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                        <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                            <div className="input-container-password">
                                                <input
                                                    type="password"
                                                    id="pwd"
                                                    placeholder="New Password"
                                                    name="pswd"
                                                    required
                                                    maxLength="25"
                                                    ref={newPasswordRef}
                                                    onChange={() => setLoading(false)}
                                                    onKeyUp={() => setLoading(false)}
                                                />
                                            </div>
                                            {!passwordIsValid && <p className="error">Please enter password</p>}
                                        </div>
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                        <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                            <div className="input-container-password">
                                                <input
                                                    autoFocus
                                                    type="password"
                                                    id="npwd"
                                                    placeholder="Re-enter New Password"
                                                    name="npswd"
                                                    required
                                                    maxLength="25"
                                                    ref={confirmPasswordRef}
                                                    onChange={() => setLoading(false)}
                                                    onKeyUp={() => setLoading(false)}
                                                />
                                            </div>
                                            {!passwordIsValid && <p className="error">Please re-enter new password</p>}
                                        </div>
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    </div>
                                    <div className="text-center pt-4">
                                        <button type="submit" className="identity-button" disabled={loading}>
                                            Submit
                                        </button>
                                        <button
                                            type="button"
                                            className="identity-cancel-button ml-3"
                                            onClick={onCancelHandler}
                                            disabled={loading}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </center>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default ChangePassword;
