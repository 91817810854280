/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useContext } from "react";
import AuthContext from "../../auth/auth-context";

const Header = () => {
    const authContext = useContext(AuthContext);

    return (
        <Fragment>
            <div className="row pt-4 header">
                <div className="col-1 d-none d-md-block text-center">
                    <a href="#/">Home</a>
                </div>
                <div className="col-1 d-none d-md-block text-center">
                    <a href="#/">About Us</a>
                </div>
                <div className="col-2 d-none d-md-block text-center">
                    <a href="#/">Our Services</a>
                </div>
                <div className="col-1 d-none d-md-block text-center">
                    <a href="#/">Contact</a>
                </div>
                {authContext.isLoggedIn && (authContext.mfaSetupEnabled === false || authContext.mfaVerified) && (
                    <div className="col-1 d-none d-md-block text-center">
                        <a href="#/logout">Logout</a>
                    </div>
                )}
                <div
                    className={
                        authContext.isLoggedIn && (authContext.mfaSetupEnabled === false || authContext.mfaVerified)
                            ? "col-2 text-center"
                            : "col-3 text-center"
                    }
                ></div>
                <div className="col-xl-3 col-md-4 text-center">
                    <img src="cyberbay.png" alt="Cyber Bay" height="50px"></img>
                    <img src="logo.png" alt="Logo" height="34px" style={{ paddingLeft: "30px" }}></img>
                </div>
                <div className="col-2 d-none d-xl-block text-center"></div>
            </div>
        </Fragment>
    );
};

export default Header;
