import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import DocumentService from "../../../services/document.service";
import AuthContext from "../../../auth/auth-context";
import DocumentViewer from "./viewer";
import AlertService from "../../../services/alert.service";

const Attachment = ({ no, attachment, clearSelection, title }) => {
    no++;
    const authContext = useContext(AuthContext);
    const [pdfData, setPDFData] = useState();
    const [contentType, setContentType] = useState();
    const [loading, setLoading] = useState(false);

    const downloadFile = useCallback(async () => {
        setLoading(true);
        const data = await DocumentService.GetFile(attachment.id, authContext);
        if (data.isSuccess) {
            // setPDFData(`data:${data.data.contentType};base64,${data.data.filePath}`);
            setContentType(data.data.contentType);
            setPDFData(data.data.filePath);
        } else {
            AlertService.error(data.errorMessage);
        }
        setLoading(false);
    }, [attachment.id, authContext]);

    useEffect(() => {
        downloadFile();
    }, [downloadFile, authContext]);

    return (
        <Fragment>
            {loading === false && (
                <div className="attachment">
                    {pdfData && (
                        <DocumentViewer
                            src={pdfData}
                            contentType={contentType}
                            title={title}
                            clearSelection={clearSelection}
                        ></DocumentViewer>
                    )}
                </div>
            )}
        </Fragment>
    );
};

export default Attachment;
