import request from "./request";

const CourseService = {
    GetAll: async (context) => {
        return await request.sendRequest(`/course/all`, context.token);
    },
    GetCourses: async (context) => {
        return await request.sendRequest(`/course/all/courses`, context.token);
    },
    Get: async (id, context) => {
        return await request.sendRequest(`/course/${id}`, context.token);
    },
    GetAttachments: async (id, searchText, context) => {
        return await request.sendRequest(`/course/attachments/${id}/${searchText}`, context.token);
    },
    GetIncidents: async (id, searchText, context) => {
        return await request.sendRequest(`/course/incidents/${id}/${searchText}`, context.token);
    },
    GetMappedCourses: async (context) => {
        return await request.sendRequest(`/vm/all/courses`, context.token);
    },
    GetUsers: async (id, searchText, context) => {
        return await request.sendRequest(`/course/users/${id}/${searchText}`, context.token);
    },
    GetCourseUsers: async (id, context) => {
        return await request.sendRequest(`/course/mapped/users/${id}`, context.token);
    },
    GetUserCourses: async (id, context) => {
        return await request.sendRequest(`/course/courses/${id}`, context.token);
    },
    GetUserVMs: async (id, context) => {
        return await request.sendRequest(`/vm/all/${id}`, context.token);
    },
    GetFile: async (id, context) => {
        return await request.sendRequest(`/course/file/${id}`, context.token);
    },
    SaveUserVM: async (payload, context) => {
        return await request.sendPostRequest(`/vm`, payload, context);
    },
    ImportUserVM: async (id, payload, context) => {
        return await request.sendFormDataRequest(`/vm/import/${id}`, payload, context);
    },
    Save: async (payload, context) => {
        return await request.sendPostRequest(`/course`, payload, context);
    },
    SaveIncidents: async (payload, context) => {
        return await request.sendPostRequest(`/course/incidents`, payload, context);
    },
    SaveUsers: async (payload, context) => {
        return await request.sendPostRequest(`/course/users`, payload, context);
    },
    SaveUser: async (payload, context) => {
        return await request.sendPostRequest(`/course/user`, payload, context);
    },
    Remove: async (id, context) => {
        return await request.sendDeleteRequest(`/course/${id}`, context.token);
    },
    RemoveUserVM: async (id, context) => {
        return await request.sendDeleteRequest(`/vm/${id}`, context.token);
    },
};

export default CourseService;
