import request from "./request";

const VMService = {
    startVM: async (vmName, context) => {
        return await request.sendRequest(`/vm/start/${vmName}`, context.token);
    },
    stopVM: async (vmName, context) => {
        return await request.sendRequest(`/vm/stop/${vmName}`, context.token);
    },
    statusVM: async (vmName, context) => {
        return await request.sendRequest(`/vm/status/${vmName}`, context.token);
    },
};

export default VMService;
