import "../../app.css";
import AlertService from "../../../../services/alert.service";
import AssessmentService from "../../../../services/assessment.service";
import CourseService from "../../../../services/course.service";
import AuthContext from "../../../../auth/auth-context";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import MCQService from "../../../../services/mcq.service";

const ViewAssessment = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState([]);
    const times = [
        { value: 5, label: "5 mins" },
        { value: 10, label: "10 mins" },
        { value: 15, label: "15 mins" },
        { value: 20, label: "20 mins" },
        { value: 25, label: "25 mins" },
        { value: 30, label: "30 mins" },
        { value: 35, label: "35 mins" },
        { value: 40, label: "40 mins" },
        { value: 45, label: "45 mins" },
        { value: 50, label: "50 mins" },
        { value: 55, label: "55 mins" },
        { value: 60, label: "60 mins" },
        { value: 65, label: "65 mins" },
        { value: 70, label: "70 mins" },
        { value: 75, label: "75 mins" },
        { value: 80, label: "80 mins" },
        { value: 85, label: "85 mins" },
        { value: 90, label: "90 mins" },
        { value: 95, label: "95 mins" },
        { value: 100, label: "100 mins" },
        { value: 105, label: "105 mins" },
        { value: 110, label: "110 mins" },
        { value: 115, label: "115 mins" },
        { value: 120, label: "120 mins" },
        { value: 125, label: "125 mins" },
        { value: 130, label: "130 mins" },
        { value: 135, label: "135 mins" },
        { value: 140, label: "140 mins" },
        { value: 145, label: "145 mins" },
        { value: 150, label: "150 mins" },
        { value: 155, label: "155 mins" },
        { value: 160, label: "160 mins" },
        { value: 165, label: "165 mins" },
        { value: 170, label: "170 mins" },
        { value: 175, label: "175 mins" },
        { value: 180, label: "180 mins" },
        { value: 185, label: "185 mins" },
        { value: 190, label: "190 mins" },
        { value: 195, label: "195 mins" },
        { value: 200, label: "200 mins" },
        { value: 205, label: "205 mins" },
        { value: 210, label: "210 mins" },
        { value: 215, label: "215 mins" },
        { value: 220, label: "220 mins" },
        { value: 225, label: "225 mins" },
        { value: 230, label: "230 mins" },
        { value: 235, label: "235 mins" },
        { value: 240, label: "240 mins" },
        { value: 245, label: "245 mins" },
        { value: 250, label: "250 mins" },
        { value: 255, label: "255 mins" },
        { value: 260, label: "260 mins" },
        { value: 265, label: "265 mins" },
        { value: 270, label: "270 mins" },
        { value: 275, label: "275 mins" },
        { value: 280, label: "280 mins" },
        { value: 285, label: "285 mins" },
        { value: 290, label: "290 mins" },
        { value: 295, label: "295 mins" },
        { value: 300, label: "300 mins" },
    ];

    const [assessmentId, setAssessmentId] = useState(0);
    const [assessmentName, setAssessmentName] = useState();
    const [courseId, setCourseId] = useState();
    const [mcq, setMCQ] = useState(false);
    const [reviewable, setReviewable] = useState(true);
    const [practical, setPractical] = useState(false);
    const [duration, setDuration] = useState(false);
    const [practicalDuration, setPracticalDuration] = useState();
    const [toBeProvided, setToBeProvided] = useState();
    const [totalPracticalMarks, setTotalPracticalMarks] = useState();

    const assessmentNameRef = useRef();

    const authContext = useContext(AuthContext);
    const assessmentAccess = authContext.getPermissions("assessmentsmaster");
    if (assessmentAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }
    const showAdd = assessmentAccess.filter((f) => f.access.includes(2)).length > 0;
    const showEdit = assessmentAccess.filter((f) => f.access.includes(3)).length > 0;
    const showDelete = assessmentAccess.filter((f) => f.access.includes(4)).length > 0;

    const loadCourses = useCallback(async () => {
        setLoading(true);
        const data = await CourseService.GetCourses(authContext);
        if (data.isSuccess) {
            setLoading(false);
            setCourses(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    useEffect(() => {
        loadCourses();
    }, [loadCourses, authContext]);

    const loadAssessment = useCallback(async () => {
        setLoading(true);
        const data = await AssessmentService.Get(params.id, authContext);
        if (data.isSuccess) {
            setLoading(false);
            setAssessmentName(data.data.assessmentName);
            setCourseId(data.data.courseId);
            setMCQ(data.data.mcq);
            setPractical(data.data.practical);
            setDuration(data.data.duration);
            setPracticalDuration(data.data.practicalDuration);
            setToBeProvided(data.data.toBeProvided);
            setReviewable(data.data.reviewAvailable);
            setTotalPracticalMarks(data.data.totalPracticalScore);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext, params.id]);

    useEffect(() => {
        if (params.id) {
            setAssessmentId(params.id);
            loadAssessment();
        }
    }, [loadAssessment, authContext, params.id]);

    const onCancelHandler = async (event) => {
        navigate("/master/assessments");
    };

    const onEditHandler = async (event) => {
        navigate(`/master/assessments/edit/${assessmentId}`);
    };

    const onConfirmMCQDeleteHandler = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Are you sure you want to delete all mcq questions?",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept: onMCQDeleteHandler,
        });
    };

    const onMCQDeleteHandler = async () => {
        let data = await MCQService.RemoveQuestions(assessmentId, authContext);
        if (data.isSuccess) {
            AlertService.success("Assessment MCQ questions are deleted successfully!");
        } else {
            AlertService.error(data.errorMessage);
        }
    };

    const onConfirmDeleteHandler = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Are you sure you want to delete this assessment?",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept: onDeleteHandler,
        });
    };

    const onDeleteHandler = async () => {
        let data = await AssessmentService.Remove(assessmentId, authContext);
        if (data.isSuccess) {
            AlertService.success("Assessment details are deleted successfully!");
            navigate("/master/assessments");
        } else {
            AlertService.error(data.errorMessage);
        }
    };

    return (
        <Fragment>
            {!loading && (
                <form>
                    <div className="row pt-4 catalogue">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Assessment Name</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Assessment Name"
                                maxLength="150"
                                disabled
                                defaultValue={assessmentName}
                                ref={assessmentNameRef}
                            />
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Course</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <Dropdown
                                value={courseId}
                                onChange={(e) => setCourseId(e.value)}
                                disabled
                                options={courses}
                                optionLabel="label"
                                placeholder="Select a course"
                                className="w-full"
                            />
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        <div className="col-md-2 col-sm-3 col-xs-12 label">Assessment Type</div>
                        <div className="col-md-8 col-sm-5 col-xs-12 value">
                            <div className="flex align-items-center">
                                <Checkbox
                                    inputId="mcqId"
                                    onChange={(e) => setMCQ(e.checked)}
                                    checked={mcq}
                                    disabled
                                ></Checkbox>
                                <label htmlFor="mcqId" className="ml-2 mt-1 mr-2">
                                    {" "}
                                    MCQ
                                </label>
                            </div>
                            <div className="flex align-items-center">
                                <Checkbox
                                    inputId="practicalId"
                                    onChange={(e) => setPractical(e.checked)}
                                    checked={practical}
                                    disabled
                                ></Checkbox>
                                <label htmlFor="practicalId" className="ml-2 mt-1">
                                    {" "}
                                    Practical
                                </label>
                            </div>
                        </div>
                        <div className="col-md-1 col-sm-2 d-xs-none"></div>
                    </div>
                    {mcq && (
                        <>
                            <div className="row pt-4">
                                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                                <div className="col-md-2 col-sm-3 col-xs-12 label">Questions to be Presented </div>
                                <div className="col-md-8 col-sm-5 col-xs-12 value">
                                    <InputNumber
                                        disabled
                                        inputId="integeronly"
                                        useGrouping={false}
                                        min={0}
                                        placeholder="Questions to be Presented"
                                        max={500}
                                        maxLength="3"
                                        format={false}
                                        value={toBeProvided}
                                        onValueChange={(e) => setToBeProvided(e.value)}
                                        className="w-full"
                                    />
                                </div>
                                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                                <div className="col-md-2 col-sm-3 col-xs-12 label">Duration </div>
                                <div className="col-md-8 col-sm-5 col-xs-12 value">
                                    <Dropdown
                                        disabled
                                        value={duration}
                                        onChange={(e) => setDuration(e.value)}
                                        options={times}
                                        optionLabel="label"
                                        placeholder="Select a duration"
                                        className="w-full"
                                    />
                                </div>
                                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                            </div>
                        </>
                    )}
                    {practical && (
                        <>
                            <div className="row pt-4">
                                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                                <div className="col-md-2 col-sm-3 col-xs-12 label">Total Practical Marks </div>
                                <div className="col-md-8 col-sm-5 col-xs-12 value">
                                    <InputNumber
                                        inputId="integeronly"
                                        useGrouping={false}
                                        min={0}
                                        placeholder="Total Practical Marks"
                                        max={500}
                                        maxLength="3"
                                        format={false}
                                        value={totalPracticalMarks}
                                        onValueChange={(e) => setTotalPracticalMarks(e.value)}
                                        className="w-full"
                                        disabled
                                    />
                                </div>
                                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                                <div className="col-md-2 col-sm-3 col-xs-12 label">Duration </div>
                                <div className="col-md-8 col-sm-5 col-xs-12 value">
                                    <Dropdown
                                        disabled
                                        value={practicalDuration}
                                        onChange={(e) => setPracticalDuration(e.value)}
                                        options={times}
                                        optionLabel="label"
                                        placeholder="Select a duration"
                                        className="w-full"
                                    />
                                </div>
                                <div className="col-md-1 col-sm-2 d-xs-none"></div>
                            </div>
                        </>
                    )}
                    {(mcq || practical) && (
                        <div className="row pt-4">
                            <div className="col-md-1 col-sm-2 d-xs-none"></div>
                            <div className="col-md-2 col-sm-3 col-xs-12 label"></div>
                            <div className="col-md-8 col-sm-5 col-xs-12 value">
                                <div className="flex align-items-center">
                                    <Checkbox
                                        disabled
                                        inputId="reviewableId"
                                        onChange={(e) => setReviewable(e.checked)}
                                        checked={reviewable}
                                    ></Checkbox>
                                    <label htmlFor="reviewableId" className="ml-2 mt-1 mr-2">
                                        Review Available
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 d-xs-none"></div>
                        </div>
                    )}
                    <div className="row pt-2 mb-3">
                        <div className="col-md-3 col-sm-5 d-xs-none"></div>
                        {mcq && (
                            <div className="col-2">
                                <div className="text-center">
                                    <div className="course-card">
                                        <span>MCQ</span>
                                        {(showAdd || showEdit) && (
                                            <div
                                                onClick={() => navigate(`/master/assessments/mcq/${assessmentId}`)}
                                                title={"Click here to create/edit MCQ."}
                                            >
                                                <i className="fa fa-cog"></i> Create / Edit
                                            </div>
                                        )}
                                        {showDelete && (
                                            <div
                                                className="mt-2 bg-danger"
                                                onClick={onConfirmMCQDeleteHandler}
                                                title={"Click here to remove MCQ."}
                                            >
                                                <i className="fa fa-trash"></i> Delete
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {practical && (
                            <div className="col-2">
                                <div className="text-center">
                                    <div className="course-card">
                                        <span>Practical</span>
                                        {(showAdd || showEdit) && (
                                            <div
                                                onClick={() =>
                                                    navigate(`/master/assessments/practical/${assessmentId}`)
                                                }
                                                title={"Click here to create/edit Practical."}
                                            >
                                                <i className="fa fa-cog"></i> Create / Edit
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="col-2">
                            <div className="text-center">
                                <div className="course-card">
                                    <span>Attendees</span>
                                    {(showAdd || showEdit) && mcq && (
                                        <div
                                            onClick={() =>
                                                navigate(`/master/assessments/attendees/mcq/${assessmentId}`)
                                            }
                                            title={"Click here to view attendees."}
                                        >
                                            <i className="fa fa-cog"></i> MCQ
                                        </div>
                                    )}
                                    {(showAdd || showEdit) && practical && (
                                        <div
                                            className={`${mcq ? "mt-2" : ""}`}
                                            onClick={() =>
                                                navigate(`/master/assessments/attendees/practical/${assessmentId}`)
                                            }
                                            title={"Click here to view attendees."}
                                        >
                                            <i className="fa fa-cog"></i> Practical
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {(showAdd || showEdit) && (mcq || practical) && (
                            <div className="col-2">
                                <div className="text-center">
                                    <div className="course-card">
                                        <span>Sessions</span>
                                        {mcq && (
                                            <div
                                                onClick={() =>
                                                    navigate(`/master/assessments/sessions/mcq/${assessmentId}`)
                                                }
                                                title={"Click here to view sessions."}
                                            >
                                                <i className="fa fa-cog"></i> MCQ
                                            </div>
                                        )}
                                        {practical && (
                                            <div
                                                className={`${mcq ? "mt-2" : ""}`}
                                                onClick={() =>
                                                    navigate(`/master/assessments/sessions/practical/${assessmentId}`)
                                                }
                                                title={"Click here to view practical sessions."}
                                            >
                                                <i className="fa fa-cog"></i> Practical
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="row pt-2 mb-3">
                        <div className="col-md-12 col-sm-12">
                            <div className="text-center pt-4">
                                {showEdit && assessmentId > 0 && (
                                    <button
                                        type="button"
                                        className="submit-button"
                                        onClick={onEditHandler}
                                        disabled={loading}
                                    >
                                        Edit
                                    </button>
                                )}
                                {showDelete && assessmentId > 0 && (
                                    <>
                                        <ConfirmPopup />
                                        <button
                                            type="button"
                                            className="remove-button ml-3"
                                            onClick={onConfirmDeleteHandler}
                                            disabled={loading}
                                        >
                                            Delete
                                        </button>
                                    </>
                                )}
                                <button
                                    type="button"
                                    className="cancel-button ml-3"
                                    onClick={onCancelHandler}
                                    disabled={loading}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Fragment>
    );
};

export default ViewAssessment;
