import { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import AlertService from "../../services/alert.service";

const Alert = () => {
    const toast = useRef(null);

    useEffect(() => {
        // subscribe to new alert notifications
        const subscription = AlertService.alert.subscribe((alert) => toast.current.show(alert));

        // unsubscribe when the component unmounts
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        // clear alert on location change
        AlertService.clear();
    }, []);

    if (!alert) return null;

    return <Toast ref={toast}></Toast>;
};

export default Alert;
