import { useParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import UserService from "../../../services/user.service";
import Header from "../header";
import "../identity.css";

const ApproveUser = () => {
    const params = useParams();

    const [loading, setLoading] = useState(false);
    const [statusText, setStatusText] = useState("User approved successfully.");

    useEffect(() => {
        setLoading(true);
        const approve = async () => {
            let results = await UserService.ApproveUser(params.code);
            if (results.isSuccess === false) {
                setStatusText(results.errorMessage);
            }
            setLoading(false);
        };
        approve();
    }, [params]);

    return (
        <Fragment>
            <div className="identity-container no-background">
                <Header />
                <div className="row identity-wrapper">
                    {loading === false && <div className="col-12 text-center label">{statusText}</div>}
                    {loading && (
                        <div className="col-12 text-center mt-auto label">
                            <i className="fa fa-spinner fa-spin fa-fw"></i> Approving...
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default ApproveUser;
