import request from "./request";

const QuizMCQService = {
    GetAll: async (id, context) => {
        return await request.sendRequest(`/quizmcq/all/${id}`, context.token);
    },
    BulkSave: async (payload, context) => {
        return await request.sendPostRequest(`/quizmcq/bulk`, payload, context);
    },
    ImportMCQ: async (id, payload, context) => {
        return await request.sendFormDataRequest(`/quizmcq/import/${id}`, payload, context);
    },
    Save: async (payload, context) => {
        return await request.sendPostRequest(`/quizmcq`, payload, context);
    },
    Remove: async (id, context) => {
        return await request.sendDeleteRequest(`/quizmcq/${id}`, context.token);
    },
};

export default QuizMCQService;
