import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import Header from "../header";
import LoginService from "../../../services/login.service";
import AlertService from "../../../services/alert.service";
import "../identity.css";

const ResetPassword = () => {
    const navigate = useNavigate();
    const params = useParams();

    const [ansIsValid, setAnsIsValid] = useState(true);
    const [newPasswordIsValid, setNewPasswordIsValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [questionsAsked, setQuestionsAsked] = useState(false);

    const loadQuestions = useCallback(async () => {
        let result = await LoginService.GetQuestions(params.code);
        if (result.isSuccess) {
            setIsExpired(false);
            setQuestions(result.data);
            if (result.data.length === 0) {
                setQuestionsAsked(true);
            }
        } else {
            setIsExpired(true);
        }
    }, [params]);

    const ans1Ref = useRef();
    const ans2Ref = useRef();
    const newPasswordRef = useRef();
    const confirmPasswordRef = useRef();
    let newPasswordValue, confirmPasswordValue;

    useEffect(() => {
        loadQuestions();
    }, [loadQuestions]);

    const onQSubmitHandler = async (event) => {
        setAnsIsValid(true);
        event.preventDefault();
        const ans1Value = ans1Ref.current.value;
        const ans2Value = ans2Ref.current.value;

        if (!ans1Value) {
            setAnsIsValid(false);
            return false;
        }

        if (!ans2Value) {
            setAnsIsValid(false);
            return false;
        }

        if (ansIsValid) {
            try {
                setLoading(true);
                let payload = [
                    { questionId: questions[0].id, answer: ans1Value },
                    { questionId: questions[1].id, answer: ans2Value },
                ];
                const result = await LoginService.ValidateSQ(params.code, payload);
                setLoading(false);
                if (result.isSuccess) {
                    setLoading(false);
                    setQuestionsAsked(true);
                } else {
                    setLoading(false);
                    AlertService.error(result.errorMessage);
                }
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        newPasswordValue = newPasswordRef.current.value;
        confirmPasswordValue = newPasswordRef.current.value;

        if (!newPasswordValue) {
            setNewPasswordIsValid(false);
            return false;
        }
        if (newPasswordValue !== confirmPasswordValue) {
            setNewPasswordIsValid(false);
            return false;
        }
        const payload = {
            Key: params.code,
            Value: newPasswordValue,
        };

        if (newPasswordIsValid) {
            try {
                setLoading(true);
                const result = await LoginService.SetPassword(payload);
                setLoading(false);
                if (result.isSuccess) {
                    AlertService.success("Your password has been changed successfully!");
                    setLoading(false);
                    navigate("login");
                } else {
                    setLoading(false);
                    AlertService.error(result.errorMessage);
                }
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    };

    return (
        <Fragment>
            <div className="identity-container">
                <Header />
                <div className="row identity-wrapper">
                    <div className="col-xl-6 col-lg-12">
                        {!isExpired && !questionsAsked && (
                            <div className="identity-form">
                                <h1 className="mb-2">Security Questions</h1>
                                <form onSubmit={onQSubmitHandler}>
                                    <div className="row m-0">
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                        <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                            <small>Security Question 1</small>
                                            <br></br>
                                            {questions && questions.length && (
                                                <span>
                                                    <b>{questions[0].question}</b>
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                        <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                            <div className="input-container-password">
                                                <input
                                                    autoFocus
                                                    type="password"
                                                    id="pwd1"
                                                    className="mfaq-textbox"
                                                    placeholder="Answer here"
                                                    name="pswd1"
                                                    maxLength="30"
                                                    required
                                                    ref={ans1Ref}
                                                    onChange={() => setLoading(false)}
                                                    onKeyUp={() => setLoading(false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                        <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                            <small>Security Question 2</small>
                                            <br></br>
                                            {questions && questions.length && (
                                                <span>
                                                    <b>{questions[1].question}</b>
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                        <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                            <div className="input-container-password">
                                                <input
                                                    type="password"
                                                    id="pwd2"
                                                    className="mfaq-textbox"
                                                    placeholder="Answer here"
                                                    name="pswd2"
                                                    required
                                                    maxLength="30"
                                                    ref={ans2Ref}
                                                    onChange={() => setLoading(false)}
                                                    onKeyUp={() => setLoading(false)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    </div>
                                    <div className="text-center pt-4">
                                        <button type="submit" className="identity-button" disabled={loading}>
                                            Verify
                                        </button>
                                    </div>
                                </form>
                            </div>
                        )}
                        {!isExpired && questionsAsked && (
                            <div className="identity-form">
                                <h1>RESET PASSWORD 🔒</h1>
                                <form onSubmit={onSubmitHandler}>
                                    <div className="row m-0">
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                        <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                            <div className="input-container-password">
                                                <input
                                                    type="password"
                                                    id="npwd"
                                                    placeholder="New password"
                                                    name="npwd"
                                                    maxLength="25"
                                                    required
                                                    ref={newPasswordRef}
                                                    onChange={() => setLoading(false)}
                                                    onKeyUp={() => setLoading(false)}
                                                />
                                            </div>
                                            {!newPasswordIsValid && <p className="error">Please enter new password</p>}
                                        </div>
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                        <div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 mb-3 mt-2 input-icons">
                                            <div className="input-container-password">
                                                <input
                                                    type="password"
                                                    id="cnpwd"
                                                    placeholder="Re-enter New Password"
                                                    name="cnpwd"
                                                    maxLength="25"
                                                    required
                                                    ref={confirmPasswordRef}
                                                    onChange={() => setLoading(false)}
                                                    onKeyUp={() => setLoading(false)}
                                                />
                                            </div>
                                            {!newPasswordIsValid && (
                                                <p className="error">Please re-enter new password</p>
                                            )}
                                        </div>
                                        <div className="col-xl-3 d-md-none d-sm-none d-xs-none"></div>
                                    </div>
                                    <div className="text-center pt-4">
                                        <button type="submit" className="identity-button" disabled={loading}>
                                            Set Password
                                        </button>
                                    </div>
                                </form>
                            </div>
                        )}
                        {isExpired && (
                            <div className="row m-0">
                                <div className="col-12 text-center">The link you followed has expired.</div>
                            </div>
                        )}
                    </div>
                    <div className="col-lg-6 d-none d-xl-block identity-image">
                        <img alt="Reset" src="assets/img/login.png"></img>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ResetPassword;
