import request from "./request";

const ReportService = {
    getQuizReport: async (id, context) => {
        return await request.sendRequest(`/quizreport/all/${id ?? 0}`, context.token);
    },
    getAssessmentReport: async (id, context) => {
        return await request.sendRequest(`/assessmentreport/all/${id ?? 0}`, context.token);
    },
    getAssessmentQuestionReport: async (id, context) => {
        return await request.sendRequest(`/assessmentreport/all/questions/${id ?? 0}`, context.token);
    },
    getAssessmentPracticalReport: async (id, context) => {
        return await request.sendRequest(`/assessmentreport/all/practical/${id ?? 0}`, context.token);
    },
    getIncidentUserReport: async (context) => {
        return await request.sendRequest(`/incidentreport`, context.token);
    },
    getIncidentMISReport: async (id, context) => {
        return await request.sendRequest(`/incidentreport/mis/${id}`, context.token);
    },
};

export default ReportService;
