/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useContext } from "react";
import AuthContext from "../auth/auth-context";
import { useNavigate } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import UserService from "../services/user.service";
import AlertService from "../services/alert.service";

const Banner = (props) => {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const roleAccess = authContext.getPermissions("roles");
    const permissionAccess = authContext.getPermissions("permissions");
    const userAccess = authContext.getPermissions("roles");
    const agentAccess = authContext.getPermissions("agents");
    const agentLearners = authContext.getPermissions("learners");
    const agentCategory = authContext.getPermissions("category");
    const docTypeCategory = authContext.getPermissions("documenttypes");
    const assessmentCategory = authContext.getPermissions("assessmentsmaster");
    const quizCategory = authContext.getPermissions("quizmaster");

    const showRoles = roleAccess.filter((f) => f.access.includes(1)).length > 0;
    const showPermissions = permissionAccess.filter((f) => f.access.includes(1)).length > 0;
    const showUsers = userAccess.filter((f) => f.access.includes(1)).length > 0;
    const showAgents = agentAccess.filter((f) => f.access.includes(1)).length > 0;
    const showLearners = agentLearners.filter((f) => f.access.includes(1)).length > 0;
    const showCategory = agentCategory.filter((f) => f.access.includes(1)).length > 0;
    const showDocType = docTypeCategory.filter((f) => f.access.includes(1)).length > 0;
    const showAssessment = assessmentCategory.filter((f) => f.access.includes(1)).length > 0;
    const showQuiz = quizCategory.filter((f) => f.access.includes(1)).length > 0;
    const showImpersonate = authContext.impersonate;
    const showSettings =
        showRoles ||
        showPermissions ||
        showUsers ||
        showAgents ||
        showDocType ||
        showAssessment ||
        showQuiz ||
        showImpersonate;

    const onConfirmImpersonateHandler = (event) => {
        event.stopPropagation();
        confirmPopup({
            target: event.currentTarget,
            message: "Do you want to Rollback to your original login?",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept: onImpersonateHandler,
        });
    };

    const onImpersonateHandler = async () => {
        let result = await UserService.RollbackUser(authContext.impersonateUser, authContext);
        if (result.isSuccess) {
            authContext.login(
                result.data.access_token,
                result.data.refresh_token,
                result.data.id,
                result.data.roleId,
                result.data.isAnswered,
                result.data.resetPassword,
                result.data.mfaSetupCompleted,
                result.data.mfaSetupEnabled,
                true,
                result.data.hasOngoingValidations,
                result.data.displayName,
                result.data.menuPermissions,
                result.data.course_count,
                result.data.impersonate,
                result.data.impersonatedUserId
            );
            authContext.setPermissions(result.data.menuPermissions);
        } else {
            AlertService.error(result.errorMessage);
        }
    };

    return (
        <Fragment>
            <div className={`row m-0 pt-3 header ${props.className}`}>
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <img
                        src="logo.png"
                        alt="Logo"
                        height="34px"
                        className="cursor-pointer"
                        onClick={() => navigate("/")}
                    ></img>
                    <img
                        src="cyberbay.png"
                        alt="Cyber Bay"
                        height="50px"
                        className="cursor-pointer"
                        onClick={() => navigate("/")}
                        style={{ paddingLeft: "30px" }}
                    ></img>
                    <span className="greeting-text">
                        Welcome, {authContext.displayName}
                        {authContext.impersonateUser != null && (
                            <button className="btn btn-impersonate" onClick={onConfirmImpersonateHandler}>
                                (Impersonated)
                            </button>
                        )}
                        .
                        <ConfirmPopup />
                    </span>
                </div>
                {authContext.isLoggedIn && (authContext.mfaSetupEnabled === false || authContext.mfaVerified) && (
                    <div className="col-lg-4 col-md-4 d-none d-md-block d-lg-block text-right right-top-container menu">
                        <ul>
                            {showSettings && (
                                <li>
                                    <img src="assets/img/settings.svg" alt="Settings" className="img-settings"></img>{" "}
                                    Settings
                                    <ul>
                                        {showAgents && (
                                            <li className="link">
                                                <a href="#/agents">Agents</a>
                                            </li>
                                        )}
                                        {showRoles && (
                                            <li className="link">
                                                <a href="#/roles">Roles</a>
                                            </li>
                                        )}
                                        {showPermissions && (
                                            <li className="link">
                                                <a href="#/permissions">Permissions</a>
                                            </li>
                                        )}
                                        {showUsers && (
                                            <li className="link">
                                                <a href="#/users">Users</a>
                                            </li>
                                        )}
                                        {showLearners && (
                                            <li className="link">
                                                <a href="#/learners">Learners</a>
                                            </li>
                                        )}
                                        {showCategory && (
                                            <li className="link">
                                                <a href="#/categories">Categories</a>
                                            </li>
                                        )}
                                        {showDocType && (
                                            <li className="link">
                                                <a href="#/documenttypes">Document Types</a>
                                            </li>
                                        )}
                                        {showAssessment && (
                                            <li className="link">
                                                <a href="#/master/assessments">Assessment Master</a>
                                            </li>
                                        )}
                                        {showQuiz && (
                                            <li className="link">
                                                <a href="#/master/quizzes">Quiz Master</a>
                                            </li>
                                        )}
                                        {showImpersonate && (
                                            <li className="link">
                                                <a href="#/impersonateuser">Impersonate User</a>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            )}
                            {/* <li className="link">
                <a href='#/' title="Go to help page">
                  <img
                    src="assets/img/help.svg"
                    alt="Help"
                    className="img-settings"
                  ></img>{" "}
                  Help
                </a>
              </li> */}
                            <li className="link">
                                <a href="#/" title="Notifications">
                                    <img src="assets/img/alarm.svg" alt="Notifications" className="img-settings"></img>
                                </a>
                            </li>
                            <li className="link">
                                <a href="#/profile" title="Cart">
                                    <img
                                        title="Cart"
                                        src="assets/img/user.svg"
                                        alt="Profile"
                                        className="img-settings"
                                    ></img>
                                </a>
                            </li>
                            <li className="link">
                                <a href="#/logout" title="Cart">
                                    <img
                                        title="Cart"
                                        src="assets/img/logout.svg"
                                        alt="Logout"
                                        className="img-settings"
                                    ></img>
                                </a>
                            </li>
                            {/* <li>
                <img
                  src="assets/img/user.svg"
                  alt="Notifications"
                  className="img-settings"
                ></img>
                <ul>
                  <li className="link">
                    <a href="#/profile">Profile</a>
                  </li>
                  <li
                    className="link"
                    title={"Logout " + authContext.displayName}
                  >
                    <a href="#/logout">Logout</a>
                  </li>
                </ul>
              </li> */}
                        </ul>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default Banner;
