import { Fragment } from "react";

const ViewStep = ({ step }) => {
    return (
        <Fragment>
            <div className="row m-0">
                <div className="col-12" dangerouslySetInnerHTML={{ __html: step }}></div>
            </div>
        </Fragment>
    );
};

export default ViewStep;
