import { Fragment, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";

const MCQQuestion = ({ index, input, changeHandler, removeHandler }) => {
    const quizId = (input && input.quizId) ?? 0;
    const optionId1 = input && input.options[0].id;
    const optionId2 = input && input.options[1].id;
    const optionId3 = input && input.options[2].id;
    const optionId4 = input && input.options[3].id;
    const optionId5 = input && input.options[4].id;
    const [graceMark, setGraceMark] = useState(input && input.hasGraceMark);
    const [question, setQuestion] = useState(input && input.question);
    const [option1, setOption1] = useState(input && input.options[0].option);
    const [option2, setOption2] = useState(input && input.options[1].option);
    const [option3, setOption3] = useState(input && input.options[2].option);
    const [option4, setOption4] = useState(input && input.options[3].option);
    const [option5, setOption5] = useState(input && input.options[4].option);
    const [isAnswer1, setIsAnswer1] = useState(input && input.options[0].isAnswer);
    const [isAnswer2, setIsAnswer2] = useState(input && input.options[1].isAnswer);
    const [isAnswer3, setIsAnswer3] = useState(input && input.options[2].isAnswer);
    const [isAnswer4, setIsAnswer4] = useState(input && input.options[3].isAnswer);
    const [isAnswer5, setIsAnswer5] = useState(input && input.options[4].isAnswer);

    useEffect(() => {
        input.question = question;
        input.orderBy = index;
        input.hasGraceMark = graceMark;
        input.options = [
            {
                id: optionId1,
                option: option1,
                isAnswer: isAnswer1,
                orderBy: 1,
            },
            {
                id: optionId2,
                option: option2,
                isAnswer: isAnswer2,
                orderBy: 2,
            },
            {
                id: optionId3,
                option: option3,
                isAnswer: isAnswer3,
                orderBy: 3,
            },
            {
                id: optionId4,
                option: option4,
                isAnswer: isAnswer4,
                orderBy: 4,
            },
            {
                id: optionId5,
                option: option5,
                isAnswer: isAnswer5,
                orderBy: 5,
            },
        ];
        changeHandler(index, input);
    }, [
        index,
        graceMark,
        quizId,
        isAnswer1,
        isAnswer2,
        isAnswer3,
        isAnswer4,
        isAnswer5,
        option1,
        option2,
        option3,
        option4,
        option5,
        question,
    ]);

    return (
        <Fragment>
            <div className="row m-0 pl-3 pr-3">
                <div className="col-12">
                    <label>Question No: {index + 1}</label>
                </div>
            </div>
            <div className="row m-0 pl-3 pr-3">
                <div className="col-10">
                    <input
                        className="form-control question"
                        placeholder="Question"
                        defaultValue={question}
                        onChange={(e) => {
                            setQuestion(e.target.value);
                        }}
                    ></input>
                </div>
                <div className="col-2">
                    {index > 0 && (
                        <button
                            type="button"
                            title="Remove Question"
                            className="text-danger btn-icon"
                            onClick={() => removeHandler(index)}
                        >
                            <i className="pi pi-trash"></i>
                        </button>
                    )}
                </div>
            </div>
            <div className="row m-0 pl-3 pr-3">
                <div className="col-10">
                    <div className="p-inputgroup flex-1 pl-3">
                        <span className="p-inputgroup-addon">
                            <Checkbox
                                checked={isAnswer1}
                                onChange={(e) => {
                                    setIsAnswer1(e.target.checked);
                                    setIsAnswer2(false);
                                    setIsAnswer3(false);
                                    setIsAnswer4(false);
                                    setIsAnswer5(false);
                                }}
                            />
                        </span>
                        <InputText
                            placeholder="Option"
                            value={option1}
                            onChange={(e) => {
                                setOption1(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="row m-0 pl-3 pr-3">
                <div className="col-10">
                    <div className="p-inputgroup flex-1 pl-3">
                        <span className="p-inputgroup-addon">
                            <Checkbox
                                checked={isAnswer2}
                                onChange={(e) => {
                                    setIsAnswer2(e.target.checked);
                                    setIsAnswer1(false);
                                    setIsAnswer3(false);
                                    setIsAnswer4(false);
                                    setIsAnswer5(false);
                                }}
                            />
                        </span>
                        <InputText
                            placeholder="Option"
                            value={option2}
                            onChange={(e) => {
                                setOption2(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="row m-0 pl-3 pr-3">
                <div className="col-10">
                    <div className="p-inputgroup flex-1 pl-3">
                        <span className="p-inputgroup-addon">
                            <Checkbox
                                checked={isAnswer3}
                                onChange={(e) => {
                                    setIsAnswer3(e.target.checked);
                                    setIsAnswer1(false);
                                    setIsAnswer2(false);
                                    setIsAnswer4(false);
                                    setIsAnswer5(false);
                                }}
                            />
                        </span>
                        <InputText
                            placeholder="Option"
                            value={option3}
                            onChange={(e) => {
                                setOption3(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="row m-0 pl-3 pr-3">
                <div className="col-10">
                    <div className="p-inputgroup flex-1 pl-3">
                        <span className="p-inputgroup-addon">
                            <Checkbox
                                checked={isAnswer4}
                                onChange={(e) => {
                                    setIsAnswer4(e.target.checked);
                                    setIsAnswer1(false);
                                    setIsAnswer2(false);
                                    setIsAnswer3(false);
                                    setIsAnswer5(false);
                                }}
                            />
                        </span>
                        <InputText
                            placeholder="Option"
                            value={option4}
                            onChange={(e) => {
                                setOption4(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="row m-0 pl-3 pr-3">
                <div className="col-10">
                    <div className="p-inputgroup flex-1 pl-3">
                        <span className="p-inputgroup-addon">
                            <Checkbox
                                checked={isAnswer5}
                                onChange={(e) => {
                                    setIsAnswer5(e.target.checked);
                                    setIsAnswer1(false);
                                    setIsAnswer2(false);
                                    setIsAnswer3(false);
                                    setIsAnswer4(false);
                                }}
                            />
                        </span>
                        <InputText
                            placeholder="Option"
                            value={option5}
                            onChange={(e) => {
                                setOption5(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="row m-0 pl-3 pr-3">
                <div className="col-8"></div>
                <div className="col-2">
                    <div className="p-inputgroup flex-1 pl-3">
                        <span className="p-inputgroup-addon">
                            <Checkbox
                                inputId="graceMark"
                                checked={graceMark}
                                onChange={(e) => {
                                    setGraceMark(e.target.checked);
                                }}
                            />
                            <label htmlFor="graceMark" className="m-auto ml-3">
                                Has Grace Mark?
                            </label>
                        </span>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default MCQQuestion;
