import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Carousel } from "primereact/carousel";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import AuthContext from "../../../auth/auth-context";
import DocumentService from "../../../services/document.service";
import AlertService from "../../../services/alert.service";
import Card from "./card";
import View from "./view";

const Documents = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [selectedCardId, setSelectedCardId] = useState(0);
    const [selectedCardTitle, setSelectedCardTitle] = useState("");
    const [documents, setDocuments] = useState([]);
    const [groupedDocuments, setGroupedDocuments] = useState([]);
    const [document, setDocument] = useState({});
    const [searchText, setSearchText] = useState("");
    const authContext = useContext(AuthContext);
    const scrollRef = useRef();

    const responsiveOptions = [
        {
            breakpoint: "767px",
            numVisible: 3,
            numScroll: 1,
        },
        {
            breakpoint: "575px",
            numVisible: 2,
            numScroll: 1,
        },
    ];

    const documentAccess = authContext.getPermissions("documents");
    if (documentAccess.filter((f) => f.access.includes(1)).length === 0 || authContext.onGoingValidations) {
        navigate("/");
    }
    const showAdd = documentAccess.filter((f) => f.access.includes(2)).length > 0;
    const showEdit = documentAccess.filter((f) => f.access.includes(3)).length > 0;
    const showDelete = documentAccess.filter((f) => f.access.includes(4)).length > 0;

    const groupIncidents = (documents) => {
        let preGroupedData = Object.groupBy(documents, ({ documentType }) => documentType);
        let groupedData = [];
        for (let key in preGroupedData) {
            groupedData.push({ key, items: preGroupedData[key] });
        }
        return groupedData;
    };

    const loadDocuments = useCallback(async () => {
        setLoading(true);
        const data = await DocumentService.GetAll(searchText, authContext);
        if (data.isSuccess) {
            setLoading(false);
            let i = 0;
            data.data.forEach((element) => {
                i++;
                element.no = i;
            });
            setDocuments(data.data);
            setGroupedDocuments(groupIncidents(data.data));
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext, searchText]);

    const loadDocumentsByType = useCallback(async () => {
        setLoading(true);
        const data = await DocumentService.GetAllByTypes(params.typeid, searchText, authContext);
        if (data.isSuccess) {
            setLoading(false);
            let i = 0;
            data.data.forEach((element) => {
                i++;
                element.no = i;
            });
            setDocuments(data.data);
            setGroupedDocuments(groupIncidents(data.data));
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext, searchText, params.typeid]);

    useEffect(() => {
        if (params.typeid) {
            loadDocumentsByType();
        } else {
            loadDocuments();
        }
    }, [loadDocuments, loadDocumentsByType, searchText, params.typeid, authContext]);

    const onCardClick = async (id, name) => {
        let preId = selectedCardId;
        setSelectedCardId(id);
        setSelectedCardTitle(name);
        loadDocument(id);
        if (preId === undefined || preId === null || preId === 0) {
            setTimeout(() => {
                if (scrollRef.current) {
                    scrollRef.current.scrollIntoView();
                }
            }, 10);
        }
    };

    const loadDocument = useCallback(
        async (cardId) => {
            setLoading(true);
            const data = await DocumentService.Get(cardId, authContext);
            if (data.isSuccess) {
                setDocument(data.data);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            } else {
                setTimeout(() => {
                    setLoading(false);
                }, 500);
                AlertService.error(data.errorMessage);
            }
        },
        [authContext]
    );

    const cardTemplate = (document) => {
        return (
            <Card
                id={document.id}
                no={document.no}
                title={document.documentName}
                active={document.id === selectedCardId}
                clickHandler={() => onCardClick(document.id, document.documentName)}
            ></Card>
        );
    };

    const onEditHandler = async () => {
        navigate(`/documents/${params.typeid}/edit/${document.id}`);
    };

    const onAddHandler = async () => {
        navigate(`/documents/${params.typeid}/add`);
    };

    const onConfirmDeleteHandler = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Are you sure you want to delete the selected document?",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept: onDeleteHandler,
        });
    };

    const onDeleteHandler = async () => {
        let data = await DocumentService.Remove(selectedCardId, authContext);
        if (data.isSuccess) {
            setDocument(undefined);
            await loadDocuments();
            AlertService.success("Document details deleted successfully!");
        } else {
            AlertService.error(data.errorMessage);
        }
    };
    const clearSelection = async () => {
        setSelectedCardId(0);
        setDocument(null);
    };

    return (
        <Fragment>
            {selectedCardId === 0 && (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group has-search search-group catalogue-search">
                                <span className="fa fa-search form-control-feedback"></span>
                                <input
                                    type="text"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    className="form-control"
                                    placeholder="Search"
                                />
                                {searchText && (
                                    <span className="input-group-append">
                                        <button
                                            className="btn border-0"
                                            type="button"
                                            onClick={() => setSearchText("")}
                                        >
                                            <i className="fa fa-times"></i>
                                        </button>
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="col-3"></div>
                        <div className="col-6 course-title">
                            <button type="button" className="btn-incident-back" onClick={() => navigate("/doctypes")}>
                                <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                            </button>
                        </div>
                    </div>
                    {groupedDocuments &&
                        groupedDocuments.map((g, i) => (
                            <div key={i}>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <span className="btn-incidents-all btn-incidents-width">{g.key}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    {g.items &&
                                        g.items.map((document, i) => (
                                            <div key={i} className="col-lg-2 col-md-4 col-sm-6 col-12">
                                                <Card
                                                    no={document.no}
                                                    id={document.id}
                                                    title={document.documentName}
                                                    active={document.id === selectedCardId}
                                                    clickHandler={() => onCardClick(document.id, document.documentName)}
                                                ></Card>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ))}
                    {documents && documents.length === 0 && <div className="col-12">No documents available...</div>}
                </div>
            )}
            {selectedCardId > 0 && (
                <>
                    <div className="row d-none d-md-flex">
                        <div className="col-md-2 col-sm-4">
                            <div className="form-group has-search search-group catalogue-search">
                                <span className="fa fa-search form-control-feedback"></span>
                                <input
                                    type="text"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    className="form-control"
                                    placeholder="Search"
                                />
                                {searchText && (
                                    <span className="input-group-append">
                                        <button
                                            className="btn border-0"
                                            type="button"
                                            onClick={() => setSearchText("")}
                                        >
                                            <i className="fa fa-times"></i>
                                        </button>
                                    </span>
                                )}
                            </div>
                            <div className="incident-list incident-v-scroll">
                                <div className="row m-0">
                                    {groupedDocuments &&
                                        groupedDocuments.map((g, i) => (
                                            <div key={i}>
                                                <div className="col-12 text-center">
                                                    <span className="btn-incidents-all">{g.key}</span>
                                                </div>
                                                {g.items &&
                                                    g.items.map((document, i) => (
                                                        <div
                                                            key={i}
                                                            className="col-12"
                                                            ref={document.id === selectedCardId ? scrollRef : null}
                                                        >
                                                            <Card
                                                                no={document.no}
                                                                id={document.id}
                                                                title={document.documentName}
                                                                active={document.id === selectedCardId}
                                                                clickHandler={() =>
                                                                    onCardClick(document.id, document.documentName)
                                                                }
                                                            ></Card>
                                                        </div>
                                                    ))}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10 col-sm-8 view">
                            {!loading && <View clearSelection={clearSelection} document={document}></View>}
                            {loading && <div className="loader"></div>}
                        </div>
                    </div>
                    <div className="row d-md-none d-sm-flex d-flex h-carousel-container">
                        <Carousel
                            value={documents}
                            numVisible={2}
                            numScroll={1}
                            showIndicators={false}
                            responsiveOptions={responsiveOptions}
                            itemTemplate={cardTemplate}
                            circular
                            className="h-carousel"
                        />
                    </div>
                    <div className="row d-md-none d-sm-flex d-flex h-view">
                        {!loading && document.id === selectedCardId && (
                            <View clearSelection={clearSelection} document={document} title={selectedCardTitle}></View>
                        )}
                    </div>
                </>
            )}
            {showAdd && !loading && (
                <button type="button" onClick={onAddHandler} className="floating-button">
                    <i className="fa fa-plus"></i>
                </button>
            )}
            {selectedCardId > 0 && showEdit && !loading && (
                <button type="button" onClick={onEditHandler} className="floating-button mr-6">
                    <i className="fa fa-pencil"></i>
                </button>
            )}
            {selectedCardId > 0 && showDelete && (
                <>
                    <ConfirmPopup />
                    <button
                        type="button"
                        onClick={onConfirmDeleteHandler}
                        className="floating-button remove"
                        style={{ marginRight: "6rem" }}
                    >
                        <i className="fa fa-trash"></i>
                    </button>
                </>
            )}
        </Fragment>
    );
};

export default Documents;
