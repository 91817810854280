import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "primereact/carousel";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import AuthContext from "../../../auth/auth-context";
import CourseService from "../../../services/course.service";
import AlertService from "../../../services/alert.service";
import Card from "./card";
import View from "./view";

const Courses = () => {
    const [loading, setLoading] = useState(false);
    const [selectedCardId, setSelectedCardId] = useState(0);
    const [courses, setCourses] = useState([]);
    const [course, setCourse] = useState({});
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const responsiveOptions = [
        {
            breakpoint: "767px",
            numVisible: 3,
            numScroll: 1,
        },
        {
            breakpoint: "575px",
            numVisible: 2,
            numScroll: 1,
        },
    ];

    useEffect(() => {
        const courseAccess = authContext.getPermissions("courses");
        if (courseAccess.filter((f) => f.access.includes(1)).length === 0 || authContext.onGoingValidations) {
            navigate("/");
        }
        setShowAdd(courseAccess.filter((f) => f.access.includes(2)).length > 0);
        setShowEdit(courseAccess.filter((f) => f.access.includes(3)).length > 0);
        setShowDelete(courseAccess.filter((f) => f.access.includes(4)).length > 0);
    }, [navigate, authContext]);

    const loadCourses = useCallback(async () => {
        setLoading(true);
        const data = await CourseService.GetAll(authContext);
        if (data.isSuccess) {
            setLoading(false);
            let i = 0;
            data.data.forEach((element) => {
                i++;
                element.no = i;
            });
            setCourses(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    useEffect(() => {
        loadCourses();
    }, [loadCourses, authContext]);

    const onCardClick = async (id) => {
        setSelectedCardId(id);
        loadCourse(id);
    };

    const loadCourse = useCallback(
        async (cardId) => {
            setLoading(true);
            const data = await CourseService.Get(cardId, authContext);
            if (data.isSuccess) {
                setLoading(false);
                setCourse(data.data);
            } else {
                setLoading(false);
                AlertService.error(data.errorMessage);
            }
        },
        [authContext]
    );

    const cardTemplate = (course) => {
        return (
            <Card
                no={course.no}
                id={course.id}
                title={course.courseName}
                active={course.id === selectedCardId}
                clickHandler={() => onCardClick(course.id)}
            ></Card>
        );
    };

    const onEditHandler = async () => {
        navigate("/courses/edit/" + course.id);
    };

    const onAddHandler = async () => {
        navigate("/courses/add");
    };

    const onConfirmDeleteHandler = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Are you sure you want to delete the selected course?",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept: onDeleteHandler,
        });
    };

    const onDeleteHandler = async () => {
        let data = await CourseService.Remove(selectedCardId, authContext);
        if (data.isSuccess) {
            setCourse(undefined);
            await loadCourses();
            AlertService.success("Course details deleted successfully!");
        } else {
            AlertService.error(data.errorMessage);
        }
    };

    return (
        <Fragment>
            {selectedCardId === 0 && (
                <div className="container-fluid">
                    <div className="row">
                        {courses &&
                            !loading &&
                            courses.map((course, i) => (
                                <div key={i} className="col-lg-2 col-md-4 col-sm-6 col-12">
                                    <Card
                                        no={course.no}
                                        id={course.id}
                                        title={course.courseName}
                                        active={course.id === selectedCardId}
                                        clickHandler={onCardClick}
                                    ></Card>
                                </div>
                            ))}
                        {!loading && courses && courses.length === 0 && (
                            <div className="col-12">No courses available...</div>
                        )}
                        {loading && <div className="col-12">Loading...</div>}
                    </div>
                </div>
            )}
            {selectedCardId > 0 && !loading && (
                <>
                    <div className="row d-none d-md-flex">
                        <div className="col-md-2 col-sm-4">
                            <div className="incident-list">
                                <Carousel
                                    value={courses}
                                    numVisible={4}
                                    numScroll={1}
                                    circular={true}
                                    orientation="vertical"
                                    showIndicators={false}
                                    verticalViewPortHeight="calc(100vh - 170px)"
                                    itemTemplate={cardTemplate}
                                />
                            </div>
                        </div>
                        <div className="col-md-10 col-sm-8 view">{!loading && <View course={course}></View>}</div>
                    </div>
                    <div className="row d-md-none d-sm-flex d-flex h-carousel-container">
                        <Carousel
                            value={courses}
                            numVisible={2}
                            numScroll={1}
                            showIndicators={false}
                            responsiveOptions={responsiveOptions}
                            itemTemplate={cardTemplate}
                            circular
                            className="h-carousel"
                        />
                    </div>
                    <div className="row d-md-none d-sm-flex d-flex h-view">
                        {!loading && course && course.id === selectedCardId && (
                            <View course={course} showAdd={showAdd} showEdit={showEdit}></View>
                        )}
                    </div>
                </>
            )}
            {showAdd && (
                <button type="button" onClick={onAddHandler} className="floating-button">
                    <i className="fa fa-plus"></i>
                </button>
            )}
            {selectedCardId > 0 && showEdit && (
                <button type="button" onClick={onEditHandler} className="floating-button mr-6">
                    <i className="fa fa-pencil"></i>
                </button>
            )}
            {selectedCardId > 0 && showDelete && (
                <>
                    <ConfirmPopup />
                    <button
                        type="button"
                        onClick={onConfirmDeleteHandler}
                        className="floating-button remove"
                        style={{ marginRight: "6rem" }}
                    >
                        <i className="fa fa-trash"></i>
                    </button>
                </>
            )}
        </Fragment>
    );
};

export default Courses;
