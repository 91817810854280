import request from "./request";

const CatalogueService = {
    GetAll: async (searchText, context) => {
        return await request.sendRequest(`/incident/all/search/${searchText}`, context.token);
    },
    GetIncidents: async (searchText, context) => {
        return await request.sendRequest(`/incident/search/${searchText}`, context.token);
    },
    HasIncidents: async (context) => {
        return await request.sendRequest(`/incident/hasdata`, context.token);
    },
    GetAllByCourseId: async (id, searchText, context) => {
        return await request.sendRequest(`/incident/all/${id}/${searchText}`, context.token);
    },
    GetAllByCategoryId: async (id, searchText, context) => {
        return await request.sendRequest(`/incident/category/${id}/${searchText}`, context.token);
    },
    GetCategories: async (context) => {
        return await request.sendRequest(`/incident/all/categories`, context.token);
    },
    GetCategoryList: async (context) => {
        return await request.sendRequest(`/incident/all/category/list`, context.token);
    },
    Get: async (id, context) => {
        return await request.sendRequest(`/incident/${id}`, context.token);
    },
    MarkAsCompleted: async (id, context) => {
        return await request.sendPutRequest(`/incident/completed/${id}`, context);
    },
    MarkAsIncomplete: async (id, context) => {
        return await request.sendPutRequest(`/incident/incomplete/${id}`, context);
    },
    Save: async (payload, context) => {
        return await request.sendPostRequest(`/incident`, payload, context);
    },
    Remove: async (id, context) => {
        return await request.sendDeleteRequest(`/incident/${id}`, context.token);
    },
};

export default CatalogueService;
