import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../auth/auth-context";
import DocumentTypeService from "../../../services/documenttype.service";
import AlertService from "../../../services/alert.service";
import Card from "./card";

const DocTypes = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [searchedDocumentTypes, setSearchedDocumentTypes] = useState([]);
    const [searchText, setSearchText] = useState("");
    const authContext = useContext(AuthContext);

    useEffect(() => {
        const documentAccess = authContext.getPermissions("documents");
        if (documentAccess.filter((f) => f.access.includes(1)).length === 0 || authContext.onGoingValidations) {
            navigate("/");
        }
    }, [navigate, authContext]);

    const searchDocumentTypes = useCallback(async () => {
        setSearchedDocumentTypes(() => {
            return documentTypes.filter((f) => f.documentType.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
        });
    }, [documentTypes, searchText]);

    const loadDocumentTypes = useCallback(async () => {
        setLoading(true);
        const data = await DocumentTypeService.GetAll(authContext);
        if (data.isSuccess) {
            setLoading(false);
            let i = 0;
            data.data.forEach((element) => {
                i++;
                element.no = i;
            });
            setDocumentTypes(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext]);

    useEffect(() => {
        loadDocumentTypes();
    }, [loadDocumentTypes, authContext]);

    useEffect(() => {
        searchDocumentTypes();
    }, [searchDocumentTypes, documentTypes, searchText]);

    const onCardClick = async (id) => {
        navigate(`/documents/${id}`);
    };

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-3">
                        <div className="form-group has-search search-group catalogue-search">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input
                                type="text"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                className="form-control"
                                placeholder="Search"
                            />
                            {searchText && (
                                <span className="input-group-append">
                                    <button className="btn border-0" type="button" onClick={() => setSearchText("")}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="col-3"></div>
                    <div className="col-6 course-title">
                        <button type="button" className="btn-incident-back" onClick={() => navigate("/doctypes")}>
                            <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                        </button>
                        <a className="btn-incidents-all float-right" href="#/documents">
                            View All Documents
                        </a>
                    </div>
                </div>
                <div className="row">
                    {!loading &&
                        searchedDocumentTypes &&
                        searchedDocumentTypes.map((document, i) => (
                            <div key={i} className="col-lg-2 col-md-4 col-sm-6 col-12">
                                <Card id={document.id} title={document.documentType} clickHandler={onCardClick}></Card>
                            </div>
                        ))}
                    {!loading && documentTypes && documentTypes.length === 0 && (
                        <div className="col-12">No documents available...</div>
                    )}
                    {loading && <div className="col-12">Loading...</div>}
                </div>
            </div>
        </Fragment>
    );
};

export default DocTypes;
