import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import "../../app.css";
import "../quiz/quiz.css";
import AlertService from "../../../../services/alert.service";
import ReviewMCQExamService from "../../../../services/reviewmcqexam.service";
import MCQExamService from "../../../../services/mcqexam.service";
import AuthContext from "../../../../auth/auth-context";
import MCQExamQuestionView from "./mcqexamquestionview";

const MCQExamView = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [assessmentExam, setAssessmentExam] = useState();
    const [questions, setQuestions] = useState([]);
    const [visible, setVisible] = useState(false);
    const [reason, setReason] = useState();
    const authContext = useContext(AuthContext);

    const assessmentAccess = authContext.getPermissions("assessments");
    if (assessmentAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }

    const loadQuestions = useCallback(async () => {
        setLoading(true);
        const data = await ReviewMCQExamService.GetByExam(params.id, authContext);
        if (data.isSuccess) {
            setLoading(false);
            setAssessmentExam(data.data);
            setQuestions(data.data.questions ?? []);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext, params.id]);

    useEffect(() => {
        if (params.id) {
            loadQuestions();
        }
    }, [loadQuestions, authContext, params.id]);

    const onCancelHandler = () => {
        navigate(`/master/assessments/attendees/mcq/${assessmentExam.assessmentId}`);
    };

    const onCancelExam = async () => {
        if (!reason) {
            AlertService.error("Please provide reason for cancellation.");
        } else {
            let payload = {
                examId: assessmentExam.id,
                cancelReason: reason,
                cancelledBy: authContext.userId,
            };
            let result = await MCQExamService.Cancel(payload, authContext);
            if (result.isSuccess) {
                AlertService.success("Exam has been cancelled successfully.");
                onCancelHandler();
            } else {
                AlertService.error(result.errorMessage);
            }
        }
    };

    const RecalculateScore = async () => {
        let result = await MCQExamService.RecalculateScore(params.id, authContext);
        if (result.isSuccess) {
            AlertService.success("Score has been recalculated successfully.");
            loadQuestions();
        } else {
            AlertService.error(result.errorMessage);
        }
    };

    return (
        <Fragment>
            <div className="container-fluid question-container">
                <div className="row d-none d-md-flex">
                    <div className="col-md-12 col-sm-12 view">
                        <div className="row m-0">
                            <div className="col-9 course-title">
                                <span className="quiz-left-title">
                                    {assessmentExam && assessmentExam.assessmentName}, MCQ :{" "}
                                    {assessmentExam && assessmentExam.attendeeName},{" "}
                                    <small>
                                        Score :{" "}
                                        {assessmentExam && assessmentExam.correctMCQAnswers + assessmentExam.graceMarks}
                                        /{assessmentExam && assessmentExam.totalMCQQuestions},{" "}
                                        {assessmentExam && assessmentExam.mcqScore}%, Confident Score :{" "}
                                        {assessmentExam && assessmentExam.mcqConfidentScore}/
                                        {assessmentExam && assessmentExam.totalMCQQuestions}
                                    </small>
                                </span>
                            </div>
                            <div className="col-3 course-title">
                                <button type="button" className="btn-incident-back" onClick={onCancelHandler}>
                                    <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                                </button>
                                <button
                                    type="button"
                                    className="btn-incidents-all float-right"
                                    onClick={() => setVisible(true)}
                                >
                                    <span>Cancel Exam</span>
                                </button>
                                <button
                                    type="button"
                                    className="btn-incidents-all float-right mr-2"
                                    onClick={RecalculateScore}
                                >
                                    <span>Re-Calculate Score</span>
                                </button>
                            </div>
                        </div>
                        {!loading &&
                            questions &&
                            questions.length > 0 &&
                            questions.map((m, i) => (
                                <>
                                    <MCQExamQuestionView key={i} input={m}></MCQExamQuestionView>
                                    {i < questions.length - 1 && <hr />}
                                </>
                            ))}
                        {loading && (
                            <div className="row m-0 pl-3 pr-3">
                                <div className="col-12">Loading...</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Dialog
                header="Reason to cancel this Exam?"
                visible={visible}
                style={{ width: "50vw" }}
                onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                }}
            >
                <div className="row m-0 p-0">
                    <div className="col-12">
                        <textarea
                            className="form-control no-resize cancel-reason"
                            maxLength={250}
                            defaultValue={reason}
                            onChange={(e) => setReason(e.target.value)}
                        ></textarea>
                    </div>
                    <div className="col-12">
                        <button
                            className="cancel-button float-right ml-3"
                            onClick={() => {
                                setReason("");
                                setVisible(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button className="submit-button float-right ml-3" onClick={onCancelExam}>
                            Submit
                        </button>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    );
};

export default MCQExamView;
