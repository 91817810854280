import { Fragment } from "react";
import Moment from "moment";
import Attachment from "./attachment";

const ViewComment = ({ comment }) => {
    return (
        <Fragment>
            <div className="comment">
                <div className="row m-0 border-bottom">
                    <div className="col-6">
                        <span>Commented by : </span>
                        {comment.commentedByName}
                    </div>
                    <div className="col-6 text-right">
                        <span>Commented On : </span>
                        {Moment(new Date(comment.createdDate + "Z").toLocaleString()).format("DD/MM/YYYY hh:mm A")}
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-12">{comment.comment}</div>
                </div>
                {comment.attachments && comment.attachments.length > 0 && (
                    <div className="row pt-1 m-0">
                        <div className="col-12 value document-files">
                            <span>Attachments</span>
                            {comment.attachments &&
                                comment.attachments.length > 0 &&
                                comment.attachments.map((m, i) => (
                                    <Attachment isComment={true} key={i} no={i} attachment={m}></Attachment>
                                ))}
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default ViewComment;
