import request from "./request";

const DocumentService = {
    GetAll: async (searchText, context) => {
        return await request.sendRequest(`/document/all/search/${searchText}`, context.token);
    },
    GetAllByTypes: async (type, searchText, context) => {
        return await request.sendRequest(`/document/all/${type}/${searchText}`, context.token);
    },
    GetDocuments: async (context) => {
        return await request.sendRequest(`/document/all/documents`, context.token);
    },
    Get: async (id, context) => {
        return await request.sendRequest(`/document/${id}`, context.token);
    },
    GetFile: async (id, context) => {
        return await request.sendRequest(`/document/file/${id}`, context.token);
    },
    Save: async (payload, context) => {
        return await request.sendFormDataRequest(`/document`, payload, context);
    },
    Remove: async (id, context) => {
        return await request.sendDeleteRequest(`/document/${id}`, context.token);
    },
    RemoveDocument: async (id, context) => {
        return await request.sendDeleteRequest(`/document/file/${id}`, context.token);
    },
};

export default DocumentService;
